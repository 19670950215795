import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { ColumnTypeProps } from '../ColumnTypeProps';

const ServiceColumnType: React.FC<ColumnTypeProps> = ({ column, entity, setEntity }) => {
  const [options, setOptions] = useState<{ label: string; value: string | number }[]>([]);

  useEffect(() => {
    // Llama al servicio para obtener las opciones
    column.service.getAll({}, 0, 0).then((response: any) => {
      let optionsData = response.data.db?.result ? response.data.db.result : response.data;
      optionsData = optionsData.map((item: any) => (item));
      
      setOptions(
        optionsData.map((item: any) => ({
          label: item[column.serviceLabel as string || 'name'],
          value: item[column.serviceValue as string || 'id'],
        }))
      );
    }).catch((error: any) => {
      console.error("Error fetching data from service:", error);
    });
  }, [column.service]);

  const handleChange = (value: string | number) => {
    const updatedEntity = { ...entity, [column.dataIndex]: value };
    setEntity(updatedEntity);
  };

  return (
    <Select
      mode={column.multiple ? "multiple" : undefined}
      style={{ width: "200px" }}
      onChange={handleChange}
      value={entity[column.dataIndex] || undefined} 
      options={options}
    />
  );
};

export default ServiceColumnType;
