import { Modal } from "antd";
import { useTranslation } from "react-i18next";

export function ModalDelete({
  originalData,
  deleteFunction,
  setIsDeleteModalOpen,
  isDeleteModalOpen,
}) {
  const { t } = useTranslation();
  async function handleDeleteOk() {
    await deleteFunction();
    setIsDeleteModalOpen(false);
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  return (
    <Modal
      title="Delete Element"
      open={isDeleteModalOpen}
      onOk={handleDeleteOk}
      onCancel={handleDeleteCancel}
    >
      <p>{t("Are you sure you want to remove this element?")}</p>
      {originalData.id ? (
        <p>
          {t("ID")}: {originalData.id}
        </p>
      ) : (
        <></>
      )}
      {originalData.name ? (
        <p>
          {t("Name")}: {originalData.name}
        </p>
      ) : (
        <></>
      )}
    </Modal>
  );
}
