import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

function PasswordInputMqtt({ element }) {
  const { t } = useTranslation();
  return (
    <Form.Item
      key={"pass"}
      label={t("Password")}
      name={"pass"}
      rules={[{ required: true }]}
    >
      <Input.Password
        defaultValue={element.pass}
        copyable="true"
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
    </Form.Item>
  );
}

export default PasswordInputMqtt;
