export const es_ES = {
  "Email address": "Correo electrónico",
  Password: "Contraseña",
  "Rememeber me": "Recuerdame",
  Description: "Descripción",
  Name: "Nombre",
  Sumbit: "Aceptar",
  Home: "Inicio",
  LoraWAN: "LoraWAN",
  "LoraWAN Sensor": "Sensor LoraWAN",
  "Network Servers": "Servidores de Red",
  "Network Server": "Servidor de Red",
  Message: "Mensaje",
  Decoder: "Decodificador",
  "Decoder Server": "Servidor de Decodificador",
  Servers: "Servidores",
  Server: "Servidor",
  Gateway: "Gateway",
  Gateways: "Gateways",
  Group: "Grupo",
  Sensor: "Sensor",
  Sensors: "Sensores",
  "Network Type": "Tipo de Red",
  "Sensor Type": "Tipo de Sensor",
  Types: "Tipos",
  Project: "Proyecto",
  Projects: "Proyectos",
  Business: "Empresa",
  Council: "Ayuntamiento",
  User: "Usuario",
  Users: "Usuarios",
  Logger: "Registro",
  Modules: "Módulos",
  Door: "Puerta",
  Doors: "Puertas",
  Calendar: "Calendario",
  Request: "Petición",
  Light: "Luz",
  "Energy Groups": "Grupo Energético",
  "Energy Devices": "Dispositivo Energético",
  Alarm: "Alarma",
  Status: "Estado",
  Planning: "Planificación",
  Actuator: "Actuador",
  History: "Historial",
  Control: "Control",
  Water: "Agua",
  Devices: "Dispositivo",
  "Hydric balance": "Balance hídrico",
  Observations: "Observaciones",
  Map: "Mapa",
  Generic: "Genérico",
  Alarms: "Alarmas",
  "Alarm Notifications": "Notificaciones de alarma",
  Capacity: "Capacidad",
  Panel: "Panel",
  Parkings: "Aparcamientos",
  Dashboard: "Panel de Control",
  Irrigation: "Irrigación",
  Boiler: "Caldera",
  "MQTT Visualizer": "Visualizador MQTT",
  "Device Profiles": "Perfil de Dispositivo",
  "Service Profiles": "Perfil de Servicio",
  Applications: "Aplicaciones",
  "Base64 Message": "Mensaje en Base64:",

  "Are you sure you want to remove this element?":
    "¿Estás seguro de que quieres eliminar este elemento?",
  General: "General",
  // Keywords
  New: "Nuevo",
  Search: "Buscar",
  "Download XML": "Descargar XML",

  // DASHBOARD
  Information: "Información",
  Signal: "Señal",
  Packages: "Paquetes",
  Received: "Recibidos",
  Lost: "Perdidos",

  // Modals
  "Edit Element": "Editar Elemento",
  "Message info": "Info del Mensaje",
  "Delete Element": "Borrar Elemento",
  "Add Element": "Añadir Elemento",
  "Search to Select": "Busca para seleccionar",

  // Notifications
  Notifications: "Notificaciones",
  MyProfile: "Mi Perfil",
};
