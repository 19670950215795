import React from 'react';
import { Select } from 'antd';

interface EnumColumnTypeProps {
  column: {
    dataIndex: string;
    defaultValue?: string;
  };
  options: string[];
  entity: { [key: string]: any };
  setEntity: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

const EnumColumnType: React.FC<EnumColumnTypeProps> = ({ column, options, entity, setEntity }) => {
  return (
    <Select
      style={{ width: "200px" }}
      onChange={(value) => { setEntity({ ...entity, [column.dataIndex]: value });}}
      value={(entity[column.dataIndex] &&  entity[column.dataIndex] != '' )|| column.defaultValue || options[0]}
      options={options.map((item: string) => {
        return { label: item, value: item };
      })}
    />
  );
};

export default EnumColumnType;
