import { Button, Form, Modal, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useTableAttributes } from "../../../Hooks/useTableData";
import { useTableAttributesUpdate } from "../../../Hooks/useTableData";

function VariableColumnAttributesModal({ allAttributes }) {
  const { t } = useTranslation();

  const tableAttributesUpdate = useTableAttributesUpdate();
  const currentAttributes = useTableAttributes();

  // Modal view controls
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }
  // -------------------------------

  const onFinish = (result) => {
    let values = result.attributes;

    let attributesToShow = [];

    attributesToShow.push(values);

    tableAttributesUpdate(allAttributes.filter((e) => values.includes(e.key))); // This reloads the table page

    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    setIsModalOpen(false);
  };
  // --------------------------------------------

  let attributesList = [];

  for (let i = 0; i < allAttributes.length; i++) {
    attributesList.push({
      label: t(allAttributes[i].name),
      value: allAttributes[i].key,
    });
  }

  return (
    <>
      <Button className="buttonCentered" onClick={openModal}>
        <SettingOutlined />
      </Button>
      <Modal
        title={t("Shown attributes")}
        open={isModalOpen}
        onCancel={onFinishFailed}
        footer={null}
      >
        <Formik initialValues={{ attributes: currentAttributes }}>
          {({ isSubmitting }) => (
            <Form
              labelAlign="left"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                key={"attributes"}
                label={t("Attributes shown")}
                name={"attributes"}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  // Do not add the actions column, it must NOT be modified
                  defaultValue={currentAttributes.filter(
                    (e) => e.value !== "actions",
                  )}
                  options={attributesList.filter((e) => e.value !== "actions")}
                />
              </Form.Item>

              <Form.Item key={"submit-btn"}>
                <Button type="primary" htmlType="submit">
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default VariableColumnAttributesModal;
