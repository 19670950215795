import Decoder from "../Decoder/Decoder";
import PhysicalGatewayService from "../../services/PhysicalGateway/PhysicalGateway.service";
import GatewayPage from "../GatewayPage/GatewayPage";
import DeviceProfile from "../DeviceProfile/DeviceProfile";
import AuthService, { posibleRoles } from "../../services/Auth/Auth.service";

import { useEffect, useState } from "react";
import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { TabNavigation, TabPanel } from "../../components/TabNavigation/TabNavigation";
import { MqttClient } from "../../components/MqttClient/MqttClient";
import { ApplicationTab } from "./ApplicationTab/ApplicationTab";
import { CRUDPrivilages } from '../../components/DynamicTable/Interfaces/CRUDPrivilages';
import { useParams } from "react-router-dom";
import ServiceProfile from "../ServiceProfile/ServiceProfile";

const physicalGatewayService = new PhysicalGatewayService();
 
const { useToken } = theme;
const { Title } = Typography;

interface NetworkServerDetailsProps {
}

const NetworkServerDetails: React.FC<NetworkServerDetailsProps> = () => {
  const { token } = useToken();
  const { t } = useTranslation();
  const [isPhysicalGatewayCentralized, setIsPhysicalGatewayCentralized] = useState<boolean>(false);
  const privilages: CRUDPrivilages = {
    create: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
    update: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
    delete: AuthService.atLeastRole(posibleRoles.ADMIN_TECH)
  };

  const {physicalGatewayId, organizationId} = useParams()

  useEffect(() => {
    physicalGatewayService
      .isCentralized(physicalGatewayId as string)
      .then((res: boolean) => {
        setIsPhysicalGatewayCentralized(res);
      });
  }, [useParams()]);

  return (
    <div className="wrapper">
      <h2> {`Organization ${organizationId}`}  </h2>
      <TabNavigation selected={0}>
        <TabPanel title="Service Profile">
          <ServiceProfile CRUDPrivilages={privilages} />
        </TabPanel>

        <TabPanel title="Applications">
          <ApplicationTab privilages={privilages} />
        </TabPanel>
        <TabPanel title="Device Profile">
          <DeviceProfile CRUDPrivilages={privilages} />
        </TabPanel>

        <TabPanel title="Gateway">
          <GatewayPage
            topic={physicalGatewayId as string}
            CRUDPrivilages={{
              create: isPhysicalGatewayCentralized,
              update: AuthService.atLeastRole(posibleRoles.USER_COUNCIL),
              delete: AuthService.atLeastRole(posibleRoles.USER_COUNCIL),
            }}
          />
        </TabPanel>

        <TabPanel title="MQTT Visualizer">
          <div>
            <Title style={{ color: token.colorText }}>{t("MQTT Visualizer")}</Title>
            <MqttClient element="organization" />
          </div>
        </TabPanel>
        <TabPanel title="Decoder">
          <Decoder organizationId={organizationId as string} /> 
        </TabPanel>
      </TabNavigation>
    </div>
  );
};

export default NetworkServerDetails;
