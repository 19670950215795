import { useContext } from "react";
import {
  AttributesContext,
  AttributesUpdateContext,
  DataContext,
  DataUpdateContext,
} from "../context/TableData/TableDataContext";

export const useTableData = () => useContext(DataContext);

export const useTableDataUpdate = () => useContext(DataUpdateContext);

export const useTableAttributes = () => useContext(AttributesContext);

export const useTableAttributesUpdate = () =>
  useContext(AttributesUpdateContext);
