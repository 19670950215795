import { Modal, Tabs } from "antd";
import type { TabsProps } from 'antd';
import { useEffect, useState } from "react";

type props = {open:boolean, setOpen:Function, data:any}
export default function ShowInfoModal({open, setOpen, data}:props) {
    const [items, setItems] = useState<TabsProps['items']>([]);

    function convertJSONChildren(object:any){
        let dataInDOM = Object.keys(object)
                            .map((key:string)=>{return <p>{key}: {object[key]}</p>})
                            
        return (<>{dataInDOM}</>)
    }
    useEffect(() => {
        let tmp_items = []
        for (const key in data) {
            tmp_items.push({
                key: key,
                label: key,
                children:  convertJSONChildren(data[key])
            })
        }
        setItems(tmp_items)

    }, [data]);
    // const items: TabsProps['items'] = [
    //     {
    //       key: '1',
    //       label: 'Tab 1',
    //       children: 'Content of Tab Pane 1',
    //     },
    //     {
    //       key: '2',
    //       label: 'Tab 2',
    //       children: 'Content of Tab Pane 2',
    //     },
    //     {
    //       key: '3',
    //       label: 'Tab 3',
    //       children: 'Content of Tab Pane 3',
    //     },
    //   ];
      const onChange = (key: string) => {
        console.log(key);
        console.log(data)
      };
    return(<>
        <Modal
            title="Info"
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
        >
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Modal>
    </>)
}
