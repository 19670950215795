import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/basicApi";

const { Text } = Typography;

function EnumSelectorNetworkServer({
  element,
  route,
  title,
  form,
  dataName,
  child,
}) {
  const [enumSelectorActualValue, setEnumSelectorActualValue] = useState("");

  const { t } = useTranslation();
  const [api] = useState(new BasicApi(route));

  const [data, setData] = useState([]);

  const onValueChange = (value) => {
    setEnumSelectorActualValue(value);
    form.setFieldValue(dataName, value);
  };

  //generateFormOptions
  useEffect(() => {
    api.getInfraestructureEnums().then((value) => {
      setData(value[dataName]);
    });
  }, [api, dataName]);

  return (
    <>
      <Form.Item
        key={dataName}
        label={t(title)}
        name={dataName}
        rules={[{ type: "string", required: true }]}
      >
        <>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("Search to Select")}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={data.map((value) => ({
              label: value,
              value: value,
            }))}
            onChange={onValueChange}
            defaultValue={element[dataName]}
          />
          <Text>{element[dataName]}</Text>
        </>
      </Form.Item>
      {child.map((attr) => {
        if (
          enumSelectorActualValue === "Centralized" &&
          attr.formItem &&
          attr.key === "url"
        ) {
          const Component = attr.formItem;
          return (
            <Component
              route={route}
              element={element ? element : {}}
              dataName={attr.key}
              title={attr.name}
              form={form}
            />
          );
        }
        if (attr.formItem && attr.key === "gateway") {
          const Component = attr.formItem;
          return (
            <Component
              route={route}
              element={element ? element : {}}
              dataName={attr.key}
              title={attr.name}
              form={form}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default EnumSelectorNetworkServer;
