import { Route } from "react-router-dom";
import { PrivateRoute } from "../../utils/PrivateRoute";
import PhysicalGateway from "../../../Pages/PhysicalGateways/PhysicalGateway";
import Organization from "../../../Pages/Organization/Organization";
import SensorDetails from "../../../Pages/SensorsDetails";
import NetworkServerDetails from "../../../Pages/NetworkServerDetails/NetworkServerDetails";
import AuthService, { posibleRoles } from "../../../services/Auth/Auth.service";
import { TableDataProvider } from "../../../context/TableData/TableDataContext";

export function GatewayRoutes() {
  return (
    <Route path="gateway">
      <Route
        path="physical-gateways"
        element={
          <PrivateRoute>
            <PhysicalGateway
              mode="embedded"
              CRUDPrivilages={{
                create: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                update: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                delete: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
              }}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="physical-gateways/:id/organizations"
        element={
          <PrivateRoute>
            <Organization
              CRUDPrivilages={{
                create: AuthService.atLeastRole(
                  posibleRoles.ADMIN_TECH
                ),
                update: AuthService.atLeastRole(
                  posibleRoles.ADMIN_TECH
                ),
                delete: AuthService.atLeastRole(
                  posibleRoles.ADMIN_TECH
                ),
              }}
            />
          </PrivateRoute>
        }
      />

      <Route
        path="physical-gateways/:physicalGatewayId/organizations/:organizationId"
        element={
          <PrivateRoute>
            <TableDataProvider>
              <NetworkServerDetails />
            </TableDataProvider>
          </PrivateRoute>
        }
      />

      <Route
        path="physical-gateways/:physicalGatewayId/organizations/:organizationId/app/:applicationId/deveui/:devEUI"
        element={
          <PrivateRoute>
            <TableDataProvider>
              <SensorDetails />
            </TableDataProvider>
          </PrivateRoute>
        }
      />

    </Route>
  );
}
