import { Select } from "antd";

export const rolOptions = [
  {
    value: "1",
    label: "SUPER_ADMIN",
  },
  {
    value: "2",
    label: "ADMIN_TECH",
  },
  {
    value: "7",
    label: "NOT_ROL_ASIGNED",
  },
];

export const selector = (onChangeHandler, options) => {
  return (
    <Select
      showSearch
      style={{
        width: 200,
      }}
      placeholder={"Search to Select"}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={onChangeHandler}
      defaultValue={"Select user rol"}
      options={options}
    />
  );
};
