import country from "country-list-js";
import { IColumnForCRUD, IServiceCRUD } from '../interfaces.service';

export default class CountryService implements IServiceCRUD {
  constructor() {}
    processCsv?: ((delimiter: string, serverFileName: string, aditionalData: any | null) => any) | undefined;
    getEnums!: () => Promise<any>;
    getColumns!: () => IColumnForCRUD[];
    getAllIds?: (() => Promise<string[] | number[]>) | undefined;

  async getAll(): Promise<any> {
    var country_names = country.ls("name");
    let list = [];
    for (let i = 0; i < country_names.length; i++) {
      list.push({
        id: i,
        name: country_names[i]
      }
      );
    }
    return await { data:list };
  }

  async getById(body: any): Promise<any> {
    throw new Error('Method not implemented');
  }

  async create(body: any): Promise<any> {
    throw new Error('Method not implemented');
  }

  async update(id: string | number, body: any): Promise<any> {
    throw new Error('Method not implemented');
  }

  async delete(id: string | string[] | number | number[]): Promise<any> {
    throw new Error('Method not implemented');
  }
}
