import { Col, Flex, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import P from "antd/es/typography/Paragraph";
import SensorWithoutTopicService from "../../../../../services/SensorWithoutTopic/SensorWithoutTopic.service";
import StaService from "../../../../../services/Sta/Sta.service";

export function ModalStaConfig({originalData,modalOpen,setModalOpen,onChangeIntervalWindow,setSelectedStasInModal}) {
  let [windowDetection, setWindowDetection] = useState(originalData.intervalWindow);
  let [connection, setConnection] = useState("lora");
  const [deviceService] = useState(new StaService());
  const [availableDevices, setAvailableDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);

  const handleSelectDevices = (value) => {
    if (value.includes('all')) {
      if (selectedDevices.length === availableDevices.length) {
        // Deselecciona todo si ya estaban todos seleccionados
        setSelectedDevices([]); 
      } else {
        const allDeviceValues = availableDevices.map(device => device.value);
        setSelectedDevices(allDeviceValues); // Selecciona todos los dispositivos
      }
    } else {
      setSelectedDevices(value);
    }
  };
    
  useEffect(()=>{
    setSelectedStasInModal(selectedDevices)
  },[selectedDevices])


  useEffect(() => {
    deviceService.allInMap().then((data) =>{
        let result = []
        console.log(data)
        data.result.map((item) =>{
          result.push({label: item.dev_EUI, value: item.dev_EUI})
        })
        setAvailableDevices(result);
      })
  },[])

  useEffect(() => {
    if(Object.keys(originalData).length !== 0){
      setWindowDetection(originalData.intervalWindow);
      setSelectedDevices([originalData.dev_EUI]);
    }
  }, [originalData]);

  console.log(originalData);
  return (
    <Modal
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={() => onChangeIntervalWindow(windowDetection, originalData.dev_EUI)}
    >
      <Title> Sta config </Title>
      <Flex justify="center">
        <Col style={{ width: "60%" }}>
        {
          Object.keys(originalData).length !== 0? (
            <>
              <P> Dispositivos: {originalData.dev_EUI}</P>
              <Flex justify="space-between">
              Connection:
              <Select
                value={connection}
                onChange={(e) => setConnection(e.target.value)}
                options={[
                  { label: "lora", value: "lora" },
                  { label: "4G", value: "4G" }
                ]}
                style={{ width: "100px" }}
              />
              </Flex>
            </>
        ) : 
          <>
            <P> Dispositivos:</P>
            <Select
            mode="multiple" 
            placeholder="Select Sensors"
            showSearch 
            style={{ width: '100%', marginBottom: '20px' }}
            value={selectedDevices}
            onChange={(value) => handleSelectDevices(value)} 
            options={[
              { label: 'Select All', value: 'all' },
              ...availableDevices
            ]}
            />
          </> 
        }
      
          <Flex justify="space-between">
            Ventana Detección:
            <InputNumber
              value={windowDetection}
              placeholder="seconds"
              onChange={(value) => setWindowDetection(value)}
              style={{ width: "100px" }}
              max={65535}
            />
          </Flex>
          
        </Col>
      </Flex>
    </Modal>
  );
}
