import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { enableInputGroupAtom } from "../../components/UpdateItemForm/CommonForm.jsx";

function FloatInput({
  dataName,
  title,
  required,
  disabledTargetValue,
  disabledVarName,
}) {
  const { t } = useTranslation();
  const [enableInputGroup] = useAtom(enableInputGroupAtom);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[{ required: required }]}
    >
      <InputNumber
        step={"0.000001"}
        disabled={
          disabledTargetValue === enableInputGroup[disabledVarName]
            ? true
            : false
        }
        stringMode
      />
    </Form.Item>
  );
}

export default FloatInput;
