import { ENV } from "../../utils";
import jwtDecode from "jwt-decode";

export const posibleRoles = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN_TECH: "ADMIN_TECH",
  ADMIN_COUNCIL: "ADMIN_COUNCIL",
  USER_COUNCIL: "USER_COUNCIL",
};

export type CNO_data_T = {
  NsId: string;
  OrganizationId: string;
  id: number;
  createdAd: string;
  updateAt: string;
  CouncilId: number;
};
export type tokenData_T = {
  userName: string;
  userId: number;
  userRole: string;
  councils: number[];
  CNOs: CNO_data_T[];
};

export default class AuthService {
  static async register(data: any) {
    const url = `${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.REGISTER}`;
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status !== 201) throw result;

    return result;
  }

  static async login(data: any) {
    const url = `${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.LOGIN}`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    if (![200, 304].includes(response.status)) throw response;
    const result = await response.json();
    localStorage.setItem(ENV.JWT.ACCESS, result.accessToken);
    return result;
  }

  static async refreshToken(token: string) {
    const url = `${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.REFRESH_TOKEN}/`;
    const params = {
      method: "POST",
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    if (![200, 304].includes(response.status)) throw result;
    return result;
  }

  static getToken(): string {
    return localStorage.getItem(ENV.JWT.ACCESS) || "";
  }
  static getTokenData(): tokenData_T {
    return jwtDecode(this.getToken());
  }
  static logout() {
    localStorage.removeItem(ENV.JWT.ACCESS);
  }
  static atLeastRole(minimumRole: string): boolean {
    if (!this.getToken()) return false;
    const tokenData = this.getTokenData();
    let result = false;
    switch (minimumRole) {
      case posibleRoles.USER_COUNCIL:
        result = [
          posibleRoles.SUPER_ADMIN,
          posibleRoles.ADMIN_TECH,
          posibleRoles.ADMIN_COUNCIL,
          posibleRoles.USER_COUNCIL,
        ].includes(tokenData.userRole);
        break;
      case posibleRoles.ADMIN_COUNCIL:
        result = [
          posibleRoles.SUPER_ADMIN,
          posibleRoles.ADMIN_TECH,
          posibleRoles.ADMIN_COUNCIL,
        ].includes(tokenData.userRole);
        break;
      case posibleRoles.ADMIN_TECH:
        result = [posibleRoles.SUPER_ADMIN, posibleRoles.ADMIN_TECH].includes(
          tokenData.userRole,
        );
        break;
      case posibleRoles.SUPER_ADMIN:
        result = [posibleRoles.SUPER_ADMIN].includes(tokenData.userRole);
        break;
    }
    return result;
  }
}
