import { ENV } from "../../utils/constants.js";
import axios from "axios";
import AuthService from "../Auth/Auth.service";

export default class ProfileService {
  token: string;
  constructor() {
    this.token = AuthService.getToken();
  }

  async getUser(id: string = "") {
    const headers = {
      headers: {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.USER}/${id}`,
      headers,
    );
  }
}
