import country from "country-list-js";
import { IColumnForCRUD, IServiceCRUD } from '../interfaces.service';

export default class StateService implements IServiceCRUD {
  constructor() {}
    processCsv?: ((delimiter: string, serverFileName: string, aditionalData: any | null) => any) | undefined;
    getEnums!: () => Promise<any>;
    getColumns!: () => IColumnForCRUD[];
    getAllIds?: (() => Promise<string[] | number[]>) | undefined;

  async getAll(countryName:string): Promise<any> {
    var countryData = country.findByName(countryName);
    let list = [];
    if (countryData?.provinces) {
      for (let i = 0; i < countryData.provinces.length; i++) {
        list.push(countryData.provinces[i].name);
      }
    } else {
      list.push(countryName);
    }
    const response = {data:{db:{result:list}}}
    return await response;
  }

  async getById(body: any): Promise<any> {
    throw new Error('Method not implemented');
  }

  async create(body: any): Promise<any> {
    throw new Error('Method not implemented');
  }

  async update(id: string | number, body: any): Promise<any> {
    throw new Error('Method not implemented');
  }

  async delete(id: string | string[] | number | number[]): Promise<any> {
    throw new Error('Method not implemented');
  }
}
