import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Button, Flex } from "antd";
import { Spin } from "antd";
import { ENV } from "../../../utils/constants.js";
import AuthService from "../../../services/Auth/Auth.service";

export default function TabServer({ mac, organizationId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    { title: "ID", dataIndex: "pkId", key: "pkId" },
    { title: "Name", dataIndex: "serverName", key: "serverName" },
    { title: "Provider", dataIndex: "provider", key: "provider" },
    { title: "Url", dataIndex: "url", key: "url" },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    setLoading(true);
    fetch(
      `${ENV.Infraestructure_API}/${mac}/decoder/${organizationId}/servers`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": AuthService.getToken(),
        },
      },
    )
      .then((response) => response.json())
      .then((data) => setDataSource(data))
      .then(() => setLoading(false))
      .catch((error) => console.error(error));
  }, [mac]);

  return (
    <>
      <Flex gap="small" wrap="wrap" justify="flex-end">
        <Button type="secondary">Update Decoder</Button>
        <Button type="secondary">From Decoder</Button>
      </Flex>

      <Spin spinning={loading} size="large">
        <Table dataSource={dataSource} columns={columns} />
      </Spin>
    </>
  );
}
