import React, { useState } from "react";
import { Modal, Steps, Progress, Flex } from "antd";
import SensorPlatform from "./Steps/SensorPlatform";
import SensorAdd from "./Steps/SensorAdd";
import SensorFiware from "./Steps/SensorFiware";
import SensorSentilo from "./Steps/SensorSentilo";
import SensorServer from "./Steps/SensorServer";
import SensorType from "./Steps/SensorType";
import { ENV } from "../../../../utils/constants.js";

function ModalAddSensor({ isModalOpen, setIsModalOpen, mac }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [PlatformValue, setPlatformValue] = useState("None");
  const [stepArray, setStepArray] = useState([
    { title: "Platform" },
    { title: "Server" },
    { title: "Sensor Type" },
    { title: "Add" },
  ]);
  const [addListSource, setAddListSource] = useState([]);
  const [addListTarget, setAddListTarget] = useState([]);
  const [addListSelected, setAddListSelected] = useState([]);
  const [addListLoading, setAddListLoading] = useState(false);

  const [errorFounds, setErrorFounds] = useState([]);
  let sensorDataDefaultValue = {
    mac: mac,
    createSentiloServer: false,
    sentilo: {
      provider: "caudetedelasfuentes@cellnextelecom",
      url: "https://connecta.dival.es",
      token: "",
      type: "",
      subTypes: [{ subType: "", value: "" }],
    },
    createFiwareServer: false,
    fiware: {
      tokenUrl: "",
      brokerUrl: "",
      user: "",
      exceedMax: "",
      service: "",
      subService: "",
    },
    sensorType: {
      type: "",
      subType: "",
      subTypeToSend: "",
      sensors: [{ name: "", value: "" }],
    },
    add: [],
  };
  const [sensorData, setSensorData] = useState(sensorDataDefaultValue);

  /// progress bar when send data to MQTT
  const [sendDataProgress, setSendDataProgress] = useState(0);
  const [progressModalOpen, setProgressModalOpen] = useState(false);

  const onStepChange = (value) => {
    setCurrentStep(value);
  };

  const handleSendData = async () => {
    setIsModalOpen(false);
    setProgressModalOpen(true);
    setSendDataProgress(0);
    let sendData = sensorData.add.map((item) => {
      let tmp = {
        applicationName: "app",
        sensorModelName: sensorData.sensorType.subTypeToSend,
        loraAddress: item.key,
        componentName: item.title,
        sensorNames: {}, // to be set
        extraFields: null, // hardcoded
        serverIds: sensorData.server, //array
      };
      sensorData.sensorType.sensors.map((item) => {
        tmp.sensorNames[item["name"]] = isNaN(item["value"])
          ? item["value"]
          : Number(item["value"]);
        return null;
      });
      return tmp;
    });

    const organizationId = window.location.href.match(
      /(?<=organizations\/)[0-9]*/g,
    )[0];
    //DECODER
    let errors = [];
    for (let index = 0; index < sensorData.add.length; index++) {
      const element = sendData[index];
      let result = await fetch(
        `${ENV.Infraestructure_API}/${sensorData.mac}/decoder/sendDataToDecoder`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(element),
        },
      );
      if (!result.ok) {
        errors.push(`MQTT->${element.componentName}`);
      }
      if (sensorData.createSentiloServer) {
        let sentilo = {
          //
          url: sensorData.sentilo.url,
          provider: sensorData.sentilo.provider,
          token: sensorData.sentilo.token,
          organizationId: organizationId,
          //
          component: {
            name: sensorData.add[index].title,
            devEUI: sensorData.add[index].key,
            type: sensorData.sentilo.type,
          },
          //componentType:`${sensorData.sentilo.type}`,
          sensors: `${JSON.stringify(sensorData.sentilo.subTypes)}`,
        };
        let resultSentilo = await fetch(
          `${ENV.Infraestructure_API}/${sensorData.mac}/decoder/sendToSentilo`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(sentilo),
          },
        );
        if (!resultSentilo.ok) {
          console.log("Error sending data to decoder");
          errors.push(`Sentilo->${element.componentName}`);
        }
      }

      // the component requiere a percentage with data between 0 and 100
      setErrorFounds(errors);

      setSendDataProgress(((index + 1) / sendData.length) * 100);
    }

    //SENTILO
    // END
    setSensorData(sensorDataDefaultValue);
    setAddListSource([]);
    setAddListTarget([]);
    setAddListSelected([]);
  };
  const handleCancel = () => {
    // END
    setIsModalOpen(false);
    setSensorData(sensorDataDefaultValue);
    setAddListSource([]);
    setAddListTarget([]);
    setAddListSelected([]);
    setAddListLoading(true);
  };
  const onPlatformChange = (e) => {
    let tmp_steps = JSON.parse(JSON.stringify(stepArray));
    if (e.target.value === "None") {
      tmp_steps.splice(1, 1);
    } else {
      if (tmp_steps.length === 6) {
        tmp_steps.splice(1, 1, { title: e.target.value });
      } else {
        tmp_steps.splice(1, 0, { title: e.target.value });
      }
    }
    setPlatformValue(e.target.value);
    setStepArray(tmp_steps);
  };
  const closeProgressModel = () => {
    setProgressModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Add sensor to Decoder Network Server"
        width={"60%"}
        open={isModalOpen}
        onOk={handleSendData}
        okText={"Send"}
        onCancel={handleCancel}
      >
        <Steps
          onChange={onStepChange}
          current={currentStep}
          items={stepArray}
        />
        <div style={{ height: "300px" }}>
          <div
            style={{ height: "100%" }}
            hidden={stepArray[currentStep].title !== "Platform"}
          >
            <SensorPlatform
              onPlatformChange={onPlatformChange}
              PlatformValue={PlatformValue}
            />
          </div>
          <div
            style={{ height: "100%" }}
            hidden={stepArray[currentStep].title !== "Fiware"}
          >
            <SensorFiware
              sensorData={sensorData}
              setSensorData={setSensorData}
            />
          </div>
          <div
            style={{ height: "100%" }}
            hidden={stepArray[currentStep].title !== "Sentilo"}
          >
            <SensorSentilo
              sensorData={sensorData}
              setSensorData={setSensorData}
            />
          </div>
          <div
            style={{ height: "100%" }}
            hidden={stepArray[currentStep].title !== "Server"}
          >
            <SensorServer
              sensorData={sensorData}
              setSensorData={setSensorData}
            />
          </div>
          <div
            style={{ height: "100%" }}
            hidden={stepArray[currentStep].title !== "Sensor Type"}
          >
            <SensorType
              sensorData={sensorData}
              setSensorData={setSensorData}
              setAddListSource={setAddListSource}
              setAddListTarget={setAddListTarget}
              setAddListSelected={setAddListSelected}
              setAddListLoading={setAddListLoading}
            />
          </div>
          <div
            style={{ height: "100%" }}
            hidden={stepArray[currentStep].title !== "Add"}
          >
            <SensorAdd
              sensorData={sensorData}
              setSensorData={setSensorData}
              addListSource={addListSource}
              addListTarget={addListTarget}
              setAddListTarget={setAddListTarget}
              addListSelected={addListSelected}
              setAddListSelected={setAddListSelected}
              addListLoading={addListLoading}
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="sending data to the decoder"
        open={progressModalOpen}
        onCancel={closeProgressModel}
        onOk={closeProgressModel}
      >
        <Flex align="center" justify={"space-around"}>
          <Progress type="circle" percent={sendDataProgress} />
        </Flex>
        <div>
          <p>Error founds: {errorFounds.length}</p>
          {errorFounds.map((item) => {
            return <p style={{ color: "red" }}>{item}</p>;
          })}
        </div>
      </Modal>
    </>
  );
}

export default ModalAddSensor;
