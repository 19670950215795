import React, { useState, useEffect } from "react";
import { Button, Input, Row, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./index.css";

function EmailInput({ setAlarmEntity, alarmEntity }) {
  const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };
  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addEmail();
    }
  };
  const addEmail = () => {
    if (currentEmail.trim() !== "") {
      if (regexCorreo.test(currentEmail)) {
        setEmails([...emails, currentEmail.trim()]);
        setCurrentEmail("");
        setAlarmEntity({
          ...alarmEntity,
          emails: [...emails, currentEmail.trim()],
        });
      } else {
        messageApi.open({
          type: "error",
          content: "El email no es válido.",
        });
      }
    }
  };
  const removeEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);

    alarmEntity.emails.splice(index, 1);
  };

  useEffect(() => {
    setEmails(alarmEntity.emails);
  }, [alarmEntity.emails]);

  return (
    <div style={{ margin: "16px 0px" }}>
      {contextHolder}
      <p>Emails: </p>
      {emails.length > 0 ? (
        <Row
          style={{
            padding: "16px 0px",
            margin: "16px 0px",
            backgroundColor: "#7150CC1A",
            borderRadius: "16px",
          }}
        >
          {emails.map((email, index) => (
            <div key={index} className="email-tag" style={{ display: "flex" }}>
              <Button
                type="primary"
                onClick={() => removeEmail(index)}
                style={{ margin: "8px" }}
              >
                <p>
                  {email} <DeleteOutlined />
                </p>
              </Button>
            </div>
          ))}
        </Row>
      ) : null}
      <div style={{ display: "flex" }}>
        <Input
          type="text"
          placeholder="Agregar email..."
          value={currentEmail}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          style={{ margin: "0px 8px 0px 0px" }}
        />
        <Button type="primary" onClick={addEmail}>
          Agregar Email
        </Button>
      </div>
    </div>
  );
}
export default EmailInput;
