import { Route } from "react-router-dom";
import { PrivateRoute } from "../../utils/PrivateRoute";
import { TableDataProvider } from "../../../context/TableData/TableDataContext";
import Organization from "../../../Pages/Organization/Organization";
import Dashboard from "../../../components/Dashboard";
import AuthService, { posibleRoles } from "../../../services/Auth/Auth.service";
import NetworkServerDetails from "../../../Pages/NetworkServerDetails/NetworkServerDetails";
import PhysicalGateway from "../../../Pages/PhysicalGateways/PhysicalGateway";

export function LoraWanRoutes() {
  return (
    <Route path="LoraWAN">
      <Route path="centralized/:id/organizations"
        element={
          <PrivateRoute>
            <Organization
              CRUDPrivilages={{
                create: AuthService.atLeastRole(
                  posibleRoles.ADMIN_TECH
                ),
                update: AuthService.atLeastRole(
                  posibleRoles.ADMIN_TECH
                ),
                delete: AuthService.atLeastRole(
                  posibleRoles.ADMIN_TECH
                ),
              }}
            />
          </PrivateRoute>
        }
      />
      <Route path="centralized"
        element={
          <PrivateRoute>
            <PhysicalGateway
              mode="centralized"
              CRUDPrivilages={{
                create: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                update: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                delete: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
              }}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="centralized/:id/organizations/:organizationId"
        element={
          <PrivateRoute>
            <TableDataProvider>
              <NetworkServerDetails />
            </TableDataProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="dashboard"
        element={
          <PrivateRoute>
            <Dashboard
              title={"LoraWAN messages"}
              props={{
                wrapper: true,
                showBreadCrumbs: true,
                showColor: true,
                info: {
                  labels: ["Username"],
                  data: ["User"],
                },
                messages: {
                  numbers: [45, 63, 7, 12],
                  received: 80,
                  lost: 20,
                },
              }}
            />
          </PrivateRoute>
        }
      />
    </Route>
  );
}
