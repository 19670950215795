// convert "3h" or "3 hours" to miliseconds. 
// default value = "3h"
export function  stringToMilliseconds (value:string){
    const regexExtract= /(\d*)( *\D*)/.exec(value)
    //headers
    let diffHeaderInMiliseconds:number = 3
    let diffHeaderType:string = "hour"
    if(regexExtract){
        diffHeaderInMiliseconds = Number(!isNaN(Number(regexExtract[1]))? Number(regexExtract[1]):diffHeaderInMiliseconds) +1
        diffHeaderType = (regexExtract.length >1? regexExtract[2]:diffHeaderType).replace(/\s/g, '').toLowerCase()
    }
    
    if(["day","days","d"].includes(diffHeaderType)){
        diffHeaderInMiliseconds *=  1000 * 60 * 60 * 24
    }else if(["hr","hrs","hour","hours","h"].includes(diffHeaderType)){
        diffHeaderInMiliseconds *= 1000 * 60 * 60
    }else if(["min","minute","minutes","m"].includes(diffHeaderType)){
        diffHeaderInMiliseconds *= 1000 * 60
    }else if(["sec","secs","second","seconds","s"].includes(diffHeaderType)){
        diffHeaderInMiliseconds *= 1000
    }else{
        diffHeaderType = "h";
        diffHeaderInMiliseconds *= 1000 * 60 * 60;
    }
    return diffHeaderInMiliseconds - 1
}