import { IColumnForCRUD } from "../interfaces.service";
import NetworkServerService from "../NetworkServer/NetworkServer.service";
import OrganizationService from "../Organization/Organization.service";

export const Columns = (mac: string): IColumnForCRUD[] => {
    return [{ dataIndex: 'topic', title: 'NS', filter: 'name', type: 'text', visible: true, editable: false },
    { dataIndex: 'name', title: 'Name', filter: 'text', type: 'text', visible: true, editable: true },
    { dataIndex: 'networkServerID', title: 'Network Server ID', filter: 'text', type: 'service', service: new NetworkServerService(), serviceLabel: "gatewayMac", serviceValue: "id", visible: true, editable: true, },
    { dataIndex: 'organizationID', title: 'Organization ID', filter: 'text', type: 'service', service: new OrganizationService(mac), serviceLabel: "name", serviceValue: "id", visible: true, editable: true, },

    { dataIndex: 'organizationID', title: 'Organization ID', filter: 'text', type: 'text', visible: true, editable: true },
    { dataIndex: 'uplinkInterval', title: 'UplinkInterval', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'factoryPresetFreqs', title: 'Freq. (should be [0])', filter: 'text', type: 'text', visible: true, editable: true },
    { dataIndex: 'adrAlgorithmID', title: 'adrAlgorithmID', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'classBTimeout', title: 'classBTimeout', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'classCTimeout', title: 'classCTimeout', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'geolocBufferTTL', title: 'geolocBufferTTL', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'geolocMinBufferSize', title: 'geolocMinBufferSize', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'macVersion', title: 'macVersion', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'maxDutyCycle', title: 'maxDutyCycle', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'maxEIRP', title: 'maxEIRP', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'payloadCodec', title: 'payloadCodec', filter: 'text', type: 'text', visible: true, editable: false },
    { dataIndex: 'payloadDecoderScript', title: 'payloadDecoderScript', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'payloadEncoderScript', title: 'payloadEncoderScript', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'pingSlotDR', title: 'pingSlotDR', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'pingSlotFreq', title: 'pingSlotFreq', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'pingSlotPeriod', title: 'pingSlotPeriod', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'regParamsRevision', title: 'regParamsRevision', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'rfRegion', title: 'rfRegion', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'rxDROffset1', title: 'rxDROffset1', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'rxDataRate2', title: 'rxDataRate2', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'rxDelay1', title: 'rxDelay1', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'rxFreq2', title: 'rxFreq2', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'supports32BitFCnt', title: 'supports32BitFCnt', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'supportsClassB', title: 'supportsClassB', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'supportsClassC', title: 'supportsClassC', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'supportsJoin', title: 'supportsJoin', filter: 'text', type: 'text', visible: false, editable: true },
    { dataIndex: 'tags', title: 'tags', filter: 'text', type: 'text', visible: false, editable: true },
    ]
}
