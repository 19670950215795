import { Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import {enableInputGroupAtom} from '../../components/UpdateItemForm/CommonForm.jsx'

function BooleanInput({element, dataName, title, required, disabledTargetValue, disabledVarName}) {

    const { t } = useTranslation()

    const [enableInputGroup] = useAtom(enableInputGroupAtom);

    const [checked, setChecked] = useState(true)

    useEffect(()=>{
        setChecked(element[dataName]|| false)
    }, [dataName, element])

    function onChange () {
        setChecked(!checked)
    }

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[{type: "boolean",required: required}]}
            valuePropName="checked"
            initialValue={checked }
        >
            <Switch
                checked={checked}
                onChange={onChange}
                disabled={disabledTargetValue === enableInputGroup[disabledVarName]}
            />
        </Form.Item>
    );
}

export default BooleanInput;
