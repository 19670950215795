import { Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import StaService from "../../../../../services/Sta/Sta.service";
import { useState } from "react";

export function ModalDelete({
  originalData,
  callback,
  setIsDeleteModalOpen,
  isDeleteModalOpen,
}) {
  let [staService] = useState(new StaService());
  const [messageApi, contextHolder] = message.useMessage();

  // Delete an element modal view
  const { t } = useTranslation();
  async function handleDeleteOk() {
    let result = await staService.delete(originalData.dev_EUI);
    setIsDeleteModalOpen(false);

    messageApi.open({
      type: "error",
      content:
        result.status === 200
          ? "Deleted successfully"
          : "Error trying to remove",
    });

    await callback();
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  return (
    <>
      {contextHolder}
      <Modal
        title="Delete Element"
        open={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      >
        <p>{t("Are you sure you want to remove this element?")}</p>
        {originalData.id && (
          <p>
            {t("ID")}: {originalData.id}
          </p>
        )}
        {originalData.name && (
          <p>
            {t("Name")}: {originalData.name}
          </p>
        )}
      </Modal>
    </>
  );
}
