import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/basicApi";
import { ENV } from "../../utils";

const { Text } = Typography;

function MqttServerSelector({ element, dataName, title, form }) {
  const { t } = useTranslation();
  const [mqttServersService] = useState(
    new BasicApi(ENV.Infraestructure_API + "/" + ENV.API_ROUTES.MQTTSERVER),
  );

  // TODO: Cambiar esto por una llamada a todos los NS del gateway a través de api al server
  const [data, setData] = useState([]);

  const onValueChange = (value) => {
    form.setFieldValue(dataName, value);
  };

  //generateFormOptions
  useEffect(() => {
    mqttServersService.getAll().then((value) => {
      if (value) {
        let result = value.db.result.map((element) => {
          return { name: element.profileName, id: element.id };
        });
        setData(result);
      }
    });
  }, [mqttServersService]);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[{ required: true }]}
    >
      <>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          defaultValue={element[dataName]}
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={data.map((user) => ({
            label: user.name,
            value: user.id,
          }))}
          onChange={onValueChange}
          // defaultValue={element.}
        />
      </>
    </Form.Item>
  );
}

export default MqttServerSelector;
