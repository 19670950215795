import { ColumnConfig } from '../../components/DynamicTable/Interfaces/ColumnConfig';
import CountryService from '../Countries/Countries.service';
import StateService from '../Countries/States.service';
import UserService from '../User/User.service';

export const CouncilColuns:ColumnConfig[] = [

    {dataIndex: 'name', title: 'Name',required: true,default: true,filter: 'text', type: 'text', visible: true, editable: true},

    {dataIndex: 'address', title: 'Address',required: true,default: true,filter: 'text', type: 'text',  visible: true, editable: true},

    {dataIndex: 'contact_person', title: 'Contact Person ',required:  false,default:  true, type: 'service',service: new UserService(), visible: true, editable: true},

    {dataIndex: 'phone', title: 'Phone', required: true, default: true, filter: 'text', type: 'text',  visible: true, editable: true},

    {dataIndex: 'country', title: 'Country', required: false, default: true, filter: 'text', type: 'service', service: new CountryService(),  visible: true, editable: true},
    // TODO: review hay que hacer muchos cambios para que obtenga del servicio getAll(:idCountry) desde addEditEntity 
   // {dataIndex: 'state', title: 'State', required: false, default: true, filter: 'text',  service: new StateService(), type: 'service',  visible: true, editable: true},

    {dataIndex: 'locality', title: 'City', required: false, default: true, filter: 'text', type: 'text',  visible: true, editable: true},

    {dataIndex: 'user_creator', title: 'User Creator ',required:  false,default:  false,filter: 'none ', type: 'text', visible: false, editable: false},

    {dataIndex: 'user_last_modificated', title: 'User Last  Modified',required : false,default : false,filter: 'none',type:  'text', visible: false, editable: false},

    {dataIndex: 'web', title: 'Web', required: false, default: false, filter: 'none', type: 'text',  visible: false, editable: true},

    {dataIndex: 'postalCode', title: 'Postal Code ',required:  false,default:  false,filter: 'text ', type: 'text', visible: false, editable: true},

    {dataIndex: 'users', title: 'Users', required: false, default: false, filter: 'none', type: 'service',  multiple:true ,service: new UserService(), visible: false, editable: true},

]