import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";

import { Outlet } from "react-router-dom";
import { useLocale } from "./Hooks/useLocale";
import { ConfigProvider, theme } from "antd";
import { useTheme } from "./Hooks/useTheme";

function App() {
  const { antdLang } = useLocale();
  const currTheme = useTheme();
  let themeColor = {
    token: {
      colorPrimary: "#815aed",
      colorSuccess: "#07b3fa",
      colorWarning: "#fa52aa",
      wireframe: false,
      colorInfo: "#b447d6",
      colorBgElevated: "#f5f5f5",
      colorBgLayout: "#dedede",
    },
  };
  if (currTheme === "dark") {
    themeColor = {
      token: {
        colorPrimary: "#815aed",
        colorSuccess: "#07b3fa",
        colorWarning: "#fa52aa",
        wireframe: false,
        colorInfo: "#b447d6",
        colorBgBase: "#161616",
        colorBgLayout: "#121212",
        userColor: "#46473",
      },
      algorithm: theme.darkAlgorithm,
    };
  }
  return (
    <ConfigProvider locale={antdLang} theme={themeColor}>
      <Outlet />
    </ConfigProvider>
  );
}

export default App;
