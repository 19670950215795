import { ENV } from "../../utils";
import axios from "axios";
import { getAllForAlarms,IServiceCRUD,IColumnForCRUD,uploadCSV } from "../interfaces.service";
import AuthService from "../Auth/Auth.service";

export default class GatewayService implements getAllForAlarms,IServiceCRUD,uploadCSV {
  url: string;
  token: string;
  topic:string|undefined;
  uploadEndpoint: string;
  constructor(topic:string|undefined= undefined) {
    this.token = AuthService.getToken();
    this.topic = topic;
    this.url = `${ENV.Infraestructure_API}/${topic}/gateways`;
    this.uploadEndpoint = `${ENV.Infraestructure_API}/gateways/upload`
  }
  
  async create (body: any){
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    let result:any =  await axios
       .post(`${this.url}`, { headers: headers, body: JSON.stringify(body) })
       .then((response) => response.data)
       .catch((err) => {console.log(err); return false});
    return result
  }
  async getById (id: string | number | null){
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    let result:any =  await axios
       .get(`${this.url}/${id}`, { headers: headers })
       .then((response) => response.data)
       .then((response) => response.db)
       .catch((err) => {console.log(err); return {}});
    return result
  }
  async getAll (filters: any, currentPage: number,pageSize: number ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const extra = `searchBy=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`;
    let result:any =  await axios
       .get(`${this.url}?${extra}`, { headers: headers })
       .then((response) => response.data)
       .catch((err) => {console.log(err); return {data: [], totalCount: 0 }});
    return result
  }
  async getAllForAlarms(filters: any = {},  currentPage: number = 1,pageSize: number = 10 ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const response:any = await axios
      .get(`${ENV.Infraestructure_API}/gatewaysForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, { headers: headers })
      .then((response) => response.data);
    return response
  }
  async update (id: string | number, body: any) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    let result:any =  await axios
       .put(`${this.url}/${id}`, { headers: headers, body: JSON.stringify(body) })
       .then((response) => response.data)
       .catch((err) => {console.log(err); return false});
    return result
  }
  async delete (id: string | number | string[] | number[])  {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    let result:any =  await axios
       .delete(`${this.url}/${id}`, { headers: headers })
       .then((response) => response.data)
       .catch((err) => {console.log(err); return false});
    return result
  }
  async getEnums () {return {}}
  getColumns(): IColumnForCRUD[]{
    return [
    { title: "lastSeen", dataIndex: "lastSeen", type: "lastSeen", lastSeenDiff: "3h", editable: false },
    { title: "id", dataIndex: "id", type: "text", visible: true },
    { title: "name", dataIndex: "name", type: "text", visible: true },
    { title: "description", dataIndex: "description", type: "text", visible: true },
    { title: "gatewayProfileID", dataIndex: "gatewayProfileID", type: "text", visible: true },
    { title: "tags", dataIndex: "tags", type: "text", visible: true },
    { title: "location", dataIndex: "location", type: "text", visible: true,
      defaultValue:`{"latitude":0,"longitude":0,"altitude":0,"source":"UNKNOWN","accuracy":0}` }
  ]}

  async processCsv(delimiter: any,serverFileName: any,aditionalData: any = null) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const body = JSON.stringify({...aditionalData,delimiter: delimiter,file_name: serverFileName});
    return await axios.post(`${ENV.Infraestructure_API}/gateways/process_csv`, body, {headers: headers});
  }
}
