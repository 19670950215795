import { Route } from "react-router-dom";

import { PrivateRoute } from "../../utils/PrivateRoute";
import AuthService, { posibleRoles } from "../../../services/Auth/Auth.service";
import Users from "../../../Pages/Users/Users";

export function UserRoutes() {
  return (
    <Route path="users" element={<PrivateRoute><Users CRUDPrivilages={{ create: AuthService.atLeastRole(posibleRoles.ADMIN_TECH), update: AuthService.atLeastRole(posibleRoles.ADMIN_TECH), delete: AuthService.atLeastRole(posibleRoles.ADMIN_TECH) }} /></PrivateRoute>} />
  );
}
