import { posibleRoles } from "../services/Auth/Auth.service";

export let menuItems = [];

//Home
menuItems.push({
  label: "Home",
  key: "",
});
//Gateway
menuItems.push({
  label: "Sensors",
  key: "sensors"
});

//Gateway
menuItems.push({
  label: "Gateway",
  key: "gateway",
  children: [
    { label: "Physical Gateways", key: "gateway/physical-gateways" },
    //{label: 'Groups',key: 'gateway/group',},
  ],
});

//MQTT Servers
menuItems.push({
  label: "MQTT Servers",
  key: "mqtt-server",
  visibility: [posibleRoles.SUPER_ADMIN, posibleRoles.ADMIN_TECH],
});

//Lora WAN
menuItems.push({
  label: "Lora WAN",
  key: "LoraWAN",
  children: [
    //{label: 'Network Servers',key: 'LoraWAN/networkserver'},
    { label: "Centralized", key: "LoraWAN/centralized" },
    //{ label: "Dashboard", key: "LoraWAN/dashboard" },
    //{ label: "Message", key: "LoraWAN/message" },
  ],
});

//Sensor
// menuItems.push({
//     label: 'Sensor',
//     key: 'sensor',
//     children: [
//         {label: 'Units', key: 'sensor/units',},
//         {label: 'Types', key: 'sensor/types',}
//     ]
// })
//Alumbrado
//const streetLight = process.env.STREETLIGHT ?  process.env.STREETLIGHT : "FALSE";
if (
  process.env.REACT_APP_STREETLIGHT !== undefined &&
  process.env.REACT_APP_STREETLIGHT.toUpperCase() === "TRUE"
) {
  menuItems.push({
    label: "Alumbrado",
    key: "alumbrado",
    children: [{ label: "Dispositivos", key: "alumbrado/dispositivo" }],
  });
}

//Server
// menuItems.push({
//     label: 'Server',
//     key: 'server',
// })

//User
menuItems.push({
  label: "User",
  key: "users",
  visibility: [
    posibleRoles.SUPER_ADMIN,
    posibleRoles.ADMIN_TECH,
    posibleRoles.ADMIN_COUNCIL,
  ],
});
//User Groups
menuItems.push({
  label: "Council",
  key: "user-groups/council",
  visibility: [posibleRoles.SUPER_ADMIN, posibleRoles.ADMIN_TECH],
  //{label: 'Business',key: 'user-groups/business',},
  //{label: 'Projects',key: 'user-groups/projects',},
});

//Logger
// menuItems.push({
//   label: "Logger",
//   key: "logger",
// });
menuItems.push({
  label: "Alarms",
  key: "alarms",
});

menuItems.push({ label: "___________", disabled: true });

//Modules

let module_children = [];
if (
  process.env.REACT_APP_MODULE_STREETLIGHT &&
  process.env.REACT_APP_MODULE_STREETLIGHT.toUpperCase() === "TRUE"
) {
  module_children.push({
    label: "Streetlight Control Cabinet",
    key: "ControlCabinet",
  });
}

if (
  process.env.REACT_APP_MODULE_STA &&
  process.env.REACT_APP_MODULE_STA.toUpperCase() === "TRUE"
) {
  module_children.push({
    label: "STA",
    key: "STA",
    visibility: [posibleRoles.SUPER_ADMIN, posibleRoles.ADMIN_TECH],
  });
}
/*
else{
    module_children =  [
        {label: 'Door',key: 'door',
            children: [
                {label: 'Doors',key: 'door/doors',},
                {label: 'Calendar',key: 'door/calendar',},
                {label: 'Requests',key: 'door/requests',},
                {label: 'Users',key: 'door/users',}
            ]
        },
        {label: 'Light',key: 'light',icon:<> </>,children: [
            {label: 'Energy Groups',key: 'light/groups',},
            {label: 'Energy Devices',key: 'light/devices',},
            {label: 'Requests',key: 'light/requests',},
            {label: 'Alarm',key: 'light/alarm',
                children: [{label: 'Notifications',key: 'light/alarm/notifications',},{label: 'List',key: 'light/alarm/list',}]
            },
            {label: 'Status',key: 'light/status',},{label: 'Planning',key: 'light/planning',},
            {label: 'Actuator',key: 'light/actuator',},{label: 'History',key: 'light/history',},
            {label: 'Control Cabinet',key: 'light/controlcabinet',},
        ]},
        {label: 'Water',key: 'water',icon:<> </>,children: [
            {
                label: 'General',key: 'water/general',icon:<> </>,
                children: [
                    {label: 'Municipal',key: 'water/general/municipal',},
                    {label: 'Diameter',key: 'water/general/diameter',},
                    {label: 'Service Fee',key: 'water/general/servicefee',},
                    {label: 'Consumption Block',key: 'water/general/consumptionblock',},
                    {label: 'Sewer Rate',key: 'water/general/sewerrate',},
                    {label: 'Canon',key: 'water/general/canon',},
                    {label: 'Counter',key: 'water/general/counter',},
                    {label: 'Investment',key: 'water/general/investment',},
                ]
            },
            {label: 'Alarms',key: 'water/alarms',},
            {label: 'Devices',key: 'water/devices',},
            {label: 'Groups',key: 'water/groups',},
            {label: 'Users',key: 'water/users',},
            {label: 'Hydric Balance',key: 'water/hydricbalance',},
            {label: 'Observations',key: 'water/observations',},
            {label: 'Map',key: 'water/map',},
            {label: 'Control',key: 'water/control',},
    ]},
    ]
}*/
menuItems.push({
  label: "Modules",
  key: "modules",
  visibility: [posibleRoles.SUPER_ADMIN, posibleRoles.ADMIN_TECH],
  children: module_children,
});
//Logger
