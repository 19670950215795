import { Drawer, List } from "antd";
import { MessageFilled } from "@ant-design/icons";
import styles from "./NotificationsDrawer.module.css";
import { useTranslation } from "react-i18next";

const NotificationsDrawer = ({ onClose, open, notifications }) => {
  const { t } = useTranslation();

  let notificationItems = [];

  for (let i = 0; i < notifications.length; i++) {
    notificationItems.push(
      <List.Item
        key={styles.item}
        className={styles.item}
        style={{ padding: "1rem" }}
      >
        <List.Item.Meta
          avatar={<MessageFilled />}
          title={notifications[i].title}
          description={notifications[i].description}
        />
      </List.Item>,
    );
  }

  return (
    <Drawer
      title={t("Notifications")}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <List>{notificationItems}</List>
    </Drawer>
  );
};

export default NotificationsDrawer;
