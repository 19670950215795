export const columns = [
  { title: "Mensaje", dataIndex: "message", key: "message" },
  { title: "Dev EUI", dataIndex: "dev_EUI", key: "dev_EUI" },
  { title: "Versión Salt", dataIndex: "salt_version", key: "salt_version" },
  { title: "Valor Salt", dataIndex: "salt_value", key: "salt_value" },
];

export const checkData = (data) => {
  let dataTable = [];

  data.forEach((item, index) => {
    item.mqtt_response_code = item.mqtt_response
      ? item.mqtt_response.mqtt_response_code
        ? item.mqtt_response.mqtt_response_code
        : "NO_RESPONSE"
      : "NO_RESPONSE";
    item.mqtt_response = item.mqtt_response
      ? item.mqtt_response.response
        ? item.error
        : "NO_RESPONSE"
      : "NO_RESPONSE";
    item.salt_version = item.salt
      ? item.salt.version
        ? item.salt.version
        : "NO_RESPONSE"
      : "NO_RESPONSE";
    item.salt_value = item.salt
      ? item.salt.salt
        ? item.salt.salt
        : "NO_RESPONSE"
      : "NO_RESPONSE";
    item.key = index;

    dataTable.push(item);
  });

  return dataTable;
};
