import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
  Upload,
} from "antd";
import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";

const { Link } = Typography;

// --------------------------------------------
// UPLOAD FILE
// --------------------------------------------
// TODO: add actual treatmen to the file
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
// --------------------------------------------

function ImportWaterDeviceButton() {
  const { t } = useTranslation();

  // Modal view controls
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }
  // -------------------------------

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onFinish = (values) => {
    setIsModalOpen(false);
    // TODO: update with api

    // Show Created text
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    setIsModalOpen(false);
  };
  // --------------------------------------------

  const unitsOptions = [
    {
      value: "1",
      label: "Num1",
    },
    {
      value: "2",
      label: "Num2",
    },
  ];

  return (
    <>
      <Button onClick={openModal}>{t("Import")}</Button>
      <Modal
        title={t("Import")}
        open={isModalOpen}
        footer={null}
        onCancel={onFinishFailed}
      >
        <Formik
          initialValues={{
            remember: true,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              labelAlign="left"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item>
                {t("Download")} <Link>{t("import template")}</Link>
              </Form.Item>
              <Form.Item valuePropName="checked" name={"sentiloParameters"}>
                <Checkbox>{t("Add Sentilo Parameters")}</Checkbox>
              </Form.Item>
              <Form.Item label={"Auth Token"} name={"authToken"}>
                <Input></Input>
              </Form.Item>
              <Form.Item label={"Provider"} name={"provider"}>
                <Input></Input>
              </Form.Item>
              <Form.Item valuePropName="checked" name={"addUnits"}>
                <Checkbox>{t("Add Units")}</Checkbox>
              </Form.Item>
              <Form.Item label={"Units"} name={"unit"}>
                <Select
                  style={{
                    width: 120,
                  }}
                  allowClear
                  options={unitsOptions}
                />
              </Form.Item>
              <Form.Item label={t("Import")}>
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>
                    {t("Click to Upload")}
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ImportWaterDeviceButton;
