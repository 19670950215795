import { Modal, Input, Upload, message, Select } from "antd";
import { useState ,useEffect} from "react";
import { InboxOutlined } from "@ant-design/icons";
import { ENV } from "../../../../../utils";
import StaService from "../../../../../services/Sta/Sta.service";
import SensorWithoutTopicService from "../../../../../services/SensorWithoutTopic/SensorWithoutTopic.service";

export function ModalUpdateFirmware({ modalOpen, setModalOpen, callback }) {
  let [delimiter] = useState(",");
  let [serverFileName, setServerFileName] = useState("");
  const [staService] = useState(new StaService());
  const [buildNumber, setBuildNumber] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [deviceService] = useState(new SensorWithoutTopicService());
  const [availableDevices, setAvailableDevices] = useState([]);

  const validateFile = (file) => {
    const validExtensions = [".bin.gz"];
    return validExtensions.some((ext) => file.name.toLowerCase().endsWith(ext));
  };

  const { Dragger } = Upload;

  const sendData = async () => {
    await staService.processCsv(delimiter, serverFileName);
    setModalOpen(false);
    callback();
  };

  const propsUpload = {
    name: "firmware",
    action: `${ENV.Sta_API}/sta/firmware`,
    multiple: false,
    ShowUploadList: false,
    maxCount: 1,
    data: () => ({
      buildNumber, 
      deveui: selectedDevices,
    }),
    beforeUpload: (file) => {
      return validateFile(file) ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        message.success(`${info.file.name} se ha subido con éxito`);
        setServerFileName(info.file.response.filename);
      } else if (info.file.status === "error") {
        message.error(`La subida del archivo ${info.file.name} falló`);
      }
    },
  };
  useEffect(() => {
    deviceService.getAll({},0,0).then((data) =>{
        let result = []
        data.data.map((item) =>{
          result.push({label: item.devEUI, value: item.devEUI})
        })
        setAvailableDevices(result);
      })
  },[])

  return (
    <Modal
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      title="Update Firmware"
      onOk={sendData}
    >
      <Input 
        placeholder="Enter Build Number"
        value={buildNumber}
        onChange={(e) => setBuildNumber(e.target.value)} 
        style={{ marginBottom: '20px' }}
      />

      <Select
        mode="multiple" 
        placeholder="Select Sensors"
        showSearch 
        style={{ width: '100%', marginBottom: '20px' }}
        value={selectedDevices}
        onChange={(value) => setSelectedDevices(value)} 
        options={availableDevices} 
      />

      <Dragger {...propsUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Upload the firmware</p>
      </Dragger>
    </Modal>
  );
}
