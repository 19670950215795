import React, { createContext, useEffect, useState, ReactNode } from "react";
import AuthService from "../../services/Auth/Auth.service";

export interface AuthContextType {
  isAuthenticated: boolean | null;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>> | null;
  accessToken: string | null;
  user: any | null; 
  login: (data?: any) => Promise<boolean>;
  logout: () => void;
  getUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: null,
  setIsAuthenticated: null,
  accessToken: null,
  user: null,
  login: async () => false,
  logout: () => {},
  getUser: async () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(false);
  const [user, setUser] = useState<any | null>(null); 
  const [accessToken, setAccessToken] = useState<string | null>("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setUser(await AuthService.getTokenData());
        setAccessToken(AuthService.getToken());
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    fetchUser();
  }, []);

  const getUser = async () => {
    setUser(await AuthService.getTokenData());
  };

  const login = async (data?: any): Promise<boolean> => {
    try {
      await AuthService.login(data);
      setAccessToken(AuthService.getToken());
      setUser(await AuthService.getTokenData());
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      setIsAuthenticated(false);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    setAccessToken(null);
    AuthService.logout();
  };

  const data: AuthContextType = {
    isAuthenticated,
    setIsAuthenticated,
    accessToken,
    user,
    logout,
    login,
    getUser,
  };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
