import React from "react";
import { Tabs } from "antd";
import Alarms from "../../AlarmSystem/Alarms/Alarms";
import Notification from "../../AlarmSystem/Notifications/Notifications";
import { useState } from "react";

import StreetlightTab from "./StreetlightTab/StreetlightTab";
import StreetlightService from "../../../services/Streetlight/Streetlight.service";

export default function Streetlight({
  ControlCabinetId,
}: {
  ControlCabinetId: number;
}) {
  let [tabValue, setTabValue] = useState<string>("streetlight");

  const items: any = [
    {
      key: "streetlight",
      label: "Streetlight",
      children: <StreetlightTab ControlCabinetId={ControlCabinetId} />,
    },
    {
      key: "alarms",
      label: "Alarms",
      children: <Alarms hardwares={["streetlights"]} hardwareServices={[new StreetlightService(ControlCabinetId)]}/>,
    },
    {
      key: "notifications",
      label: "Notifications",
      children: <Notification hardwares={["streetlights"]} />,
    },
  ];

  return <Tabs activeKey={tabValue} items={items} onChange={setTabValue} />;
}
