import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/index";
import { BellFilled, UserOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Space,
  Col,
  theme,
  Select,
  Input,
  Tooltip,
  Avatar,
  Badge,
  Switch,
  Image
} from "antd";
import { useEffect, useState } from "react";
import { useLocaleUpdate } from "../../Hooks/useLocale";
import { useThemeUpdate } from "../../Hooks/useTheme";
import { useTranslation } from "react-i18next";
import styles from "./NavBar.module.css";
import NotificationsDrawer from "../NotificationsDrawer/index";
import logo from "../../assets/logo-banner.png";
import { ENV } from "../../utils";

const { useToken } = theme;
const { Search } = Input;

function NavBar() {
  const [cssLogo, setCssLogo] = useState({});

  const { isAuthenticated, user } = useAuth();
  const switchTheme = useThemeUpdate();

  const changeLanguage = useLocaleUpdate();
  const { t, i18n } = useTranslation();

  const { token } = useToken();

  const handleSwitchTheme = () => {
    switchTheme();
  };
  useEffect(() => {
    switch(ENV.DIPUTACION){
      case "valencia":
        setCssLogo({width:"700px"});
        break;
      case "castellon":
        setCssLogo({width: "200px"});
        break;
      default:
        setCssLogo({width: "200px"})
        break;
    }
  },[]);
  // Language change
  const handleLangChange = (value) => {
    changeLanguage(value);
    i18n.changeLanguage(value);
  };

  // search in the navbar
  const onSearch = (value) => console.log(value);

  // Notifications Drawer
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // TO see the current user
  const getUser = () => {};

  return (
    <>
      <div className={styles.content}>
        <Row justify={"space-between"}>
          <Col
            flex={1}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
          <Image src={logo} style={cssLogo}/>
          </Col>
          <Col flex={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space size={23}>
              🌙
              <Switch
                checkedChildren={"Dark"}
                unCheckedChildren={"Light"}
                defaultChecked={false}
                onChange={handleSwitchTheme}
              />
              <Select
                defaultValue="English"
                style={{
                  width: 120,
                }}
                onChange={handleLangChange}
                options={[
                  {
                    value: "en_US",
                    label: "English",
                  },
                  {
                    value: "es_ES",
                    label: "Español",
                  },
                ]}
              />
              {isAuthenticated && new Date(user.exp * 1000) > new Date() ? (
                <>
                  <Tooltip
                    title={
                      <Search
                        placeholder="input search text"
                        allowClear
                        enterButton={t("Search")}
                        size="large"
                        onSearch={onSearch}
                      />
                    }
                  >
                    <SearchOutlined
                      style={{ color: token.colorText, fontSize: "large" }}
                    />
                  </Tooltip>
                  <Badge count={1} overflowCount={10}>
                    <BellFilled
                      onClick={showDrawer}
                      style={{ color: token.colorText, fontSize: "large" }}
                    />
                  </Badge>
                  <NotificationsDrawer
                    onClose={onClose}
                    open={open}
                    notifications={[
                      {
                        title: "Notification 1",
                        description: "sgadfgasdg",
                      },
                      {
                        title: "Notification 1",
                        description: "463525734747",
                      },
                      {
                        title: "Notification 636",
                        description: "463475685hgffbsdfg",
                      },
                    ]}
                  />
                  <Link className="nav-link" to={"myuser"}>
                    <Avatar
                      onClick={getUser}
                      size="large"
                      icon={<UserOutlined style={{ color: token.colorText }} />}
                    />
                  </Link>
                </>
              ) : (
                <Link className="nav-link" to={"/sign-in"}>
                  Login
                </Link>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default NavBar;
