import { Input } from "antd";
export const dataAbp = [
  {
    placeholder: "Device address",
    label: "Device address:",
    name: "deviceAddress",
    value: "",
    component: Input,
    type: "",
  },
  {
    placeholder: "Network Session",
    label: "Network Session:",
    name: "networkSession",
    value: "",
    component: Input,
    type: "",
  },
  {
    placeholder: "Application Session Key",
    label: "Application Session Key:",
    name: "applicationSessionKey",
    value: "",
    component: Input,
    type: "",
  },
  {
    placeholder: "Uplink Frame Counter",
    label: "Uplink Frame Counter:",
    name: "uplinkFrameCounter",
    value: "",
    component: Input,
    type: "",
  },
  {
    placeholder: "Downlink Frame Counter",
    label: "Downlink Frame Counter:",
    name: "downlinkFrameCounter",
    value: "",
    component: Input,
    type: "",
  },
];

export const dataOtaa = [
  {
    placeholder: "Application Key",
    label: "Application Key:",
    name: "nwkKey",
    value: "",
    component: Input,
    type: "",
  },
];
