import { Flex, Input, Row, Col, Checkbox, Select } from "antd";
import {
  RightCircleTwoTone,
  PlusCircleTwoTone,
  DeleteOutlined,
} from "@ant-design/icons";

function SensorSentilo({ sensorData, setSensorData }) {
  const data = {
    WasteContainer: [
      "batteryLevel",
      "temperature ",
      "boardTemperature",
      "accelerometerPosition",
      "storedWasteKind",
      "fillingLevel",
      "cargoWaste",
      "madeOf",
      "madeOfCode",
      "name",
      "modelName",
      "width",
      "height",
      "depth",
      "weight",
    ],
    ParkingSpot: ["status", "batteryLevel", "batteryAlarm", "category"],
    NoiseLevelObserved: [
      "batteryLevel",
      "boardTemperature",
      "LA1",
      "LA10",
      "LA50",
      "LA90",
      "LA95",
      "LA99",
      "LAmin",
      "LAmax",
      "LAeq",
      "LA",
      "LC",
    ],
    AirQualityObserved_partículas: [
      "batteryLevel",
      "boardTemperature",
      "temperature ",
      "humidity",
      "relativeHumidity",
      "PM1_0",
      "PM2_5",
      "avgPM2_5_h",
      "PM10",
    ],
    AirQualityObserved_gas: [
      "NO2",
      "O3",
      "CO",
      "CO2",
      "SO2",
      "category",
      "batteryLevel",
    ],
    IrrigationValve: [
      "batteryLevel",
      "tamperOpened",
      "valveOpening",
      "valveOpened",
    ],
    Humidity: [
      "batteryLevel",
      "boardTemperature",
      "soilTemperature",
      "soilMoisture",
      "temperature ",
      "humidity",
    ],
    Beacon: ["rssi", "category"],
    WeatherObserved: [
      "temperature",
      "humidity",
      "relativeHumidity",
      "precipitation",
      "windSpeed ",
      "windDirection",
      "atmosphericPressure",
      "maxWindGust",
      "solarRadiation",
      "uVIndex",
      "evapotranspiration",
      "dewPoint",
      "heatIndex",
      "windChill",
      "avgWindSpeed_10min",
      "avgWindGust_10min",
      "avgWindDirection_10min",
      "avgPrecipitation_15min",
      "avgPrecipitation_1h",
      "totalPrecipitation",
      "precipitation_m",
      "precipitation_y",
      "illuminance",
      "visibility",
      "mor",
    ],
    WifiBluDetector: [
      "countWifi",
      "macHashWifi",
      "countBluetooth",
      "macHashBluetooth",
      "countBLE",
      "macHashBLE",
      "stayWifi",
      "stayWifi_15min",
      "stayWifi_less4h",
      "stayWifi_greater4h",
    ],
    AeroAllergenObserved: ["pollenPMgreater10"],
    OffStreetParking: ["totalSpotNumber", "aviableSpotNumber"],
    EnergyMeter: [
      "phaseVoltageL1",
      "phaseVoltageL2",
      "phaseVoltageL3",
      "currentL1",
      "currentL2",
      "currentL3",
      "activePower",
      "apparentPower",
      "reactivePower",
      "cosPhi",
      "totalReactiveEnergyExport",
      "totalActiveEnergyExport",
      "totalActiveEnergyImport",
      "totalApparentEnergyExport",
      "totalApparentEnergyImport",
    ],
    SmartCamera: [
      "countBikeSmcam_d",
      "countBusSmcam_d",
      "counrCarSmcam_d",
      "countCarSmcamTwoDirectionsA_d",
      "countCarSmcamTwoDirectionsB_d",
      "countMediumbusSmcam_d",
      "countMinibusSmcam_d",
      "countPedestrianSmcam_d",
      "countPedestrianSmcamTwoDirectionsA_d",
      "countPedestrianSmcamTwoDirectionsB_d",
      "countSuvmpvSmcam_d",
      "countTrikeSmcam_d",
      "countTruckSmcam_d",
      "countUnknownSmcam_d",
      "countVanSmcam_d",
    ],
    WaterQualityObserved: ["temperature", "pH", "CL"],
    WaterExternal: ["waterMeterLecture"],
    WaterHeater: [
      "consignedWaterTemperature",
      "entryWaterTemperature",
      "fuelTankLevel",
      "pelletTankLevel",
      "waterMeterLecture",
    ],
    ElectricityMeter: [
      "statusDifferentialSwitch",
      "boxDoorOpen",
      "thermalMagneticCircuitBreakers",
    ],
    TrafficFlowObserved: ["intensity"],
    Building: ["doorOpened"],
  };
  const boxStyle = {
    borderRadius: 6,
    maxHeight: "300px",
  };

  const columStyle = {
    padding: "8px 0",
  };
  const rowStyle = {
    padding: "8px 0",
    width: "100%",
  };

  const changeUrl = (e) => {
    setSensorData({
      ...sensorData,
      sentilo: { ...sensorData.sentilo, url: e.target.value },
    });
  };
  const changeProvider = (e) => {
    setSensorData({
      ...sensorData,
      sentilo: { ...sensorData.sentilo, provider: e.target.value },
    });
  };
  const changeToken = (e) => {
    setSensorData({
      ...sensorData,
      sentilo: { ...sensorData.sentilo, token: e.target.value },
    });
  };
  const checkedCreateSentilo = (e) => {
    setSensorData({ ...sensorData, createSentiloServer: e.target.checked });
  };

  const addSensorType = () => {
    let tmp_sentilo = JSON.parse(JSON.stringify(sensorData.sentilo));
    tmp_sentilo.subTypes.push({ subType: "", value: "" });
    setSensorData({ ...sensorData, sentilo: { ...tmp_sentilo } });
  };
  const selectSensorType = (value) => {
    let tmp_Sentilo = sensorData.sentilo;
    tmp_Sentilo.type = value;
    setSensorData({ ...sensorData, sentilo: { ...tmp_Sentilo } });
  };
  const deleteItem = (index) => {
    let tmp_sensorTypeData = JSON.parse(
      JSON.stringify(sensorData.sentilo.subTypes),
    );
    tmp_sensorTypeData.splice(index, 1);
    setSensorData({
      ...sensorData,
      sentilo: { ...sensorData.sentilo, subTypes: tmp_sensorTypeData },
    });
  };

  const indexSubTypeChange = (value, index) => {
    let tmp_sensorType = sensorData.sentilo.subTypes;
    tmp_sensorType[index].subType = value;
    setSensorData({
      ...sensorData,
      sentilo: { ...sensorData.sentilo, subTypes: tmp_sensorType },
    });
  };

  const indexValueChange = (event, index) => {
    let tmp = JSON.parse(JSON.stringify(sensorData.sentilo.subTypes));
    tmp[index].value = event.target.value;
    setSensorData({
      ...sensorData,
      sentilo: {
        ...sensorData.sentilo,
        subTypes: tmp,
      },
    });
  };

  return (
    <div style={{ height: "300px" }}>
      <br />
      <Checkbox
        checked={sensorData.createSentiloServer}
        onChange={checkedCreateSentilo}
      >
        Create Sentilo
      </Checkbox>
      <br />
      <br />

      <Flex
        vertical
        style={boxStyle}
        gap={16}
        hidden={!sensorData.createSentiloServer}
      >
        <Row
          style={rowStyle}
          gap={16}
          justify={"space-around"}
          align={"flex-start"}
        >
          <Col span={5}>
            <label>Provider</label>
            <Input
              onChange={changeProvider}
              value={sensorData.sentilo.provider}
            />
          </Col>
          <Col span={5}>
            <label>Url</label>
            <Input onChange={changeUrl} value={sensorData.sentilo.url} />
          </Col>
          <Col span={5}>
            <label>Token</label>
            <Input onChange={changeToken} value={sensorData.sentilo.token} />
          </Col>
          <Col span={5}>
            <label>Sensor Type</label>
            <Select
              onChange={selectSensorType}
              defaultValue={sensorData.sentilo.type}
              style={{ width: "100%" }}
              options={Object.keys(data).map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Col>
        </Row>
        <Row style={{ overflow: "auto", width: "100%" }}>
          {sensorData.sentilo.subTypes.map((_, index) => (
            <Row
              key={index}
              style={rowStyle}
              gap={16}
              justify={"space-around"}
              align={"flex-start"}
            >
              <Col style={columStyle} span={5}></Col>
              <Col span={5} align={"right"} justify={"center"}>
                <DeleteOutlined
                  onClick={(event) => deleteItem(event, index)}
                  hidden={index === 0}
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                />
                <RightCircleTwoTone
                  twoToneColor="#8491a3"
                  style={{ fontSize: "20px" }}
                />
              </Col>
              <Col style={columStyle} span={5}>
                <label>Sensor Sub Type</label>
                <Select
                  onChange={(event) => indexSubTypeChange(event, index)}
                  style={{ width: "100%" }}
                  options={
                    Object.keys(data).includes(sensorData.sentilo.type)
                      ? data[sensorData.sentilo.type].map((item) => ({
                          value: item,
                          label: item,
                        }))
                      : []
                  }
                />
              </Col>
              <Col style={columStyle} span={5}>
                <label>Sensor Value</label>
                <Input
                  onChange={(event) => indexValueChange(event, index)}
                  value={sensorData.sentilo.subTypes[index].value}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          ))}
        </Row>
        <Row
          style={rowStyle}
          gap={16}
          justify={"space-around"}
          align={"flex-start"}
        >
          <Col span={5}></Col>
          <Col span={5} align={"right"}>
            <PlusCircleTwoTone
              onClick={addSensorType}
              twoToneColor="#87D68D"
              style={{ fontSize: "20px" }}
            />
          </Col>
          <Col span={5}></Col>
          <Col span={5}></Col>
        </Row>

        <Row
          style={rowStyle}
          gap={16}
          justify={"space-around"}
          align={"flex-start"}
        >
          <Col style={columStyle} span={13} />
          <Col style={columStyle} span={4}></Col>
        </Row>
      </Flex>
    </div>
  );
}
export default SensorSentilo;
