import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/basicApi";

const { Text } = Typography;

function EnumSelector({ element, route, title, form, dataName }) {
  const { t } = useTranslation();
  const [api] = useState(new BasicApi(route));
  const [data, setData] = useState([]);

  const onValueChange = (value) => {
    form.setFieldValue(dataName, value);
  };

  //generateFormOptions
  useEffect(() => {
    api.getInfraestructureEnums().then((value) => {
      setData(value[dataName]);
    });
  }, [api, dataName]);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[{ type: "string", required: true }]}
    >
      <>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={data.map((value) => ({ label: value, value: value }))}
          onChange={onValueChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </>
    </Form.Item>
  );
}

export default EnumSelector;
