import { Form, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CountryService from "../../services/Countries/Countries.service";
import StateService from "../../services/Countries/States.service";

const { Text } = Typography;

function CountryStateSelector({ element, form }) {
  const [countriesService] = useState(new CountryService());
  const [stateService] = useState(new StateService());

  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("Andorra");

  useEffect(() => {
    countriesService.getAll().then((value) => setCountries(value));
    if (element) {
      stateService.getAll(element["country"]).then((value) => {
        setStates(value);
        setSelectedState(states[0]);
      });
    }
  }, [countriesService]);

  /**
   * Loads states for the selected country
   * @param {String} countryName
   */
  const handleCountryChange = (countryName) => {
    form.setFieldValue("country", countryName);
    countriesService.getStates(countryName).then((value) => {
      setStates(value);
      // Should reset the selected state
      setSelectedState(value[0]);
    });
  };

  const onStateChange = (value) => {
    form.setFieldValue("state", value);
    setSelectedState(value);
  };

  useEffect(() => {
    setSelectedState(states[0]);
  }, [states]);

  return (
    <>
      <Form.Item
        key={"country"}
        label={t("Country")}
        name={"country"}
        rules={[{ required: true }]}
      >
        <>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("Search to Select")}
            onChange={handleCountryChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={countries.map((country) => ({
              label: country,
              value: country,
            }))}
            defaultValue={element?.country}
          />
          <Text>{element?.country}</Text>
        </>
      </Form.Item>

      <Form.Item key={"state"} label={t("State")} name={"state"} rules={[{}]}>
        <>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("Search to Select")}
            onChange={onStateChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={states.map((state) => ({ label: state, value: state }))}
            defaultValue={element?.state}
          />
          <Text>{element?.state}</Text>
        </>
      </Form.Item>
    </>
  );
}

export default CountryStateSelector;
