import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { useParams } from "react-router-dom";
function PublishSection({  isConnected,  handlePublishTopic,  element,  isCentralized,}) {

  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const params = useParams();
  useEffect(() => {
    if (element == "sensors") {
      setTopic(
        `${params.physicalGatewayId}/application/${params.applicationId}/device/${params.devEUI}/tx`
      );
    } else if (element == "organization") {
      setTopic(`${params.physicalGatewayId}/gateway_requests/request`);
    }
  }, [params.physicalGatewayId]);
  return (
    <div className="col-md-6 border-end" style={{ flex: "1" }}>
      <div className="topic-publish-section" style={{ height: "80%" }}>
        <div className="d-flex justify-content-between align-items-center">
          <Input
            type="text"
            className="mb-2 me-2"
            value={topic}
            placeholder={topic}
            onChange={(e) => setTopic(e.target.value)}
          />
          <Button
            className="mb-2"
            disabled={
              !isConnected ||
              topic.length == 0 ||
              (element == "sensors" && isCentralized)
            }
            onClick={() => {
              handlePublishTopic({ topic, message });
            }}
          >
            Publish
          </Button>
        </div>
        <Input
          style={{ height: "100%" }}
          className="mb-2"
          rows="5"
          value={message}
          placeholder="Message to send"
          onChange={(e) => setMessage(e.target.value)}
        ></Input>
      </div>
    </div>
  );
}

export default PublishSection;
