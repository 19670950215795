import { ENV } from "../../utils";
import AuthService from "../Auth/Auth.service";
import {
  IColumnForCRUD,
  IServiceCRUD,
  getAllForAlarms,
  uploadCSV,
} from "../interfaces.service";
import axios from "axios";


export default class StreetlightService implements IServiceCRUD, getAllForAlarms,uploadCSV {
  url: string;
  enums = null;
  ControlCabinetId: number;
  token: string;
  uploadEndpoint:string
  constructor(ControlCabinetId: number) {
    this.ControlCabinetId = ControlCabinetId;
    this.url = `${ENV.Streetlight_API}/controlCabinet/${ControlCabinetId}/streetlight`;
    this.uploadEndpoint =  `${ENV.Streetlight_API}/upload/csv`
    this.token = AuthService.getToken();
  }
  async processCsv(delimiter: string = ",",serverFileName: string,aditionalData: any = null) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const path: string = `${this.url}/process_csv`;
    const body: string = JSON.stringify({...aditionalData,delimiter: delimiter,file_name: serverFileName});
    return await fetch(path, { method: "POST", headers: headers, body: body })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  async getEnums() {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    if (this.enums) return this.enums;
    this.enums =  await fetch(`${ENV.Streetlight_API}/enum`,{method: 'GET',headers: headers})
        .then(response => response.json())
        .catch((error)=>{console.log('Error:', error);return {}})
    return this.enums
  }
  async getAll(filter: any, currentPage: number,pageSize: number ) {
    let tmp_filters = { ...filter };
    Object.keys(filter).map((item) => {
      if (!tmp_filters[item] || tmp_filters[item] === "")
        delete tmp_filters[item];
      return null;
    });
    const offset = (currentPage - 1) * pageSize;
    const path: string = `${this.url}?filters=${JSON.stringify(tmp_filters)}&limit=${pageSize}&offset=${offset}`;
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    let result = await fetch(path, { method: "GET", headers: headers })
      .then((response) => response.json())
      .then((response) => {
        response.data = response.data.map((item: any) => ({ key: item.id, ...item }));
        return response;
      })
      .catch((error) => {console.log(error);return []});
    return result;
  }
  async getAllForAlarms(filters: any = {},  currentPage: number = 1,pageSize: number = 10 ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const response:any = await axios
      .get(`${this.url}/getAllForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, { headers: headers })
      .then((response) => response.data);
    return response
  }

  async create(body:any={}) {
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      return await fetch(this.url,{ method: 'POST',headers: headers,body: JSON.stringify(body)})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);})
  }
  async update(id:number|string="", body:any={}) {
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      return await fetch(`${this.url}/${id}`,{ method: 'PUT',headers: headers,body: JSON.stringify(body)})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);})
  }
  async delete(ids:number|string|number[]|string[]) {
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      ids = Array.isArray(ids)? ids.join(","):ids.toString()
      return await fetch(`${this.url}/${ids}`,{ method: 'DELETE',headers: headers})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);})
  }
  async getById(id:string|number|null){
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      return await fetch(`${this.url}/${id}`,{ method: 'GET',headers: headers})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);return {}})
  }
  getColumns(): IColumnForCRUD[] {
    return [
      { title: "lastSeen",dataIndex: "lastSeen",type: "lastSeen",lastSeenDiff: "6h",editable: false},
      { title: "id_plataforma", dataIndex: "id_platform", type: "text" },
      { title: "id_uvax", dataIndex: "id_uvax", type: "text" },
      { title: "is static", dataIndex: "isStatic", type: "boolean" },
      { title: "latitude", dataIndex: "latitude", type: "float" },
      { title: "longitude", dataIndex: "longitude", type: "float" },
      /*{ title: 'cuadro',dataIndex: 'cuadro',type: "text"},*/
      { title: "circuito", dataIndex: "circuit", type: "text" },
      { title: "poblacion", dataIndex: "poblation", type: "text" },
      { title: "descripcion", dataIndex: "description", type: "text" },

      //{ title: 'vendor',dataIndex: 'vendor',type: "enum",subtype:"vendor",visible:false},
      /*{ title: 'timeInstant',dataIndex: 'timeInstant',type: "date",visible:false},
            { title: 'latitude',dataIndex: 'latitude',type: "float",visible:true},
            { title: 'longitude',dataIndex: 'longitude',type: "float",visible:true},
            { title: 'refStreetlightControlCabinet',dataIndex: 'refStreetlightControlCabinet',type: "text",visible:false},
            { title: 'status',dataIndex: 'status',type: "enum",subtype:"status",visible:false},
            { title: 'powerState',dataIndex: 'powerState',type: "enum",subtype:"onOff2",visible:false},
            { title: 'refStreetlightGroup',dataIndex: 'refStreetlightGroup',type: "text",visible:false},
            { title: 'refStreetlightZone',dataIndex: 'refStreetlightZone',type: "text",visible:false},
            { title: 'dateLastSwitchingOn',dataIndex: 'dateLastSwitchingOn',type: "date",visible:false},
            { title: 'dateLastSwitchingOff',dataIndex: 'dateLastSwitchingOff',type: "date",visible:false},
            { title: 'controllingMethod',dataIndex: 'controllingMethod',type: "text",visible:false},
            { title: 'dateServiceStarted',dataIndex: 'dateServiceStarted',type: "date",visible:false},
            { title: 'illuminanceLevel',dataIndex: 'illuminanceLevel',type: "float",visible:false},
            
            { title: 'switchOnOff',dataIndex: 'switchOnOff',type: "boolean",visible:false},
            { title: 'switchOnOff_status',dataIndex: 'switchOnOff_status',type: "enum",subtype:"status",visible:false},
            { title: 'switchOnOff_info',dataIndex: 'switchOnOff_info',type: "text",visible:false},
        
            
            { title: 'setIlluminanceLevel',dataIndex: 'setIlluminanceLevel',type: "boolean",visible:false},
            { title: 'setIlluminanceLevel_status',dataIndex: 'setIlluminanceLevel_status',type: "enum",subtype:"status",visible:false},
            { title: 'setIlluminanceLevel_info',dataIndex: 'setIlluminanceLevel_info',type: "text",visible:false},
        
            { title: 'setWorkingMode',dataIndex: 'setWorkingMode',type: "boolean",visible:false},
            { title: 'setWorkingMode_status',dataIndex: 'setWorkingMode_status',type: "enum",subtype:"status",visible:false},
            { title: 'setWorkingMode_info',dataIndex: 'setWorkingMode_info',type: "text",visible:false},
        
            { title: 'activePower',dataIndex: 'activePower',type: "boolean",visible:false},
            { title: 'dateMeteringStarted',dataIndex: 'dateMeteringStarted',type: "date",visible:false},
            { title: 'delay',dataIndex: 'delay',type: "float",visible:false},
            { title: 'luminosity',dataIndex: 'luminosity',type: "float",visible:false},
            { title: 'serialNumber',dataIndex: 'serialNumber',type: "text",visible:false},
        
            { title: 'manufacturerName',dataIndex: 'manufacturerName',type: "text",visible:false},
            { title: 'timer',dataIndex: 'timer',type: "text",visible:false},
            { title: 'workingMode',dataIndex: 'workingMode',type: "enum",subtype:"status",visible:false},
            { title: 'image',dataIndex: 'image',type: "text",visible:false},
            { title: 'refStreetlightControlContactor',dataIndex: 'refStreetlightControlContactor',type: "text",visible:false},
            { title: 'reactivePower',dataIndex: 'reactivePower',type: "float",visible:false},
            { title: 'refStreetlightModel',dataIndex: 'refStreetlightModel',type: "text",visible:false},
            { title: 'annotations',dataIndex: 'annotations',type: "text",visible:false},
            { title: 'circuit',dataIndex: 'circuit',type: "text",visible:false},
            { title: 'dateLastLampChange',dataIndex: 'dateLastLampChange',type: "date",visible:false},
            { title: 'description',dataIndex: 'description',type: "date",visible:false},
            { title: 'lanternHeight',dataIndex: 'lanternHeight',type: "float",visible:false},
            { title: 'locationCategory',dataIndex: 'locationCategory',type: "text",visible:false},
            { title: 'source',dataIndex: 'source',type: "text",visible:false},
            { title: 'areaServed',dataIndex: 'areaServed',type: "text",visible:false},
            { title: 'intensity',dataIndex: 'intensity',type: "float",visible:false},
            { title: 'powerFactor',dataIndex: 'powerFactor',type: "float",visible:false},
            { title: 'temperature',dataIndex: 'temperature',type: "float",visible:false},
            { title: 'dateLastCommanded',dataIndex: 'dateLastCommanded',type: "float",visible:false},
            { title: 'enableHistoricCommand',dataIndex: 'enableHistoricCommand',type: "float",visible:false},
            { title: 'lastCommander',dataIndex: 'lastCommander',type: "text",visible:false},
            { title: 'lastCommanderMessage',dataIndex: 'lastCommanderMessage',type: "text",visible:false},
            { title: 'commandTimeinstant',dataIndex: 'commandTimeinstant',type: "date",visible:false},
            { title: 'workingHours',dataIndex: 'workingHours',type: "float",visible:false},
            { title: 'resetWorkingHours',dataIndex: 'resetWorkingHours',type: "boolean",visible:false},
            { title: 'resetWorkingHours_info',dataIndex: 'resetWorkingHours_info',type: "text",visible:false},
            { title: 'resetWorkingHours_status',dataIndex: 'resetWorkingHours_status',type: "enum",subtype:"status",visible:false},
            { title: 'name',dataIndex: 'name',type: "text",visible:false},
            { title: 'owner',dataIndex: 'owner',type: "text",visible:false},
            { title: 'deviceId',dataIndex: 'deviceId',type: "text",visible:false},
            { title: 'streetType',dataIndex: 'streetType',type: "text",visible:false},
            { title: 'connectionPort',dataIndex: 'connectionPort',type: "number",visible:false},
            { title: 'totalEnergyConsumed',dataIndex: 'totalEnergyConsumed',type: "float",visible:false},
            { title: 'operatingHours',dataIndex: 'operatingHours',type: "float",visible:false},
            { title: 'controllerInstallationDate',dataIndex: 'controllerInstallationDate',type: "date",visible:false},
            { title: 'driverInstallationDate',dataIndex: 'driverInstallationDate',type: "date",visible:false},
            { title: 'firmwareVersion',dataIndex: 'firmwareVersion',type: "text",visible:false},
            { title: 'calendarName',dataIndex: 'calendarName',type: "text",visible:false},
            
            
            { title: 'zip',dataIndex: 'zip',type: "text",visible:false},
            { title: 'zone',dataIndex: 'zone',type: "text",visible:false},
            { title: 'district',dataIndex: 'district',type: "text",visible:false},
            { title: 'province',dataIndex: 'province',type: "text",visible:false},
            { title: 'region',dataIndex: 'region',type: "text",visible:false},
            { title: 'community',dataIndex: 'community',type: "text",visible:false},
            { title: 'country',dataIndex: 'country',type: "text",visible:false},
            { title: 'streetAddress',dataIndex: 'streetAddress',type: "text",visible:false},
            { title: 'postalCode',dataIndex: 'postalCode',type: "text",visible:false},
            { title: 'addressLocality',dataIndex: 'addressLocality',type: "text",visible:false},
            { title: 'addressRegion',dataIndex: 'addressRegion',type: "text",visible:false},
            { title: 'addressCommunity',dataIndex: 'addressCommunity',type: "text",visible:false},
            { title: 'addressCountry',dataIndex: 'addressCountry',type: "text",visible:false}*/
    ];
  }
}
