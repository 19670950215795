import { Route } from "react-router-dom"
import { PrivateRoute } from "../../utils/PrivateRoute"
import Council from "../../../Pages/Council/Council"
import AuthService, { posibleRoles } from "../../../services/Auth/Auth.service"

export const UserGroupsRoutes = () => {
    return <Route path="user-groups">
        <Route
            path="council"
            element={
            <PrivateRoute>
            <Council
                CRUDPrivilages={{
                create: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                update: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                delete: AuthService.atLeastRole(posibleRoles.ADMIN_TECH),
                }}
            />
            </PrivateRoute>
            }
        />
    </Route>
}