import { ENV } from "../../utils";
import axios from "axios";
import AuthService from "../Auth/Auth.service";
import { IServiceCRUD } from "../interfaces.service";
import { CouncilColuns } from "./Columns";
import { ColumnConfig } from "../../components/DynamicTable/Interfaces/ColumnConfig";

export default class CouncilService implements IServiceCRUD {
  token: string;
  url: string;
  uploadEndpoint: string;
  headers: object;
  columns: ColumnConfig[];

  constructor() {
    this.token = AuthService.getToken();
    this.url = `${ENV.Auth_API}/council`;
    this.uploadEndpoint = `${this.url}/upload`;
    this.headers = { "x-access-token": this.token,"Content-Type": "application/json",};
    this.columns = CouncilColuns;
  }

  getColumns(): any {
    return this.columns;
  }

  async getAll(filters: any, currentPage: number, pageSize: number): Promise<any> {
    const offset = (currentPage - 1) * pageSize;
    let rawData = await axios.get(
      `${this.url}` + `?filter=${JSON.stringify(filters)}` + `&limit=${pageSize}&offset=${offset}`,
      { headers: this.headers }
    );
    rawData.data.db.result = rawData.data.db.result.map((council:any) => ({...council,key: council.id }));
    return {
      data: rawData.data.db.result,
      totalCount: rawData.data.db.totalCount,
    };
  }

  async create(body: any): Promise<any> {
    return await axios.post(this.url, body, { headers: this.headers });
  }

  async getById(id: string | number | null): Promise<any> {
    return await axios
      .get(`${this.url}/${id}`, { headers: this.headers })
      .then((response) => response.data)
      .catch((error) => console.log("Error:", error));
  }

  async update(id: string | number, body: any): Promise<any> {
    return await axios.put(`${this.url}/${id}`, body, { headers: this.headers });
  }

  async delete(id: string | string[] | number | number[]): Promise<any> {
    const IdsWithCommas = Array.isArray(id) ? id.join(",") : id;
    return await axios.delete(`${this.url}/${IdsWithCommas}`, {
      headers: this.headers,
    });
  }
  
  async getEnums() { }
}
