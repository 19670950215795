import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Hooks";
import { AuthContextType } from "../../context";

interface PrivateRouteProps {
  children: ReactNode;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated,setIsAuthenticated,accessToken, user,login,logout,getUser }: AuthContextType = useAuth(); 
  if (!isAuthenticated || new Date(user.exp * 1000) < new Date()) {
    return <Navigate to="/sign-in" replace />;
  }

  return <>{children}</>;
};
