import { ENV } from "../../utils";
import axios from "axios";
import AuthService from "../Auth/Auth.service";
import { IColumnForCRUD, IServiceCRUD } from "../interfaces.service";
import { columns } from "./Columns";

  export default class OrganizationService implements IServiceCRUD {
    private cache: any[] = [];  
    url: string;
    token: string;
    mac:string|undefined;
    headers: { "x-access-token": string; "Content-Type": string; };
    constructor(mac:string|undefined= undefined) {
      this.token = AuthService.getToken();
      this.mac = mac;
      this.url = `${ENV.Infraestructure_API}/${mac}/organizations`;
      this.headers = { "x-access-token": this.token, "Content-Type": "application/json" };
    }
    getColumns() : IColumnForCRUD[] {
      return columns
  }
async getAll( filters: any = {}, currentPage: number = 1, pageSize: number = 0): Promise<any> {
  const offset = (currentPage - 1) * pageSize;
  const rawData = await axios.get(
    `${this.url}?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`,
    { headers: this.headers }
  );

  this.cache = rawData.data.db.result;

  return {
    data: rawData.data.db.result,
    totalCount: rawData.data.db.totalCount,
  };
}
async create(body: any): Promise<any> {
  return await axios.post(this.url, body, { headers: this.headers });
}
async getById(id: string | number | null): Promise<any> {
  const cachedItem = this.cache.find(item => item.id === id);
    console.log(cachedItem,'cachedItemcachedItemcachedItem');
  if (cachedItem) {
    console.log("Data retrieved from cache");
    return {
      name: cachedItem.name,
      canHaveGateways: cachedItem.canHaveGateways || true  ,
      displayName: cachedItem.displayName,
      maxDeviceCount: cachedItem.maxDeviceCount,
      maxGatewayCount: cachedItem.maxGatewayCount,
    };
  }
}
async update(id: string | number, body: any): Promise<any> {
  return await axios.put(`${this.url}/${id}`, body, { headers: this.headers });
}

async delete(id: string | string[] | number | number[]): Promise<any> {
  const IdsWithCommas = Array.isArray(id) ? id.join(",") : id;
  return await axios.delete(`${this.url}/${IdsWithCommas}`, {
    headers: this.headers,
  });
}
async getEnums() {}
}