import { theme } from "antd";
import { ApartmentOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

const { useToken } = theme;

const RedirectButton = ({ route, element, alt }) => {
  const { token } = useToken();
  const navigate = useNavigate();

  const RedirectFunction = () => {
    navigate(route);
  };
  return (
    <>
      <ApartmentOutlined
        alt={alt}
        style={{ fontSize: "large", color: token.colorPrimary }}
        onClick={RedirectFunction}
      />
    </>
  );
};

export default RedirectButton;
