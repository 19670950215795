export const en_US = {
  "Email address": "Email address",
  Password: "Password",
  "Rememeber me": "Remember me",
  Sumbit: "Submit",
  Home: "Home",
  LoraWAN: "LoraWAN",
  "Network Servers": "Network Servers",
  "Network Server": "Network Server",
  "LoraWAN Sensor": "LoraWAN Sensor",
  Message: "Message",
  Decoder: "Decoder",
  "Decoder Server": "Decoder Server",
  Server: "Server",
  Gateway: "Gateway",
  Group: "Group",
  Sensor: "Sensor",
  Sensors: "Sensors",
  Types: "Types",
  Project: "Project",
  Projects: "Projects",
  Business: "Business",
  Council: "Council",
  User: "User",
  Users: "Users",
  Logger: "Logger",
  Modules: "Modules",
  Door: "Door",
  Doors: "Doors",
  Calendar: "Calendar",
  Request: "Request",
  Light: "Light",
  "Energy Groups": "Energy Groups",
  "Energy Devices": "Energy Devices",
  Alarm: "Alarm",
  Status: "Status",
  Planning: "Planning",
  Actuator: "Actuator",
  History: "History",
  Control: "Control",
  Water: "Water",
  Devices: "Devices",
  "Hydric balance": "Hydric balance",
  Observations: "Observations",
  Map: "Map",
  Generic: "Generic",
  Alarms: "Alarms",
  "Alarm Notifications": "Alarm Notifications",
  Capacity: "Capacity",
  Panel: "Panel",
  Parkings: "Parkings",
  Dashboard: "Dashboard",
  Irrigation: "Irrigation",
  Boiler: "Boiler",
  "MQTT Visualizer": "MQTT Visualizer",
  "Device Profiles": "Device Profiles",
  Gateways: "Gateways",
  "Service Profiles": "Service Profiles",
  Applications: "Applications",
  "Base64 Message": "Base64 Message:",
  General: "General",

  "Are you sure you want to remove this element?":
    "Are you sure you want to remove this element?",

  // DASHBOARD
  Information: "Information",
  MyProfile: "My Profile",
};
