import React from 'react';
import { Input } from 'antd';
import { ColumnTypeProps } from '../ColumnTypeProps';


const TextColumnType: React.FC<ColumnTypeProps> = ({ column, entity, setEntity }) => {
  //column.defaultValue ? setEntity({...entity,[column.dataIndex]: column.defaultValue }) : null
  return (
    <Input
      style={{ width: "200px" }}
      value={entity[column.dataIndex] || ( column.defaultValue && setEntity({...entity,[column.dataIndex]:column.defaultValue  })) || ""}
      disabled={column.disabled}
      onChange={(e) => {
        setEntity({
          ...entity,
          [column.dataIndex]: e.target.value,
        });
      }}
    />
  );
};

export default TextColumnType;
