import Application from "../../Application/Application"
import {CRUDPrivilages} from "../../../components/DynamicTable/Interfaces/CRUDPrivilages"
import Sensor from "../../Sensors/Sensors";

interface ApplicationTabProps {
    privilages: CRUDPrivilages;
  }
  
  export const ApplicationTab: React.FC<ApplicationTabProps> = ({ privilages }) => {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ width: "50%", padding: "0px 20px 0px 0px" }}>
                <Application CRUDPrivilages={privilages} />
            </div>
            <div style={{ width: "50%", padding: "0px 20px 0px 0px" }}>
                <Sensor CRUDPrivilages={privilages} />
            </div>
          </div>
    )
}