import { createContext, useState } from "react";

export const ThemeContext = createContext("dark");
export const ThemeUpdateContext = createContext(() => null);

export function ThemeProvider({ children }) {
  const [currTheme, setTheme] = useState("dark");

  function switchTheme() {
    if (currTheme === "dark") setTheme("light");
    else setTheme("dark");
  }

  return (
    <ThemeContext.Provider value={currTheme}>
      <ThemeUpdateContext.Provider value={switchTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
}
