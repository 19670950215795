import { Button, Form, Popconfirm } from "antd";
import { useAuth } from "../../Hooks";
import { useTranslation } from "react-i18next";

function LoginSubmitButton({ userData }) {
  const { login } = useAuth();
  const { t } = useTranslation();

  /**
   * Directly login with the new user
   */
  function confirmLogin() {
    login({ username: userData.email, pswd: userData.pswd });
  }

  return (
    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Popconfirm
        title="Login into user"
        description="Do you want to login with this user?"
        onConfirm={confirmLogin}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" htmlType="submit">
          {t("Submit")}
        </Button>
      </Popconfirm>
    </Form.Item>
  );
}

export default LoginSubmitButton;
