import { Modal, message, Upload } from "antd";
import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";

export function ModalUploadCSV({
  modalOpen,
  setModalOpen,
  csvOptions,
  fileExtension = ".csv",
}) {
  let [serverFileName, setServerFileName] = useState("");
  let [aditionalData, setAditionalData] = useState("");
  let [isOkButtonDisabled, setIsOkButtonDisabled] = useState(true);

  const { Dragger } = Upload;

  const {
    service,
    callback = () => {},
    description = "",
    children = null,
    options = null,
  } = csvOptions;
  const delimiter = ",";

  const handleChange = (value, name) => {
    console.log(value, name);
    setAditionalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onOk = async () => {
    await service.processCsv(delimiter, serverFileName, aditionalData);
    setModalOpen(false);
    callback();
  };

  const propsUpload = {
    name: "csv_file",
    action: service.uploadEndpoint,
    multiple: false,
    showUploadList: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const correctExtension = file.name.endsWith(fileExtension);
      if (!correctExtension)
        message.error(
          `You can only upload ${fileExtension.split(".")[1].toLowerCase()} file!`,
        );
      return correctExtension ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status !== "uploading") {
        // Puedes agregar algún código adicional aquí si es necesario
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setServerFileName(info.file.response.filename);
        setIsOkButtonDisabled(false);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      //onCancel={() => console.log(aditionalData)}
      title={`Upload ${fileExtension.split(".")[1].toLowerCase()}`}
      onOk={onOk}
      okButtonProps={{ disabled: isOkButtonDisabled }}
    >
      <p>
        The {fileExtension.split(".")[1].toLowerCase()} file must have the
        following columns in any order:{" "}
      </p>
      {description}
      <p></p>
      <Dragger {...propsUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag a {fileExtension.split(".")[1].toLowerCase()} file to
          this area to upload
        </p>
      </Dragger>
      {
        // e.target.value e.target.name
        children(handleChange, options)
      }
    </Modal>
  );
}
