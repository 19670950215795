import { IColumnForCRUD } from "../interfaces.service";
import OrganizationService from "../Organization/Organization.service";
import ServiceProfileService from "../ServiceProfile/ServiceProfile.service";

export const Columns =  (mac: string): IColumnForCRUD[] => {
    return [
        { dataIndex: 'name', title: 'Name', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: 'description', title: 'Description', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: 'organizationID', title: 'Organization ID', filter: 'text', type: 'service', service: new OrganizationService(mac), serviceLabel: "name", serviceValue: "id", visible: true, editable: true },
        { dataIndex: 'serviceProfileID', title: 'Service Profile', filter: 'text', type: 'service', service: new ServiceProfileService(mac), serviceLabel: "name", serviceValue: "id", editable: true }
    ];
}
