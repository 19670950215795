import React from "react";

import { Button, Popover, theme } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import exportFromJSON from "export-from-json";

const { useToken } = theme;
/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const XmlButton = ({ inputAttributes, element }) => {
  const { token } = useToken();
  const { t } = useTranslation();

  const downloadCsv = (type) => {
    // BUG: It seems if you add the element directly is not a valid json data
    // If you write it by hand each one it works correctly
    const data = [
      {
        id: element.id,
        name: element.name,
        description: element.description,
        taxnumber: element.taxnumber,
        phone: element.phone,
        email: element.email,
        createddate: element.createddate,
      },
    ];
    const fileName = element.name;
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  const downloadXml = (type) => {
    // BUG: It seems if you add the element directly is not a valid json data
    // If you write it by hand each one it works correctly
    const data = [
      {
        id: element.id,
        name: element.name,
        description: element.description,
        taxnumber: element.taxnumber,
        phone: element.phone,
        email: element.email,
        createddate: element.createddate,
      },
    ];
    const fileName = element.name;
    const exportType = exportFromJSON.types.xml;

    exportFromJSON({ data, fileName, exportType });
  };

  const content = (
    <div>
      <Button onClick={downloadCsv}>CSV</Button>
      <Button onClick={downloadXml}>XML</Button>
    </div>
  );

  return (
    <Popover content={content} title={t("Download data")}>
      <DownloadOutlined
        style={{ fontSize: "large", color: token.colorPrimary }}
      />
    </Popover>
  );
};

export default XmlButton;
