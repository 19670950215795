import { theme } from "antd";
import { Tabs } from "antd";
import Alarms from "./Alarms/Alarms";
import Notifications from "./Notifications/Notifications";
import { useState } from "react";
import "./index.css";
import { HardwareInAlarm } from "../../services/Alarms/Alarms.service";
import GatewayService from "../../services/Gateway/Gateway.service";
import DeviceService from "../../services/Device/Device.service";
const { useToken } = theme;
type params = {
  alarmsHardware: HardwareInAlarm[];
  alarmServices: any;
};
export default function AlarmSystem({
  alarmsHardware = ["gateways", "devices"],
  alarmServices = [ new GatewayService(), new DeviceService()],
}: params) {
  const { token } = useToken();
  let [tabValue, setTabValue] = useState<string>("alarms");
  let changeTab = (item: any) => {
    setTabValue(item);
  };
  let tabItems: any[] = [
    {
      key: "alarms",
      label: "Alarm",
      children: <Alarms hardwares={alarmsHardware} hardwareServices={alarmServices} />,
    },
    {
      key: "notifications",
      label: "Notifications",
      children: <Notifications hardwares={alarmsHardware} />,
    },
  ];
  return (
    <>
      <h1 style={{ color: token.colorText }}> Alarms System</h1>
      <Tabs
        style={{
          background: "transparent !important",
          justifyContent: "space-between",
          color: `${token.colorText} !important`,
        }}
        items={tabItems}
        activeKey={tabValue}
        onChange={changeTab}
      />
    </>
  );
}
