import { Route } from "react-router-dom";
import { PrivateRoute } from "../../utils/PrivateRoute";
import MqttServer from "../../../Pages/MqttServer/MqttServerPage";
import AuthService, { posibleRoles } from "../../../services/Auth/Auth.service";

export function MqttRoutes() {
    return (
        <Route path="mqtt-server" element={<PrivateRoute>
            <MqttServer
                CRUDPrivilages={{
                    create: AuthService.atLeastRole(
                        posibleRoles.ADMIN_TECH
                    ),
                    update: AuthService.atLeastRole(
                        posibleRoles.ADMIN_TECH
                    ),
                    delete: AuthService.atLeastRole(
                        posibleRoles.ADMIN_TECH
                    ),
                }}
            />

        </PrivateRoute>
        }
        />
    )
}