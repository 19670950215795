import { Button, Empty, Input, Table } from "antd";
import { useState, useEffect } from "react";
import "./MqttClient.css";
import { useParams } from "react-router-dom";
import AuthService, {
  posibleRoles,
} from "../../services/Auth/Auth.service.tsx";
function SubscribeSection({ socket, handleSubscribe, isConnected, element }) {
  const [subscribeTopic, setSubscribeTopic] = useState("");
  const [messages, setMessages] = useState([]);

  const params = useParams();
  useEffect(() => {
    if (element == "sensors") {
      setSubscribeTopic(
        `${params.physicalGatewayId}/application/${params.applicationId}/device/${params.devEUI}/#`
      );
    } else if (element == "organization") {
      setSubscribeTopic(
        `${params.physicalGatewayId}/gateway_requests/response`
      );
    }
  }, [params.physicalGatewayId]);

  const table_columns = AuthService.atLeastRole(posibleRoles.ADMIN_COUNCIL)
    ? [
        { dataIndex: "topic", title: "Topic" },
        { dataIndex: "message", title: "Message" },
      ]
    : [{ dataIndex: "message", title: "Message" }];

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "message") {
          setMessages((prevMessages) => [...prevMessages, data]);
        }
      };
    }
  }, [socket]);

  return (
    <div
      className={
        AuthService.atLeastRole(posibleRoles.ADMIN_COUNCIL) ? "col-md-6 " : ""
      }
      style={{ flex: "2" }}
    >
      <div className="topic-subscribe-section">
        <div className="d-flex justify-content-between align-items-center">
          {AuthService.atLeastRole(posibleRoles.ADMIN_COUNCIL) ? (
            <>
              <Input
                type="text"
                className="mb-2 me-2"
                placeholder={subscribeTopic}
                value={subscribeTopic}
                onChange={(e) => setSubscribeTopic(e.target.value)}
              />
              <Button
                className="mb-2"
                onClick={() => {
                  handleSubscribe({ topic: subscribeTopic });
                }}
                disabled={!isConnected}
              >
                subscribe
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          className={`messages-wrapper d-flex justify-content-${
            messages?.length > 0 ? "start" : "center align-items-center"
          }  flex-column`}
          style={{ height: "350px", overflowY: "auto" }}
        >
          {messages?.length > 0 ? (
            <>
              <div className="shadow rounded">
                <Table
                  className="table_scroll"
                  bordered={true}
                  columns={table_columns}
                  dataSource={messages}
                  sticky={true}
                  pagination={false}
                />
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
}

export default SubscribeSection;
