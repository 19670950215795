import React, { useState, ReactNode } from "react";
import "./TabNavigation.css";
import { theme } from "antd";
import { useTranslation } from "react-i18next";

const { useToken } = theme;

interface TabNavigationProps {
  selected?: number;
  children: ReactNode[];
}

interface TabPanelProps {
  title?: string; // Añadido para que funcione con la prop `title`
  children: ReactNode;
}

export const TabNavigation: React.FC<TabNavigationProps> = ({ selected: defaultSelected = 0, children }) => {
  const [selected, setSelected] = useState<number>(defaultSelected);
  const { token } = useToken();
  const { t } = useTranslation();

  const handleChange = (index: number) => {
    setSelected(index);
  };

  return (
    <div className="main" style={{ background: token.colorBgElevated }}>
      <ul
        className="inline"
        style={{
          background: token.colorBgLayout,
          borderRadius: "15px 15px 0 0",
        }}
      >
        {React.Children.map(children, (elem, index) => {
          if (React.isValidElement(elem)) {
            const style = index === selected ? "selected" : "noSelected";
            const color = index === selected
              ? { color: token.colorPrimary }
              : { color: token.colorText };
            return (
              <li
                className={style}
                key={index}
                onClick={() => handleChange(index)}
                style={color}
              >
                {t(elem.props.title as string)}
              </li>
            );
          }
          return null;
        })}
      </ul>
      <div className="tab" style={{ color: token.colorText }}>
        {children[selected]}
      </div>
    </div>
  );
};

export const TabPanel: React.FC<TabPanelProps> = ({ children }) => {
  return <div>{children}</div>;
};
