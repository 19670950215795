import { ENV } from "../../utils/constants";
import AuthService from "../Auth/Auth.service";
import { IColumnForCRUD, IServiceCRUD } from "../interfaces.service";

export default class UvaxService implements IServiceCRUD {
  enums = null;
  url: string | null = null;
  controlCabinetId: number | undefined;
  token: string;
  constructor(controlCabinet?: number) {
    this.controlCabinetId = controlCabinet;
    this.url = `${ENV.Streetlight_API}/controlCabinet/${controlCabinet}/uvax`;
    this.token = AuthService.getToken();
  }
  async processCsv(
    delimiter: string = ",",
    serverFileName: string,
    aditionalData: any | null = null,
  ) {
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    const path = `${this.url}/process_csv`;
    const body = JSON.stringify({
      ...aditionalData,
      delimiter: delimiter,
      file_name: serverFileName,
    });

    return await fetch(path, { method: "POST", headers: headers, body: body })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  async getEnums() {
    if (this.enums) return this.enums;

    if (!this.controlCabinetId) return {};
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    const path = `${this.url}/enum`;

    this.enums = await fetch(path, { method: "GET", headers: headers })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
    return this.enums;
  }

  async getAll(filters: any, currentPage: number, pageSize: number ) {
    let tmp_filters = { ...filters };
    Object.keys(filters).map((item) => {
      if (!tmp_filters[item] || tmp_filters[item] === "")
        delete tmp_filters[item];
      return null;
    });
    const offset = (currentPage - 1) * pageSize;
    const path = `${this.url}?filters=${JSON.stringify(tmp_filters)}&limit=${pageSize}&offset=${offset}`;
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await fetch(path, { method: "GET", headers: headers })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async getById(id: string | number | null = null) {
    if (!this.controlCabinetId) return {};
    const path: string | null = this.url;
    if (!path) return {};
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await fetch(path, { method: "GET", headers: headers })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async create(body: any = {}) {
    if (!this.controlCabinetId) return {};

    const path: string | null = this.url;
    if (!path) return {};
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await fetch(path, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async update(id: string | number | null, body: any = {}) {
    if (!this.controlCabinetId) return {};

    const path: string | null = this.url;
    if (!path) return {};
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await fetch(path, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async delete(id: string | string[] | number | number[]) {
    // DO NOTHING
    return {};
  }
  getColumns(): IColumnForCRUD[] {
    return [
      { title: "ip", dataIndex: "ip", type: "text" },
      { title: "port", dataIndex: "port", type: "number" },
      { title: "user", dataIndex: "user", type: "text" },
      { title: "pass", dataIndex: "pass", type: "text" },
    ];
  }
}
