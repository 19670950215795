import { ENV } from "../../utils";
import axios from "axios";
import AuthService from "../Auth/Auth.service";
import { IServiceCRUD, uploadCSV } from "../interfaces.service";
import { UserColumns } from "./Columns";
import { ColumnConfig } from "../../components/DynamicTable/Interfaces/ColumnConfig";
export default class UserService implements IServiceCRUD,uploadCSV {
  token: string;
  url: string;
  uploadEndpoint: string;
  headers: object;
  columns: ColumnConfig[];

  constructor() {
    this.token = AuthService.getToken();
    this.url = `${ENV.Auth_API}/user`;
    this.uploadEndpoint = `${this.url}/upload`;
    this.headers = { "x-access-token": this.token,"Content-Type": "application/json",};
    this.columns = UserColumns;
  
  }

  async processCsv( delimiter: string = "", serverFileName: string, aditionalData: any = null) {
    const body = JSON.stringify({
      ...aditionalData,
      delimiter: delimiter,
      file_name: serverFileName,
    });
    console.log("PROCESS CSV", body);
    return await axios.post(`${this.url}/process_csv`, body, this.headers);
  }

  async getAllRoles() {
    const result = await axios.get(`${ENV.Auth_API}/user/getRoles`, this.headers);
    return result.data || [];
  }

  getColumns(): any { return UserColumns }

  async getAll( filters: any = {}, currentPage: number=0, pageSize: number=0 ): Promise<any> {
    const offset = (currentPage - 1) * pageSize;
    let rawData = await axios.get(
      `${this.url}` +
        `?filter=${JSON.stringify(filters)}` +
        `&limit=${pageSize}&offset=${offset}`,
      { headers: this.headers }
    );
    return {
      data: rawData.data.db.result,
      totalCount: rawData.data.db.totalCount,
    };
  }
  async create(body: any): Promise<any> {
    const headers = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await axios.post(this.url, body, { headers: headers });
  }
  // async getById() {}
  async getById(id: string | number | null): Promise<any> {
    const headers = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${this.url}/${id}`, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error:", error);
        return {};
      });
  }
  async update(id: string | number, body: any): Promise<any> {
    const headers = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await axios.put(`${this.url}/${id}`, body, { headers: headers });
  }

  async delete(id: string | string[] | number | number[]): Promise<any> {
    const headers = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    const IdsWithCommas = Array.isArray(id) ? id.join(",") : id;
    return await axios.delete(`${this.url}/${IdsWithCommas}`, {
      headers: headers,
    });
  }
  async getEnums() {}
}
