import { ENV } from "../../utils";
import axios from "axios";
import {
  IColumnForCRUD,
  getAllForAlarms,
  IServiceCRUD,
} from "../interfaces.service";
import AuthService from "../Auth/Auth.service";

export default class StaService implements getAllForAlarms, IServiceCRUD {
  url: string = ENV.Sta_API;
  token: string;
  constructor() {
    this.token = AuthService.getToken();
  }
  async allInMap() {
    try{
      const headers: any = {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      };
      return fetch(`${ENV.Sta_API}/sta/allInMap`,{headers: headers})
      .then(response => response.json())
      .then(data => {
        return data
      })
    }catch(error){
      console.log(error)
      return []
    }
  }
  async processCsv(delimiter: string, serverFileName: string): Promise<any> {
    try {
      const headers: any = {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      };
      const response = await fetch(`${ENV.Sta_API}/sta/process_csv`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          delimiter: delimiter,
          file_name: serverFileName,
        }),
      });
      return await response.json();
    } catch {
      console.log("Error");
      return { error: "Error" };
    }
    }
    async getEnums():Promise<any> {
        return {}
    }
    async getAll (filters: any, currentPage: number,pageSize: number ) {
        const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
        const offset = (currentPage-1) * pageSize;
        const extra = `searchBy=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`
        return await axios
            .get(`${ENV.Sta_API}/sta?${extra}`,{ headers: headers })
            .then((response) => response.data);

    }
  async getById(id: string | number | null) {}
  async updateSalt(data: any) {
    const body = { devEUI: data };
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await axios.post(`${this.url}/salt/state`, body, {
      headers: headers,
    });
  }
  async writeIntervalWindow(rowsSelected: any, intervalInSeconds: any) {
    const body = {
      dev_EUIs: rowsSelected,
      intervalInSeconds: intervalInSeconds,
    };
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    return await axios.post(`${this.url}/sta/interval`, body, {
      headers: headers,
    });
  }

  async getAllForAlarms(filters: any = {},  currentPage: number = 1,pageSize: number = 10 ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const response:any = await axios
      .get(`${ENV.Sta_API}/sta/getAllForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, { headers: headers })
      .then((response) => response.data);
    return response
  }

  async getEvents(dev_EUI: string) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    return await axios
      .get(`${this.url}/sta/events/${dev_EUI}`, { headers: headers })
      .then((response) => response.data);
  }
  async getSalt(dev_EUI: string) {
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${this.url}/salt?dev_EUI=${dev_EUI}`, { headers: headers })
      .then((response) => response.data);
  }
  async update(id: string | number, body: Object) {
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    const result = await fetch(`${this.url}/sta/${id}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    });
    return result;
  }
  async create(body: Object) {
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    const result = await fetch(`${this.url}/sta`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    return result;
  }
  async delete(id: string | string[] | number | number[]) {
    if (Array.isArray(id)) id = id.join(",");
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    const result = await fetch(`${this.url}/sta/${id}`, {
      method: "DELETE",
      headers: headers,
    });
    return result;
  }

  getColumns(): IColumnForCRUD[] {
    return [];
  }
}
