import { Col, Row } from "antd";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import DashboardCard from "./DashboardCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
);

//const mockData = { received: 80, lost: 20}

const Dashboard = ({ props, title }) => {
  const messages = props.messages;

  return (
    <div className={props.wrapper ? "wrapper" : ""}>
      <div>
        <Row>
          <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
            <DashboardCard type={"info"} data={props} />
          </Col>
          <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
            <DashboardCard
              type={"donut"}
              data={{
                labels: ["Received", "Lost"],
                datasets: [
                  {
                    label: "% of Messages",
                    data: [messages.received, messages.lost],
                    backgroundColor: ["#815AED", "#d4d4d4"],
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </Col>
          <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
            <DashboardCard
              type={"bar"}
              data={{
                labels: [">-105", ">-110", ">-115", ">-120"],
                datasets: [
                  {
                    label: "# of Messages",
                    data: messages.numbers,
                    backgroundColor: "#4766D6",
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </Col>
          <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
            <DashboardCard
              type={"donut"}
              data={{
                labels: ["Received", "Lost"],
                datasets: [
                  {
                    label: "% of Messages",
                    data: [messages.received, messages.lost],
                    backgroundColor: ["#815AED", "#d4d4d4"],
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </Col>
          <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
            <DashboardCard
              type={"bar"}
              data={{
                labels: [">-105", ">-110", ">-115", ">-120"],
                datasets: [
                  {
                    label: "# of Messages",
                    data: [23, 42, 43, 5],
                    backgroundColor: "#4766D6",
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
