export const dataToShow=[
    {"title": "Name","paramName":"name"},
    {"title": "Dev EUI","paramName":"dev_EUI"},
    {"title": "Description","paramName":"description"},

    {"title": "Tipo de conneción","paramName":"connection"},
    {"title": "Total mensajes(24hs)","paramName":"messageLast24hs"},
    {"title": "Total mensajes recibidos:","paramName":"messageRecibed"},

    {"title": "RSSI:","paramName":"RSSI"},
    {"title": "SNR:","paramName":"SNR"},
    {"title": "Ultimo conteo WIFI:","paramName":"WifiCount"},
    {"title": "Ultimo conteo BT:","paramName":"BTCount"},
    {"title": "Ultimo conteo BLE:","paramName":"BleCount"},
]
   