import { Col, Input, Modal, Row, Select, message } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import StaService from "../../../../../services/Sta/Sta.service";

export function ModalAddOrEdit({
  AddOrEdit = "Add",
  modalOpen,
  setModalOpen,
  originalData,
  callback,
}) {
  let [staToEdit, setStaToEdit] = useState({});
  let [staService] = useState(new StaService());

  useEffect(() => {
    setStaToEdit(originalData);
  }, [originalData]);

  async function sentNewData() {
    let result;
    let type_message;
    switch (AddOrEdit) {
      case "Add":
        type_message = "add";
        result = await staService.create(staToEdit);
        break;
      case "Edit":
        result = await staService.update(staToEdit.old_DEV_EUI, staToEdit);
        break;
      default:
        break;
    }
    if (result.status === 200) {
      message.success(`STA ${type_message}ed successfully`);
    } else {
      message.error(`Error when trying to ${type_message} STA`);
    }
    setModalOpen(false);
    callback();
  }
  return (
    <Modal
      open={modalOpen}
      onCancel={() => {
        setModalOpen(false);
      }}
      onOk={sentNewData}
    >
      <Title>{AddOrEdit} STA</Title>
      <Row>
        <Col span={8}>dev_EUI:</Col>
        <Col span={8} offset={8}>
          <Input
            style={{ width: "100%" }}
            value={staToEdit.dev_EUI}
            onChange={(item) => {
              setStaToEdit({ ...staToEdit, dev_EUI: item.target.value });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>Name:</Col>
        <Col span={8} offset={8}>
          <Input
            style={{ width: "100%" }}
            value={staToEdit.name}
            onChange={(item) => {
              setStaToEdit({ ...staToEdit, name: item.target.value });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>Description:</Col>
        <Col span={8} offset={8}>
          <Input
            style={{ width: "100%" }}
            value={staToEdit.description}
            onChange={(item) => {
              setStaToEdit({ ...staToEdit, description: item.target.value });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>Latitude:</Col>
        <Col span={8} offset={8}>
          <Input
            style={{ width: "100%" }}
            value={staToEdit.latitude}
            onChange={(item) => {
              setStaToEdit({ ...staToEdit, latitude: item.target.value });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>Longitude:</Col>
        <Col span={8} offset={8}>
          <Input
            style={{ width: "100%" }}
            value={staToEdit.longitude}
            onChange={(item) => {
              setStaToEdit({ ...staToEdit, longitude: item.target.value });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>Connection:</Col>
        <Col span={8} offset={8}>
          <Select
            style={{ width: "100%" }}
            options={[
              { value: "lora", label: "lora" },
              { value: "4G", label: "4G" },
            ]}
            value={{ value: staToEdit.connection, label: staToEdit.connection }}
            onChange={(item) => {
              setStaToEdit({ ...staToEdit, connection: item });
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
}
