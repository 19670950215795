import { Tabs } from "antd";
import Alarms from "../AlarmSystem/Alarms/Alarms";
import Notification from "../AlarmSystem/Notifications/Notifications";
import { useState, useEffect } from "react";
import { Map } from "../../components/Map/map.component";

import StaTab from "./StaTab/StaTab";
import StaService from "../../services/Sta/Sta.service";

function Sta() {
  let [tabValue, setTabValue] = useState("sta");
  let [dataMap, setDataMap] = useState([]);
  const [serviceSta] = useState(new StaService());
  
  useEffect(() => {
    serviceSta.allInMap().then((data) => setDataMap(data.result));
  }, []);

  const items = [
    { key: "map", label: "MAP", children: <Map data={dataMap} /> },
    { key: "sta", label: "STA", children: <StaTab /> },
    { key: "alarms",label: "Alarms",children: <Alarms optionTypes={["connection","aforo"]} hardwares={["stas"]} hardwareServices={[new StaService()]}/>},
    { key: "notificactions", label: "Notifications", children: <Notification hardwares={["stas"]} /> },
  ];
  return <Tabs activeKey={tabValue} items={items} onChange={setTabValue} />;
}
export default Sta;
