import { ENV } from "../../utils";
import GatewayService from "../Gateway/Gateway.service";
import DeviceProfileService from "../DeviceProfile/DeviceProfile.service";
import StaService from "../Sta/Sta.service";
import StreetlightService from "../Streetlight/Streetlight.service";
import ControlCabinetService from "../ControlCabinet/ControlCabinet.service";
import AuthService from "../Auth/Auth.service";
import DeviceService from "../Device/Device.service";

export type HardwareInAlarm =
  | "stas"
  | "gateways"
  | "devices"
  | "controlCabinets"
  | "streetlights";
export default class AlarmService {
  hardware: HardwareInAlarm[];
  token: string = "";
  url: string = "";
  services: any[] = [];
  constructor(hardware: HardwareInAlarm[]) {
    this.hardware = hardware;
    this.token = AuthService.getToken();
    this.url = `${ENV.Alarms_API}/alarms`;
  }

  async getAlarms(filters: any ={}, currentPage: number = 0,pageSize: number = 10 ): Promise<any> {
    const offset = (currentPage - 1) * pageSize;
    return fetch(`${this.url}?type=${this.hardware.join(",")}&limit=${pageSize}&offset=${offset}`, {
      method: "GET",
      headers: { "x-access-token": this.token },
    })
      .then((response) => {
        if (!response.ok) return { result: [], totalCount: 0 };
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
        return { result: [], totalCount: 0 };
      });
  }

  async delete(entityId: string | number) {
    await fetch(`${this.url}/${entityId}`, {
      method: "DELETE",
      headers: { "x-access-token": this.token },
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Error al realizar la solicitud: ${response.status}`);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async update(id: number | string, data: any) {
    await fetch(`${this.url}/${id}`, {
      method: "PUT",
      headers: {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  async create(data: any) {
    await fetch(`${this.url}`, {
      method: "POST",
      headers: {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  getHardwareService(hardware: HardwareInAlarm): any {
    if (hardware === "stas") return new StaService();
    if (hardware === "gateways") return new GatewayService();
    if (hardware === "devices") return new DeviceService();
    if (hardware === "controlCabinets") return new ControlCabinetService();
    if (hardware === "streetlights") return new StreetlightService(0);

    return new StaService();
  }

  async getHardwareIds(hardware: HardwareInAlarm) {
    const service = this.getHardwareService(hardware);
    return await service.getAllIds();
  }
}
