import React, { useState } from "react";

import { theme, Tooltip, Button, Space } from "antd";
import {
  MessageOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import Typography from "antd/es/typography/Typography";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { useToken } = theme;
/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const MqttLogButton = ({ element }) => {
  const { token } = useToken();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function handleCancel() {
    setIsModalOpen(false);
  }

  // -------------------------------

  // TODO: info and delete actions
  return (
    <>
      <Tooltip title={t("Message info")}>
        <MessageOutlined
          style={{ fontSize: "large", color: token.colorPrimary }}
          onClick={openModal}
        />
        <Modal
          title={t("Message")}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Paragraph>Gateway: {element.gateway}</Paragraph>
          <Paragraph>DeviceEUI: {element.deviceeui}</Paragraph>
          <Paragraph>DR: {element.dr}</Paragraph>
          <Paragraph>RSSI: {element.rssi}</Paragraph>
          <Paragraph>Total Message: {element.totalmessage}</Paragraph>
          <Space>
            <Button
              style={{ display: "inline-flex", "align-items": "center" }}
              type="primary"
              icon={<InfoCircleOutlined />}
            >
              {" "}
              Info
            </Button>
            <Button
              style={{ display: "inline-flex", "align-items": "center" }}
              type="primary"
              danger
              icon={<WarningOutlined />}
            >
              {" "}
              Borrar
            </Button>
          </Space>
        </Modal>
      </Tooltip>
    </>
  );
};

export default MqttLogButton;
