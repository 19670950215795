import { ENV } from "../../utils";
import axios from "axios";
import AuthService from "../Auth/Auth.service";
import { IServiceCRUD, IColumnForCRUD } from "../interfaces.service";
import { columns } from "./Columns";

export default class MqttServerService implements IServiceCRUD {
    private cache: any[] = [];
    token: string
    url: string
    headers: object | undefined
    
    constructor() {
        this.token = AuthService.getToken()
        this.url = `${ENV.Infraestructure_API}/${ENV.API_ROUTES.MQTTSERVER}`
        this.headers = { 'x-access-token': this.token, 'Content-Type': 'application/json' }
    }

    async create(body: any): Promise<any> {
        return await axios.post(this.url, body, { headers: this.headers });
    }

    async getById(id: string | number | null): Promise<any> {
        const cachedItem = this.cache.find(item => item.id === id);
        if (cachedItem) {
            return cachedItem;
        }
    }

    async getAll(filters: any = {}, currentPage: number = 1, pageSize: number = 0): Promise<any> {
        const offset = (currentPage - 1) * pageSize;
        let rawData = await axios.get(`${this.url}` + `?filters=${JSON.stringify(filters)}` + `&limit=${pageSize}&offset=${offset}`,
            { headers: this.headers }
        );
        this.cache = rawData.data.db.result;
        return { data: rawData.data.db.result, totalCount: rawData.data.db.totalCount }
    }

    async update(id: string | number, body: any): Promise<any> {
        return await axios.put(`${this.url}/${id}`, body, { headers: this.headers });
    }

    async getEnums(): Promise<any> {
        const headers = { 'x-access-token': this.token, 'Content-Type': 'application/json' }
        return await axios.get(`${this.url}/enum`, { headers: headers });
    }

    async delete(id: string | string[] | number | number[]): Promise<any> {
        const IdsWithCommas = Array.isArray(id) ? id.join(",") : id
        return await axios.delete(`${this.url}/${IdsWithCommas}`, { headers: this.headers });
    }

    getColumns(): IColumnForCRUD[] {
        return columns
    }

}
