import React from 'react';
import { Select } from 'antd';
import { ColumnTypeProps } from '../ColumnTypeProps';

const BoolColumnType: React.FC<ColumnTypeProps> = ({ column, entity, setEntity }) => {
  return (
    <Select
      style={{ width: 200 }}
      value={entity[column.dataIndex] ?? column.defaultValue ?? true}
      onChange={(value) => {
        setEntity({
          ...entity,
          [column.dataIndex]: value,
        });
      }}
      options={[
        { label: "True", value: true },
        { label: "False", value: false },
      ]}
    />
  );
};

export default BoolColumnType;
