import { Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { enableInputGroupAtom } from "../../components/UpdateItemForm/CommonForm.jsx";

function BooleanInputFalseDefault({
  dataName,
  title,
  required,
  disabledTargetValue,
  disabledVarName,
}) {
  const { t } = useTranslation();
  const [enableInputGroup /*setEnableInputGroup*/] =
    useAtom(enableInputGroupAtom);
  // to-do: review default value
  const [checked /*setChecked*/] = useState(false);

  useEffect(() => {}, []);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[{ type: "boolean", required: required }]}
      valuePropName="checked"
      initialValue={checked}
      style={{ display: "none" }} // Oculta el Form.Item
    >
      <Switch
        checked={checked}
        disabled={disabledTargetValue === enableInputGroup[disabledVarName]}
      />
    </Form.Item>
  );
}

export default BooleanInputFalseDefault;
