import { useState } from "react";

import { BarsOutlined } from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const LogsButton = ({ route, form, attributes, element }) => {
  const { t } = useTranslation();

  // Edit an element modal view
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  function openEditModal() {
    setIsEditModalOpen(true);
  }

  function handleEditCancel() {
    setIsEditModalOpen(false);
  }
  // -------------------------------

  return (
    <>
      <BarsOutlined
        style={{ fontSize: "large", color: "#FFFFFF" }}
        onClick={openEditModal}
      />
      <Modal
        footer={null}
        title={t("Logs")}
        open={isEditModalOpen}
        onCancel={handleEditCancel}
      >
        <div>Data de logs</div>
        {/*
                <CommonForm route={route} element={element} attributes={attributes} />
                */}
      </Modal>
    </>
  );
};

export default LogsButton;
