import React from "react";
import App from "../App";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, ScrollRestoration, useParams } from "react-router-dom";
// STYLES
import "../styles/index.css";
// COMPONENTS
import Home from "../Pages/Home/home.component";
import CommonTable from "../components/CommonTable";
import { PrivateRoute } from "./utils/PrivateRoute";
import LoginSubmitButton from "../components/UpdateItemForm/LoginSubmitButton.component";
import ImportWaterDeviceButton from "../components/ActionButtons/ImportWaterDeviceButton";
import UpdateWaterDeviceButton from "../components/ActionButtons/UpdateWaterDeviceButton";
import VariableColumnAttributesModal from "../components/CommonTable/VariableColumnAttributesModal";
import CommonForm from "../components/UpdateItemForm/CommonForm";

// UTILS
import {  userTable, waterDevicesTable } from "../utils/tables";
import { waterGeneralMunicipalTable } from "../utils/tables";
import { withParams } from "./utils/WithParams";
import { ENV } from "../utils";

import { TableDataProvider } from "../context/TableData/TableDataContext";

// PAGES
import AlarmSystem from "../../src/Pages/AlarmSystem/AlarmSystem";
import Sta from "../Pages/Sta/Sta";
import Streetlight from "../Pages/Streetlight/Streetlight/Streetlight";
import ControlCabinet from "../Pages/Streetlight/ControlCabinet/ControlCabinet";
import GatewaysAndSensorsPage from "../Pages/GatewaysAndSensorsPage/GatewaysAndSensorsPage";

// ROUTES
import { UserRoutes, UserGroupsRoutes, AuthRoutes, LoraWanRoutes, DecoderRoutes, GatewayRoutes, MqttRoutes, SensorRoutes } from "./Routes";
import MyProfile from "../Pages/MyProfile/myProfile.component";

function AppRouter(): JSX.Element {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {AuthRoutes()}
        <Route path="/" element={<PrivateRoute><App /><ScrollRestoration /></PrivateRoute>} >
          <Route path="home" element={<Home />}>
            <Route path="myuser" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
            <Route index element={<GatewaysAndSensorsPage />} />
            {SensorRoutes()}
            {UserRoutes()}
            {LoraWanRoutes()}
            {DecoderRoutes()}
            {GatewayRoutes()}
            {MqttRoutes()}
            {UserRoutes()}
            {UserGroupsRoutes()}
          
            <Route path="light">
              <Route
                path="groups"
                element={
                  <PrivateRoute>
                    <TableDataProvider>
                      <CommonTable
                        allAttributes={userTable.attributes}
                        route={`${ENV.Modules_API}/${ENV.API_ROUTES.LIGHTGROUPS}`}
                        form={CommonForm}
                        dataName={ENV.API_ROUTES.LIGHTGROUPS}
                        title={"Groups"}
                        rowsPerPage={15}
                        additionalButtons={
                          <VariableColumnAttributesModal
                            allAttributes={userTable.attributes}
                          />
                        }
                        wrapper={true}
                      />
                    </TableDataProvider>
                  </PrivateRoute>
                }
              />
              <Route path="devices" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="requests" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="status" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="planning" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="actuator" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="history" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="controlcabinet" element={<PrivateRoute children={undefined}></PrivateRoute>} />
            </Route>
            <Route path="water">
              <Route path="general">
                <Route
                  path="municipal"
                  element={
                    <PrivateRoute>
                      <CommonTable
                        allAttributes={waterGeneralMunicipalTable.attributes}
                        form={CommonForm}
                        route={`${ENV.Auth_API}/${ENV.API_ROUTES.USER}`}
                        submitButton={LoginSubmitButton}
                        dataName={"user"}
                        title={"Municipality"}
                        rowsPerPage={15}
                        additionalButtons={
                          <>
                            <VariableColumnAttributesModal
                              allAttributes={waterGeneralMunicipalTable.attributes}
                            />
                          </>
                        }
                        wrapper={true}
                      />
                    </PrivateRoute>
                  }
                />
                <Route path="diameter" element={<PrivateRoute children={undefined}></PrivateRoute>} />
                <Route path="servicefee" element={<PrivateRoute children={undefined}></PrivateRoute>} />
                <Route path="consumptionblock" element={<PrivateRoute children={undefined}></PrivateRoute>} />
                <Route path="sewerrate" element={<PrivateRoute children={undefined}></PrivateRoute>} />
                <Route path="canon" element={<PrivateRoute children={undefined}></PrivateRoute>} />
                <Route path="counter" element={<PrivateRoute children={undefined}></PrivateRoute>} />
                <Route path="investment" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              </Route>
              <Route path="alarms" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route
                path="devices"
                element={
                  <PrivateRoute>
                    <TableDataProvider>
                      <CommonTable
                        allAttributes={userTable.attributes}
                        form={CommonForm}
                        dataName={"user"}
                        rowsPerPage={15}
                        additionalButtons={
                          <>
                            <VariableColumnAttributesModal
                              allAttributes={waterDevicesTable.attributes}
                            />
                            <UpdateWaterDeviceButton />
                            <ImportWaterDeviceButton />
                          </>
                        }
                        wrapper={true}
                      />
                    </TableDataProvider>
                  </PrivateRoute>
                }
              />
              <Route path="groups" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="hydricbalance" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="observations" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="map" element={<PrivateRoute children={undefined}></PrivateRoute>} />
              <Route path="control" element={<PrivateRoute children={undefined}></PrivateRoute>} />
            </Route>
            <Route path="alarms" element={<PrivateRoute children={<AlarmSystem alarmsHardware={["gateways", "devices"]} alarmServices={undefined} />} />} />
            <Route path="STA" element={<PrivateRoute children={<Sta />} />} />
            <Route path="ControlCabinet/:ControlCabinetId/Streetlight" element={<PrivateRoute>{React.createElement(withParams(Streetlight))}</PrivateRoute>} />
            <Route path="ControlCabinet" element={<PrivateRoute children={<ControlCabinet />} />} />
          </Route>
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;
