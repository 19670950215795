import React, { useEffect, useState } from "react";
import { Flex, Select, Spin, Row } from "antd";
import { ENV } from "../../../../../utils/constants.js";

function SensorPlatform({ sensorData, setSensorData }) {
  const [serverList, setServerList] = useState([]);
  const [loadingServers, setLoadingServers] = useState(true);
  const boxStyle = { width: "100%", height: "100%", borderRadius: 6 };
  const handleChange = (value) => {
    setSensorData({ ...sensorData, server: value });
  };

  useEffect(() => {
    setLoadingServers(true);
    fetch(`${ENV.Infraestructure_API}/${sensorData.mac}/decoder/servers`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) =>
        data.map((item) => ({ value: item.pkId, label: item.serverName })),
      )
      .then((data) => setServerList(data))
      .then(() => setLoadingServers(false))
      .catch((error) => console.error(error));
  }, [sensorData.mac]);
  return (
    <Flex style={boxStyle} justify={"center"} align={"center"}>
      <Spin spinning={loadingServers} size="large" style={{ width: "100%" }}>
        <Row style={{ width: "100%" }}>
          <div>Select Server:</div>
          <Select
            mode="multiple"
            style={{ width: "300px" }}
            onChange={handleChange}
            value={sensorData.server}
            options={serverList}
          />
        </Row>
      </Spin>
    </Flex>
  );
}
export default SensorPlatform;
