import React, { Suspense, lazy, useState , useEffect} from "react";
import GatewaysAndSensorsService from "../../services/GatewayAndSensors/GatewayAndSensors.service";
import { Map } from "../../components/Map/map.component";
import { Button } from "antd";
import { stringToMilliseconds } from "../../utils/date";
import FiltersMap from "./FiltersMap/FiltersMap";

export default function GatewaysAndSensorsPage() {
  const [dataMap, setDataMap] = useState([]);
  const [filters, setFilters] = useState<any>({
    "lastSeen-diffInMill": stringToMilliseconds("24h"),
    "lastSeen-diff": "24h",
  });
  const [disableLastSeen, setDisableLastSeen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const GateAndSensService = new GatewaysAndSensorsService();
    GateAndSensService.getAll(filters)
      .then((data) => setDataMap(data.data))
      .catch((error) => {
        console.error(error);
        setDataMap([]);
      });
  }, [filters]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <h1>Gateways and Sensors</h1>
        <Button onClick={() => setIsModalVisible(true)}>Filters</Button>
      </div>
      <Suspense fallback={<div>Cargando...</div>}>
  {isModalVisible && (
      <FiltersMap
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        filters={filters}
        setFilters={setFilters}
        disableLastSeen={disableLastSeen}
        setDisableLastSeen={setDisableLastSeen}
      />
    )}
</Suspense>
      <Map data={dataMap} filters={filters} />
    </>
  );
}
