import React, { FC } from "react";
import { Input, Button, Flex, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { stringToMilliseconds } from "../../../utils/date";

const LastSeenFilterDropdown: FC<any> = ({setDisableLastSeen,setFilters,filters, item,disableLastSeen}) => (
  <Flex vertical>
    <Select
      style={{ width: "200px" }}
      onChange={(value: any[]) => {
        setDisableLastSeen((value.length === 1 && value[0] === "null"))
        setFilters({
          ...filters,
          [`${item.dataIndex}-onOff`]: value,
          [`${item.dataIndex}-diff`]: filters[`${item.dataIndex}-diff`] || "6h",
          [`${item.dataIndex}-diffInMill`]: stringToMilliseconds(filters[`${item.dataIndex}-diff`] || "6h")
        });
      }}
      mode="multiple"
      options={[{ label: "🟩 ON", value: "on" }, { label: "🟥 OFF", value: "off" }, { label: "⬜ Null", value: "null" }]}
      defaultValue={["on", "off", "null"]}
    />
    <Input
      placeholder="6h"
      disabled={disableLastSeen}
      onChange={(e) => {
        setFilters({
          ...filters,
          [`${item.dataIndex}-diff`]: e.target.value,
          [`${item.dataIndex}-diffInMill`]: stringToMilliseconds(e.target.value)
        });
      }}
      defaultValue={filters[`${item.dataIndex}-diff`] || item.lastSeenDiff || "3h"}
      style={{ width: "200px" }}
    />
  </Flex>
);

export default LastSeenFilterDropdown;