//general
import React from "react";
import { useState } from "react";

import { ENV } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { EditOutlined, IeOutlined, ApartmentOutlined } from "@ant-design/icons";
import { Flex, Space } from "antd";
//modals
import AddOrEditEntityModal from "../../../../components/AddOrEditEntityModal/AddOrEditEntityModal";

// utils
import DynamicTable from "../../../../components/DynamicTable/DynamicTable";
import ControlCabinetService from "../../../../services/ControlCabinet/ControlCabinet.service";
import uvaxService from "../../../../services/Uvax/Uvax.service";

export default function ControlCabinetTab() {
    const navigate = useNavigate();
    const [modalControlCabinet, setModalControlCabinet] = useState(false);
    const [modalUvax, setModalUvax] = useState(false);
    const [controlCabinetId, setControlCabinetId] = useState<number|undefined>(undefined);
    const [refreshTable, setRefreshTable] = useState<boolean>(true);
    const service = new ControlCabinetService()
    const tableGeneralActions={
      add:{},
      configColumns:{},
      deleteSelected:{}
    }
    const columnsWithActions = [
      ...service.getColumns(),
      {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 100,
        align: 'center',
        render: (text:any, record:any) => (
        <Flex justify="center" ><Space align="center">
          <EditOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              setControlCabinetId(record.id);
              setModalControlCabinet(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <div
            onClick={() => {
              setControlCabinetId(record.id);
              setModalUvax(true);
            }}
          >
            UVAX
          </div>
          <IeOutlined
            style={{ fontSize: "1.8em", color: "blue" }}
            onClick={async () => {
              const uvax = new uvaxService(record.id);
              const result = await uvax.getById();
              const url = "http://" + result.ip;
              window.open(url, "_blank")?.focus();
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <ApartmentOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              navigate(`${record.id}/Streetlight`);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
        </Space></Flex>
      ),
    },
  ];
  return (
    <>
      {/* ControlCabinet*/}
      <AddOrEditEntityModal
        title="Control Cabinet"
        type="edit"
        service={service}
        id={controlCabinetId}
        isModalOpen={modalControlCabinet}
        setIsModalOpen={setModalControlCabinet}
        paramsPerPage={10}
        callback={() => {
          setRefreshTable(!refreshTable);
        }}
      />

      {/* UVAX*/}
      <AddOrEditEntityModal
        title="UVAX"
        type="edit"
        service={new uvaxService(controlCabinetId)}
        isModalOpen={modalUvax}
        setIsModalOpen={setModalUvax}
      />

      <DynamicTable
        title="Control Cabinet"
        service={service}
        columns={columnsWithActions}
        generalActions={tableGeneralActions}
        refreshTable={refreshTable}
        multiSelect={true}
        setRefreshTable={setRefreshTable}
      />
    </>
  );
}
