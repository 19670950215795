
import markerBlue from "../../../assets/marker.png";
import markerGreen from "../../../assets/markerGreen.png";
import markerRed from "../../../assets/markerRed.png";
import markerGrey from "../../../assets/markerGrey.png";

export const BlueMarker = L.icon({
    iconUrl: markerBlue,
    iconRetinaUrl: markerBlue,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 35],
  })
export const GreenMarker = L.icon({
    iconUrl: markerGreen,
    iconRetinaUrl: markerGreen,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 35],
})
export const RedMarker = L.icon({
    iconUrl: markerRed,
    iconRetinaUrl: markerRed,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 35],
  })

export const GreyMarker = L.icon({
  iconUrl: markerGrey,
  iconRetinaUrl: markerGrey,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [35, 35],
})