const DOMAIN = process.env.REACT_APP_DOMAIN;
const A_PORT = process.env.REACT_APP_A_PORT;
const B_PORT = process.env.REACT_APP_B_PORT;
const C_PORT = process.env.REACT_APP_C_PORT;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const API_VERSION_B = process.env.REACT_APP_API_VERSION_B;
const PROTOCOL =
  process.env.REACT_APP_PROTOCOL.toLocaleLowerCase() === "https"
    ? "https"
    : "http";

export const ENV = {
  domain: `${PROTOCOL}://${DOMAIN}`,
  Auth_API: `${PROTOCOL}://${DOMAIN}${A_PORT}/api/${API_VERSION}`,
  Auth_API_LOCAL: `${PROTOCOL}://${DOMAIN}${A_PORT}/api/${API_VERSION}`,
  Infraestructure_API: `${PROTOCOL}://${DOMAIN}${B_PORT}/${API_VERSION_B}`,
  MqttServer_API: `${PROTOCOL}://${DOMAIN}${B_PORT}/${API_VERSION_B}`,
  MqttWebsocket_URL: `ws://${DOMAIN}${B_PORT}/websocket`,
  Modules_API: `${PROTOCOL}://${DOMAIN}${C_PORT}/api/${API_VERSION}`,
  Alarms_API: `${PROTOCOL}://${DOMAIN}${process.env.REACT_APP_ALARMS}/api`,
  Sta_API: `${PROTOCOL}://${DOMAIN}${process.env.REACT_APP_STA}/api`,
  Streetlight_API: `${PROTOCOL}://${DOMAIN}${process.env.REACT_APP_STREETLIGHT}/api`,
  API_ROUTES: {
    REGISTER: "signup",
    LOGIN: "auth/signin",
    REFRESH_TOKEN: "auth/refresh-token",
    MEASURE: "measure",
    USER: "user",
    CNO: "CNO",
    COUNCIL: "council",
    APPLICATIONS: "applications",
    GATEWAY: "gateways",
    KEYS: "keys",
    ORGANIZATIONS: "organizations",
    PHYSICAL_GATEWAY_EMBEDDED: "physical-gateway-embedded",
    PHYSICAL_GATEWAY_CENTRALIZED: "physical-gateway-centralized",
    DEVICE_PROFILE: "device-profiles",
    DEVICES: "devices",
    SERVICE_PROFILE: "service-profiles",
    BUSINESS: "business",
    NETWORKSERVER: "network-servers",
    MQTTCLIENT: "mqtt",
    MQTTSERVER: "mqtt-server",
    SENSOR: "sensor",
    DECODER: "decoder",
    SENSORUNIT: "sensor",
    SENSORTYPE: "sensorType",
    SERVER: "server",
    PROJECT: "project",
    DOOR: "door",
    DOORREQUEST: "doorRequest",
    // Modules
    LIGHTGROUPS: "lightGroups",
    LIGHTDEVICES: "lightDevices",
    LIGHTREQUESTS: "lightRequests",
    LIGHTALARM: "lightAlarm",
    LIGHTALARMNOTIFICATIONS: "lightAlarmNotifications",
    LIGHTSTATUS: "lightStatus",
    LIGHTPLANNING: "lightPlanning",
    LIGHTACTUATOR: "lightActuator",
    LIGHTHISTORY: "lightHistory",
  },
  DIPUTACION: process.env.REACT_APP_DIPUTACION,
  JWT: {
    ACCESS: "token",
    REFRESH: "refreshToken",
  },
};
