import ApplicationService from "../Application/Application.service";
import DeviceProfileService from "../DeviceProfile/DeviceProfile.service";
import { IColumnForCRUD } from "../interfaces.service";

export const Columns =  (mac: string): IColumnForCRUD[] => {
    return [
        { dataIndex: 'name', title: 'Name', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: 'devEUI', title: 'devEUI', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: 'description', title: 'description', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: "lastSeen", title: "status", filter: "text", type: 'text', visible: true, editable: false },
        { dataIndex: "type", title: "type", filter: "text", type: 'text', visible: true, editable: true },
        { dataIndex: "isDisabled", title: "isDisabled", filter: "boolean", type: 'boolean', visible: true, editable: true },
        { dataIndex: 'referenceAltitude', title: 'referenceAltitude', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: 'skipFCntCheck', title: 'skipFCntCheck', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: "applicationID", title: "app_ID", filter: "text", type: 'service', service: new ApplicationService(mac), visible: true, editable: true },
        { dataIndex: 'deviceProfileID', title: 'deviceProfileID', filter: 'text', type: 'service',service: new DeviceProfileService(mac), visible: true, editable: true },
        { dataIndex: 'tags', title: 'tags', filter: 'text', type: 'text', visible: true, editable: true, defaultValue: "{}", disabled:true },
        { dataIndex: 'variables', title: 'variables', filter: 'text', type: 'text', visible: true, editable: true, defaultValue:"not_available", disabled:true },
    ];
}