import React, { useState } from "react";
import { theme, Typography, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import TabServer from "./TabServer/TabServer";
import TabSensor from "./TabSensor/TabSensor";
function Decoder({ organizationId }) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [DecoderTabValue, SetDecoderTabValue] = useState("server");
  const mac = window.location.href.match(
    /(?<=(centralized|ph[iy]sical-gateways)\/)[a-f0-9-]*/g,
  )[0];
  return (
    <>
      <Title style={{ color: theme.colorText }}>{t("Decoder")}</Title>
      <Tabs
        defaultActiveKey={DecoderTabValue}
        onChange={(value) => SetDecoderTabValue(value)}
        items={[
          { label: "Server", key: "server" },
          { label: "Sensor", key: "sensor" },
        ]}
      />
      <div hidden={DecoderTabValue !== "server"}>
        <TabServer mac={mac} organizationId={organizationId} />
      </div>
      <div hidden={DecoderTabValue !== "sensor"}>
        <TabSensor mac={mac} organizationId={organizationId} />
      </div>
    </>
  );
}
export default Decoder;
