import { Marker } from 'react-leaflet'
import { GreenMarker, GreyMarker, RedMarker } from '../MarkersIcons/MarkersIcons';
import { v4 as uuidv4 } from 'uuid'

// type AllMarkersProps = {
//     data: any;
//     callback:Function;
//     differenceInMill: number;
// }

export function AllMarkers({data=[],callback=()=>{},differenceInMill=24*60*60*1000}) {
    
    return data.map((item) => {
        let icon = GreyMarker
        if(item.lastSeen){
          if((new Date().getTime()   - new Date(item.lastSeen).getTime() ) > differenceInMill){
            icon = RedMarker
          }else{
            icon = GreenMarker
          }
        } 
        return (
         (item.latitude && item.longitude) ?
          <Marker
            key={uuidv4()}
            position={[item.latitude, item.longitude]}
            
            eventHandlers={{click: () => callback(item)}}
            title={item.title||""}
            icon={icon}
          /> : null
      )})
}
