import React from "react";
import { Flex, Radio } from "antd";
function SensorPlatform({ PlatformValue, onPlatformChange }) {
  const boxStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    //border: '1px solid #40a9ff',
  };
  return (
    <Flex style={boxStyle} justify={"center"} align={"center"}>
      <div>Select Platform:</div>
      <Radio.Group onChange={onPlatformChange} value={PlatformValue}>
        <Radio value={"Sentilo"}>Sentilo</Radio>
        <Radio disabled={true} value={"Fiware"}>
          Fiware
        </Radio>
        <Radio value={"None"}>None</Radio>
      </Radio.Group>
    </Flex>
  );
}
export default SensorPlatform;
