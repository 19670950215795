import { ColumnConfig } from '../../components/DynamicTable/Interfaces/ColumnConfig';
import CountryService from '../Countries/Countries.service';

export const UserColumns: ColumnConfig[] = [
    {dataIndex: 'username',title: 'Username',required: true,default: true,filter: 'text',type: 'text',visible: true,editable: true},
    {dataIndex: 'name',title: 'Name',required: true,default: true,filter: 'text',type: 'text',visible: true,editable: true },
    {dataIndex: 'surname',required: false,title: 'Surname',default: true,filter: 'text',type: 'text',visible: true,editable: true},
    {dataIndex: 'roleName',required: true,title: 'Role',default: true,filter: 'text',type: 'roleSelector',visible: true,editable: true,},
    {dataIndex: 'email',title: 'Email',required: true,default: true,filter: 'text',type: 'text',visible: true,editable: true,},
    {dataIndex: 'country',title: 'Country',filter: 'text',type: 'service',service: new CountryService(),visible: true,editable: true,required: false,default: false},
    {dataIndex: 'postalCode',title: 'Postal Code',default: true,filter: 'text',type: 'text',visible: false,editable: true,required: false,},
    {dataIndex: 'city',title: 'City',filter: 'text',type: 'text',visible: false,editable: true,required: false,default: false,},
    {dataIndex: 'phone',title: 'Phone',filter: 'text',type: 'text',visible: false,editable: true,required: false,default: false,},
    {dataIndex: 'pswd',title: 'Password',required: true,type: 'text',visible: false,editable: true,default: false,}];