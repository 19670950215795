import { ENV } from "../../utils";
import axios from "axios";
import AuthService from "../Auth/Auth.service";

export default class NetworkServerService {
  token: string;
  constructor() {
    this.token = AuthService.getToken();
  }
  async getAll(topic: string = "") {
    const headers = {
      headers: {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${ENV.Infraestructure_API}/network-servers`,
      headers,
    );
  }
}
