import { Modal } from "antd";

export default function ConfirmationModal({
  message,
  modalOpen,
  setModalOpen,
  callback,
}) {
  const AsyncFunction = (async () => {}).constructor;
  const onOk = async () => {
    callback instanceof AsyncFunction ? await callback() : callback();
    setModalOpen(false);
  };
  const onCancel = () => {
    setModalOpen(false);
  };
  return (
    <Modal open={modalOpen} onCancel={onCancel} onOk={onOk}>
      <p>{message}</p>
    </Modal>
  );
}
