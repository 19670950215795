import { ENV } from "../../utils";
import AuthService from "../Auth/Auth.service";
import { HardwareInAlarm } from "../Alarms/Alarms.service";
//export type HardwareInAlarm = "stas"|"gateways"|"devices"|"controlCabinet"|"streetlight"
export default class NotificationService {
  hardware: HardwareInAlarm[];
  token: string = "";
  url: string = "";
  services: any[] = [];
  constructor(hardware: HardwareInAlarm[]) {
    this.hardware = hardware;
    this.token = AuthService.getToken();
    this.url = `${ENV.Alarms_API}/notifications`;
  }
  async getAll(filters: any ={}, currentPage: number = 0,pageSize: number = 10) {
    const offset = (currentPage - 1) * pageSize;
    return await fetch(`${this.url}?where=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, {
      method: "GET",
      headers: { "x-access-token": this.token },
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Error al realizar la solicitud: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        data.result.map(
          (item: any, index: number) => (data.result[index].key = item.id),
        );
        data.result.map(
          (item: any, index: number) =>
            (data.result[index].alarm = item.alarm.name),
        );
        return data;
      });
  }

  async delete(entityId: string | number) {
    await fetch(`${this.url}/${entityId}`, {
      method: "DELETE",
      headers: { "x-access-token": this.token },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud: ${response.status}`);
      }
    });
  }

  async update(id: number | string, data: any) {
    await fetch(`${this.url}/${id}`, {
      method: "PUT",
      headers: {
        "x-access-token": this.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
}
