import { IColumnForCRUD } from "../interfaces.service";

export const columns:IColumnForCRUD[] = [
    {   title: "Profile Name",   dataIndex: "profileName", serviceLabel:"profileName",  filter: "text",   type: "text",   visible: true,   editable: true },
    {   title: "Description",   dataIndex: "description",   filter: "text",   type: "text",   visible: true,   editable: true },
    {   title: "URL",   dataIndex: "url",   filter: "text",   type: "text",   visible: true,   editable: true },
    {   title: "Port",   dataIndex: "port",   filter: "none",   type: "number",   visible: true,   editable: true },
    {   title: "User",   dataIndex: "user",   filter: "none",   type: "text",   visible: true,   editable: true },
    {   title: "Password",   dataIndex: "pass",   filter: "none",   type: "text",   visible: false,   editable: true },
    {   title: "SSL/TLS",   dataIndex: "ssl_tls",   filter: "none",   type: "boolean",   visible: false,   editable: true },
    {   title: "Signed by Server",   dataIndex: "CA_signed_server_certificate",   filter: "none",   type: "boolean",   visible: false,   editable: true },
    {   title: "Certificate",   dataIndex: "certificate",   filter: "none",   type: "text",   visible: false,   editable: true },
  ];