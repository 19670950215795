import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar.component";
import { theme, Layout, Menu } from "antd";
import { useState } from "react";
import { menuItems } from "../../utils/menuItems";
import { useAuth } from "../../Hooks";

const { Content, Sider } = Layout;
const { useToken } = theme;

function Home() {
  const { user } = useAuth();
  const { token } = useToken();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  
  const toggleCollapsed = () => setCollapsed(!collapsed);

  let leftPos = 0;
  if (collapsed) leftPos = "-200px";

  const items = menuItems.filter(
    (item) => !item.visibility || item.visibility.includes(user.userRole),
  );

  return (
    <Layout style={{ height: "100%" }}>
      <div className="App">
        <NavBar collapsedFunction={toggleCollapsed} collapsed={collapsed} />
        <Layout hasSider>
          <Sider
            className="left-menu"
            style={{
              background: token.colorBgElevated,
              overflow: "auto",
              zIndex: 2,
              left: leftPos,
              bottom: 0,
            }}
          >
            <Menu
              defaultSelectedKeys={[""]}
              defaultOpenKeys={[]}
              mode="inline"
              items={items}
              onClick={(selectedKeys) => {
                navigate(selectedKeys.key);
              }}
            />
          </Sider>
          <Content style={{ height: "100%", background: token.colorBgBase }}>
            <Outlet />
          </Content>
        </Layout>
      </div>
    </Layout>
  );
}

export default Home;
