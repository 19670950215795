// utils
import React from "react";
import { useState } from "react";

import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import SensorWithoutTopicService from "../../../services/SensorWithoutTopic/SensorWithoutTopic.service";
import { Button, Flex, Space } from "antd";
import { InfoCircleOutlined,CloudDownloadOutlined } from '@ant-design/icons';
import ShowInfoModal from "./ShowInfoModal";
export default function SensorsWithoutTopicTab() {
    
  const service = new SensorWithoutTopicService();
  const [modalInfoOpen, setModalInfoOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<any>({});
  const [refreshTable, setRefreshTable] = useState<boolean>(true);


  const columns = [
    ...service.getColumns(),
    {
      title: <Flex align="center" justify="center">Actions</Flex>,
      key: 'actions',fixed: 'right',  width: 100,
      align: 'center',
      render: (text:string, record:any) => (
        <Flex justify="center" ><Space align="center">
          <InfoCircleOutlined 
              style={{ fontSize: "1.8em", color: "yellow"}} 
              onClick={async () => {
                setModalInfo(await service.getContextDataByObject(record))
                setModalInfoOpen(true);
              }} 
              onPointerOverCapture={undefined} onPointerMoveCapture={undefined}              
          />
        </Space></Flex>
      )
      }
  ]
  
  const extraActions = 
    <Button
    type="primary"
    icon={<CloudDownloadOutlined/>}
    onClick={async () => {await service.downloadCSV({})}} 
    >
      Download CSV
    </Button>
  return (
    <>
        <ShowInfoModal
        open={modalInfoOpen}
        setOpen={setModalInfoOpen}
        data={modalInfo}
        />

        <DynamicTable
        title={undefined}
        service={service}
        columns={columns}
        generalActions={{}}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        multiSelect={false}
        extraGeneralActions={extraActions}
        />
      </>
    
  );
}
