import { useEffect, useState } from "react";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Flex, message, Space } from "antd";
import AddOrEditEntityModal from "../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import MqttServersService from "../../services/MqttServer/MqttServer.service";
import { CRUDPrivilages } from "../../components/DynamicTable/Interfaces/CRUDPrivilages";

type params = { CRUDPrivilages: CRUDPrivilages };

export default function MqttServer({ CRUDPrivilages }: params) {
  const [service, setService] = useState(new MqttServersService());
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [mqttServerId, setMqttServerId] = useState(0);
  const [refreshTable, setRefreshTable] = useState(true);

  useEffect(() => {
    setService(new MqttServersService());
  }, []);

  const columns = [
    ...service.getColumns(),
    {
      title: (
        <Flex align="center" justify="center">
          Actions
        </Flex>
      ),
      key: "actions",
      dataIndex:"actions",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text:string,record: any) => (
        <Flex justify="center">
          <Space align="center">
            {CRUDPrivilages.update && (
              <EditOutlined
                style={{ fontSize: "1.8em", color: "#7150CC" }}
                onClick={() => {
                  setMqttServerId(record.id);
                  setEditModal(true);
                }}
              />
            )}
            {CRUDPrivilages.delete && (
              <DeleteOutlined
                style={{ fontSize: "1.8em", color: "#DC4446" }}
                onClick={() => {
                  setMqttServerId(record.id);
                  setDeleteModal(true);
                }}
              />
            )}
          </Space>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <AddOrEditEntityModal
        title={`MqttServer`}
        type="edit"
        service={service}
        id={mqttServerId}
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        paramsPerPage={10}
        callback={() => { setRefreshTable(!refreshTable); }}
      />

      <ConfirmationModal
        callback={async ()=>{ 
          if(await service.delete(mqttServerId)){
            setRefreshTable(!refreshTable);
            message.success("Deleted successfully!")
          }
        }}       
        message="Are you sure you want to delete this mqtt server?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <DynamicTable
        title={`MqttServer`}
        service={service}
        generalActions={{ add: CRUDPrivilages.create ? {} : null,configColumns: {},deleteSelected:{}}}
        columns={columns}
        multiSelect={true}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
      />
    </>
  );
}
