import axios from "axios";
import AuthService from "../Auth/Auth.service";
import { ENV } from "../../utils";

export default class GatewaysAndSensorsService {
    token: string;
    constructor() {
      this.token = AuthService.getToken();
    }
    async getAll(filters: any = {}) {
        const headers = {headers: {"x-access-token": this.token,"Content-Type": "application/json"},};
        const reqParams = Object.keys(filters).length === 0 ?  "" : `?filters=${JSON.stringify(filters)}`
        return await axios.get(`${ENV.Infraestructure_API}/gatewaysAndSensors${reqParams}`, headers)
        .then(response => response.data)
        .catch((error) => {
          console.log("Error:", error);
          return [];
        })
    }
}