import { Modal } from "antd";
import { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Table } from "antd";
import { checkData, columns } from "./Table";

export function ModalStaUpdateSalt({
  originalData = {},
  modalOpen,
  setModalOpen,
}) {
  let [data, setData] = useState([]);
  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  const dataTable = checkData(data);

  return (
    <Modal
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={() => setModalOpen(false)}
      width={800}
    >
      <Title>Modal Salt Update</Title>
      <div justify="center">
        <Table
          dataSource={dataTable}
          pagination={{ pageSize: 8 }}
          columns={columns}
        />
      </div>
    </Modal>
  );
}
