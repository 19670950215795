import AuthService from "./Auth/Auth.service";
import { ENV } from "../utils";

export class BasicApi {
  route: string;
  constructor(route: string) {
    this.route = route;
  }

  async getAllWithoutPagination(extra = "") {
    const url = `${this.route}/allWithoutPagination?searchBy=${JSON.stringify(extra)}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": AuthService.getToken(),
      },
    };
    try {
      const response = await fetch(url, params);
      const result = await response.json();
      if (![200, 304].includes(response.status)) throw result;
      return result;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  async getAll(limit = 40, offset = 0, extra = "") {
    const url = `${this.route}?limit=${limit}&offset=${offset}&searchBy=${JSON.stringify(extra)}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        "x-access-token": AuthService.getToken(),
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    try {
      if (![200, 304].includes(response.status)) throw result;
      if (result.db.result !== undefined) return result;
    } catch (error) {
      console.log(error);
      return result;
    }
    if (![200, 304].includes(response.status)) throw result;
  }

  async getInfraestructureEnums() {
    const url = ENV.Infraestructure_API + "/enums";
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();

    if (![200, 304].includes(response.status)) throw result;

    return result;
  }

  async getOne(id: string | number) {
    const url = `${this.route}/${id}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (![200, 304].includes(response.status)) throw result;

    return result;
  }

  async addOne(data: any) {
    const url = this.route;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": AuthService.getToken(),
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status !== 201) throw result;

    return result;
  }

  async editOne(data: any, id: string | number) {
    const url = `${this.route}/${id}`;
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": AuthService.getToken(),
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    if (![200, 201].includes(response.status)) throw result;

    return result;
  }

  async deleteOne(id: string | number) {
    const url = `${this.route}/${id}`;
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": AuthService.getToken(),
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (![200, 304].includes(response.status)) throw result;

    return result;
  }
}
