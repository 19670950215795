import '../../styles/App.css'
import '../../styles/Map.css'
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { RedMarker,GreyMarker,GreenMarker } from './MarkersIcons/MarkersIcons';
import { WindowMarker} from './WindowMaker/WindowMarker.component';
import MapMenu from '../Map/MapMenu/MapMenu.component';
import LazyLoad from 'react-lazyload';
import { v4 as uuidv4 } from 'uuid';
import { dataToShow } from './infoToShow';
import { AllMarkers } from './AllMarkers/AllMarkers';
function listItems(dataMap, callback,diference=48*60*60*1000) {

  return dataMap.map((item) => {
    let icon = GreyMarker
    if(item.lastSeen){
      if((new Date() - new Date(item.lastSeen)) > diference){
        icon = RedMarker
      }else{
        icon = GreenMarker
      }
    } 
    return (
     (item.latitude && item.longitude) ?
      <Marker
        key={uuidv4()}
        position={[item.latitude, item.longitude]}
        title={item.lastSeen}
        icon={icon}
        eventHandlers={{click: () => callback(item)}}
    ></Marker> : null
  )}
  )
}

export function Map({data,dataToShowAtMarker=dataToShow, filters={} }) {

  const [windowMarkerVisible, setWindowMarkerVisible] = useState(false)
  const [windowMarkerData, setWindowMarkerData] = useState({})
  const handleClickMarker = (item) => {
    setWindowMarkerData(item)
    setWindowMarkerVisible(true)
  }
 
  return (
    <>
      <WindowMarker 
        visible={windowMarkerVisible} setVisible={setWindowMarkerVisible} 
        data={windowMarkerData} dataToShow={dataToShowAtMarker}
      />
      {/* <MapMenu/> */}
      <MapContainer
        className="markercluster-map"
        center={[40.0563, -1.12761]}
        zoom={10}
        maxZoom={18}
        style={{ margin: '0px', width: '90vw',height: '80vh', zIndex: 0 }}
        onClick={(e) => {console.log(e)}}

      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          onClick={(e) => {console.log(e)}}
        />

        <MarkerClusterGroup chunkedLoading
          onClick={(e) => {console.log(e)}}
        >
          <AllMarkers data={data} callback={handleClickMarker} differenceInMill={ filters["lastSeen-diffInMill"]}/>
          
          
          
          { 
          // listItems(
          //     data,
          //     handleClickMarker,
          //     filters["lastSeen-diffInMill"]?filters["lastSeen-diffInMill"]:undefined) 
          //   
          }
        </MarkerClusterGroup>
      </MapContainer>
    </>
  )
}


