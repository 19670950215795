import React from "react";
import { Modal, Flex, Input, Select } from "antd";
import { stringToMilliseconds } from "../../../utils/date";

interface FiltersMapProps {
  visible: boolean;
  onClose: () => void;
  filters: any;
  setFilters: (filters: any) => void;
  disableLastSeen: boolean;
  setDisableLastSeen: (value: boolean) => void;
}

const FiltersMap: React.FC<FiltersMapProps> = ({ visible, onClose, filters, setFilters, disableLastSeen, setDisableLastSeen }) => (
  <Modal     style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}title="Filters" visible={visible} onCancel={onClose} footer={null}>
    <Flex justify="space-around" style={{ width: "100%" }}>
      {/* ID */}
      <Flex style={{margin:'8px'}} vertical>
        <h5>Id:</h5>
        <Input
          style={{ width: "200px" }}
          onChange={(e) => setFilters({ ...filters, id: e.target.value })}
        />
      </Flex>
      {/* TYPE */}
      <Flex style={{margin:'8px'}} vertical>
        <h5>Type:</h5>
        <Select
          style={{ width: "200px" }}
          mode="multiple"
          defaultValue={["Gateways", "Sensors"]}
          options={["Gateways", "Sensors"].map((item) => ({ label: item, value: item }))}
          onChange={(value: any[]) => setFilters({ ...filters, type: value })}
        />
      </Flex>
      {/* Emb-Cent */}
      <Flex style={{margin:'8px'}} vertical>
        <h5>Emb-Cent:</h5>
        <Select
          style={{ width: "200px" }}
          mode="multiple"
          defaultValue={["embedded", "centralized"]}
          options={["embedded", "centralized"].map((item) => ({ label: item, value: item }))}
          onChange={(value: any[]) => setFilters({ ...filters, EmbCent: value })}
        />
      </Flex>
      {/* Mac */}
      <Flex style={{margin:'8px'}} vertical>
        <h5>Mac (topic):</h5>
        <Input
          style={{ width: "200px" }}
          placeholder="mac"
          onChange={(e) => setFilters({ ...filters, topic: e.target.value })}
        />
      </Flex>
      {/* LastSeen */}
      <Flex  style={{margin:'8px'}}vertical>
        <h5>LastSeen:</h5>
        <Select
          style={{ width: "200px" }}
          mode="multiple"
          options={[{ label: "🟩 ON", value: "on" }, { label: "🟥 OFF", value: "off" }, { label: "⬜ Null", value: "null" }]}
          defaultValue={["on", "off", "null"]}
          onChange={(value: any[]) => {
            setDisableLastSeen(value.length === 1 && value[0] === "null");
            setFilters({
              ...filters,
              [`lastSeen-onOff`]: value,
              [`lastSeen-diff`]: filters[`lastSeen-diff`] || "24h",
              [`lastSeen-diffInMill`]: stringToMilliseconds(filters[`lastSeen-diff`] || "24h")
            });
          }}
        />
        <Input
          placeholder="6h"
          disabled={disableLastSeen}
          defaultValue={filters[`lastSeen-diff`]}
          style={{ width: "200px" }}
          onChange={(e) => setFilters({ 
              ...filters, 
              [`lastSeen-diff`]: e.target.value, 
              [`lastSeen-diffInMill`]: stringToMilliseconds(e.target.value) 
          })}
        />
      </Flex>
    </Flex>
  </Modal>
);

export default FiltersMap;
