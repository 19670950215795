//import { useEffect, useState } from "react";
import MqttServerSelector from "./MqttServerSelector.component";

//TODO REFACTOR

function EmbebedNetServer({ element, form, dataName }) {
  // const [checked, setChecked] = useState(true)

  // useEffect(() => {
  //     if (element.embebed) setChecked(true)
  //     else setChecked(false)
  // }, [element])

  return (
    <>
      <MqttServerSelector
        element={element}
        dataName={dataName}
        title="MQTT Server"
        form={form}
      />
    </>
  );
}

export default EmbebedNetServer;
