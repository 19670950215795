import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { theme, Spin, Typography , Button} from "antd";
import { useTranslation } from "react-i18next";
import { TabNavigation, TabPanel, } from "../components/TabNavigation/TabNavigation";
import { MqttClient } from "../components/MqttClient/MqttClient.jsx";
import PhysicalGatewayService from "../services/PhysicalGateway/PhysicalGateway.service.tsx";
import { ModalUpdateFirmware } from "./Sta/StaTab/Modals/ModalUpdateFirmware/ModalUpdateFirmware";
const physicalGatewayService = new PhysicalGatewayService();

const { useToken } = theme;
const { Title } = Typography;

const SensorDetails = (allAttributes, route, form, title) => {
  // Just delete 
  const [modal_UpdateFirmware_Open, setModal_UpdateFirmware_Open] = useState(false);

  //----
  const [loading /*setLoading*/] = useState(false);
  const { token } = useToken();
  const { t } = useTranslation();
  const [isPhysicalGatewayCentralized, setIsPhysicalGatewayCentralized] =
    useState(false);
  const params = useParams();

  useEffect(() => {
    physicalGatewayService
      .isCentralized(params.physicalGatewayId)
      .then((res) => {
        setIsPhysicalGatewayCentralized(res);
      });
  }, [route]);

  return (
    <div className="wrapper">
      <ModalUpdateFirmware
        modalOpen={modal_UpdateFirmware_Open}
        setModalOpen={setModal_UpdateFirmware_Open}
        callback={() => {return null}}
      />
      <TabNavigation selected={0}>
        <TabPanel title="MQTT Visualizer">
          <Title style={{ color: token.colorText }}>
            {t("MQTT Visualizer")}
          </Title>
          <Spin size="middle" spinning={loading}>
            <MqttClient
              element={"sensors"}
              isCentralized={isPhysicalGatewayCentralized}
            />
          </Spin>
        </TabPanel>

        <TabPanel title="Tab2">
          <div style={{ display: "flex" }}>
            <h6>Tab 2 content</h6>
            <Button
          type="primary"
          style={{ marginRight: "6px" }}
          className="buttonCentered"
          onClick={() => {
            setModal_UpdateFirmware_Open(true);
          }}
        >
          Actualizar Firmware
        </Button>
          </div>
        </TabPanel>
      </TabNavigation>
    </div>
  );
};

export default SensorDetails;
