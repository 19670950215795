import { Tabs } from "antd";
import Alarms from "../../AlarmSystem/Alarms/Alarms";
import Notification from "../../AlarmSystem/Notifications/Notifications";
import { useState } from "react";

import ControlCabinetTab from "./ControlCabinetTab/ControlCabinetTab";
import ControlCabinetService from "../../../services/ControlCabinet/ControlCabinet.service";

export default function ControlCabinet() {
  let [tabValue, setTabValue] = useState("controlCabinet");

  const items = [
    {
      key: "controlCabinet",
      label: "ControlCabinet",
      children: <ControlCabinetTab />,
    },
    {
      key: "alarms",
      label: "Alarms",
      children: <Alarms hardwares={["controlCabinets"]} hardwareServices={[new ControlCabinetService()]} />,
    },
    {
      key: "notifications",
      label: "Notifications",
      children: <Notification hardwares={["controlCabinets"]} />,
    },
  ];

  return <Tabs activeKey={tabValue} items={items} onChange={setTabValue} />;
}
