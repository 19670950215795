import { Breadcrumb } from "antd";
import { createContext, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BreadcrumbsContext = createContext();
export const BreadcrumbsUpdateContext = createContext(() =>
  console.log("Called null"),
);

const patternphysicalGateway = new RegExp(
  "/home/gateway/physical-gateways/.*/organizations",
);
const routephysicalGateway =
  "/home/gateway/physical-gateways/:id/organizations";

const breadcrumbNameMap = {
  "/home": "Home",

  "/home/LoraWAN": "LoraWAN",
  "/home/LoraWAN/networkserver": "Network Servers",
  "/home/LoraWAN/sensor": "Sensors",
  "/home/LoraWAN/dashboard": "Dashboard",
  "/home/LoraWAN/message": "Message",

  "/home/decoder": "Decoder",
  "/home/decoder/server": "Server",

  "/home/gateway": "Gateway",
  "/home/gateway/physical-gateways": "physical Gateways",
  "/home/gateway/physical-gateways/:id/organizations":
    "Organizations in Gateway",
  // '/home/gateway/group': 'Group',

  "/home/sensor": "Sensor",
  "/home/sensor/units": "Units",
  "/home/sensor/types": "Types",

  "/home/server": "Server",

  "/home/user-groups": "User Groups",
  "/home/user-groups/business": "Business",
  "/home/user-groups/projects": "Projects",
  "/home/user-groups/council": "Council",

  "/home/myuser": "User",

  "/home/users": "Users",
  "/home/logger": "Logger",
  "/home/mqtt-server": "Mqtt Server",

  "/home/controlCabinet": "dispositivos",

  "/home/door": "Door",
  "/home/door/doors": "Doors",
  "/home/door/calendar": "Calendar",
  "/home/door/requests": "Request",
  "/home/door/users": "User",

  "/home/light": "Energy",
  "/home/light/groups": "Groups",
  "/home/light/devices": "Devices",
  "/home/light/requests": "Requests",
  "/home/light/alarm": "Alarm",
  "/home/light/alarm/notifications": "Notifications",
  "/home/light/alarm/list": "List",
  "/home/light/status": "Status",
  "/home/light/planning": "Planning",
  "/home/light/actuator": "Actuator",
  "/home/light/history": "History",
  "/home/light/controlcabinet": "Control Cabinet",

  "/home/water": "Water",
  "/home/water/general": "General",
  "/home/water/general/municipal": "Municipal",
  "/home/water/general/diameter": "Diameter",
  "/home/water/general/servicefee": "Service Fee",
  "/home/water/general/consumptionblock": "Consumption Block",
  "/home/water/general/sewerrate": "Sewer Rate",
  "/home/water/general/canon": "Canon",
  "/home/water/general/counter": "Counter",
  "/home/water/general/investment": "Investment",
  "/home/water/alarms": "Alarms",
  "/home/water/alarms/list": "List",
  "/home/water/devices": "Devices",
  "/home/water/devices/list": "List",
  "/home/water/group": "Groups",
  "/home/water/user": "Users",
  "/home/water/balance": "Balance",
  "/home/water/observation": "Observations",
  "/home/water/map": "Map",
  "/home/water/control": "Control",

  "/home/generic": "Generic",
  "/home/generic/devices": "Devices",
  "/home/generic/alarm": "Alarm",
  "/home/generic/alarm/notification": "Notifications",
  "/home/generic/history": "History",

  "/home/capacity": "Capacity",
  "/home/capacity/device": "Device",
  "/home/capacity/panel": "Panel",
  "/home/capacity/parking": "Parking",
  "/home/capacity/dashboard": "Dashboard",
  "/home/capacity/map": "Map",

  "/home/irrigation": "Irrigation",
  "/home/irrigation/device": "Device",

  "/home/boiler": "Boiler",
  "/home/boiler/device": "Device",
};

const checkIfRouteFollowPattern = (pattern, route) => {
  if (pattern.test(route)) {
    return true;
  }
  return false;
};

export function BreadcrumbsProvider({ children }) {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    <Breadcrumb.Item key="home">
      <Link to={"/home"}>Home</Link>
    </Breadcrumb.Item>,
  ]);
  const { t } = useTranslation();

  function getBreadcrumbs(location) {
    let pathSnippets = location.pathname.split("/").filter((i) => i);
    if (checkIfRouteFollowPattern(patternphysicalGateway, location.pathname)) {
      pathSnippets = routephysicalGateway.split("/").filter((i) => i);
    }
    const extras = pathSnippets.map((element, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{t(breadcrumbNameMap[url])}</Link>
        </Breadcrumb.Item>
      );
    });
    setBreadcrumbItems(extras);
  }

  return (
    <BreadcrumbsContext.Provider value={breadcrumbItems}>
      <BreadcrumbsUpdateContext.Provider value={getBreadcrumbs}>
        {children}
      </BreadcrumbsUpdateContext.Provider>
    </BreadcrumbsContext.Provider>
  );
}
