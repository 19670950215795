import React, { useState } from "react";

import { theme } from "antd";
import { BankOutlined } from "@ant-design/icons";

import ModalCNO from "./ModalCNO";
const { useToken } = theme;

const CNOButton = ({ route, element, alt }) => {
  const { token } = useToken();
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <BankOutlined
        alt={alt}
        style={{ fontSize: "large", color: token.colorPrimary }}
        onClick={openModal}
      />
      <ModalCNO
        visible={modalVisible}
        onCancel={closeModal}
        element={element}
      />
    </>
  );
};

export default CNOButton;
