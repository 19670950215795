import React, { useState } from "react";

import { theme } from "antd";
import { FileExclamationOutlined } from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";

import CommonForm from "../../UpdateItemForm/CommonForm";
import { useTranslation } from "react-i18next";

const { useToken } = theme;
/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const EditTermsButton = ({ route, attributes, element }) => {
  const { token } = useToken();
  const { t } = useTranslation();

  // Edit an element modal view
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  function openEditModal() {
    setIsEditModalOpen(true);
  }

  function handleEditCancel() {
    setIsEditModalOpen(false);
  }
  // -------------------------------

  return (
    <>
      <FileExclamationOutlined
        style={{ fontSize: "large", color: token.colorPrimary }}
        onClick={openEditModal}
      />
      <Modal
        footer={null}
        title={t("Edit Element")}
        open={isEditModalOpen}
        onCancel={handleEditCancel}
      >
        <CommonForm route={route} attributes={attributes} element={element} />
      </Modal>
    </>
  );
};

export default EditTermsButton;
