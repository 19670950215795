import { Route } from "react-router-dom";
import CommonTable from "../../../components/CommonTable";
import { decoderTable } from "../../../utils/tables";
import VariableColumnAttributesModal from "../../../components/CommonTable/VariableColumnAttributesModal";
import { ENV } from "../../../utils";
import { PrivateRoute } from "../../utils/PrivateRoute";
import { TableDataProvider } from "../../../context/TableData/TableDataContext";
import CommonForm from "../../../components/UpdateItemForm/CommonForm";

export function DecoderRoutes() {
  return (
    <Route path="decoder">
    <Route
      path="server"
      element={
        <PrivateRoute>
          <TableDataProvider>
            <CommonTable
              allAttributes={decoderTable.attributes}
              route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.DECODER}`}
              form={CommonForm}
              dataName={ENV.API_ROUTES.DECODER}
              title={"Decoders"}
              rowsPerPage={15}
              additionalButtons={
                <VariableColumnAttributesModal
                  allAttributes={decoderTable.attributes}
                />
              }
              wrapper={true}
            />
          </TableDataProvider>
        </PrivateRoute>
      }
    />
  </Route>
  );
}
