import { Modal, Steps } from "antd";
import { TableHardware } from "./TableHardware/TableHardware";
import { AlarmOptionType, AlarmSetting } from "./AlarmSetting/AlarmSetting";
import AlarmService from "../../../../services/Alarms/Alarms.service";
import { HardwareInAlarm } from "../../../../services/Alarms/Alarms.service";
type params = {
  alarmId?: number;
  modalOpen: boolean;
  setModalOpen: Function;
  currentStep: number;
  onChangeStep: any;
  alarmEntity: any;
  setAlarmEntity: Function;
  hardwareSelectedKeys: any;
  setHardwareSelectedKeys: Function;
  hardwares: HardwareInAlarm[];
  callback: Function;
  hardwareServices: any;
  optionTypes?:AlarmOptionType[];
};

export function ModalAlarms({
  alarmId,
  setModalOpen,
  modalOpen,
  currentStep,
  onChangeStep,
  alarmEntity,
  setAlarmEntity,
  hardwareSelectedKeys,
  setHardwareSelectedKeys,
  hardwares,
  callback,
  hardwareServices,
  optionTypes,
}: params) {
  const alarmService = new AlarmService(hardwares);

  const sendData = async () => {
    let tmp_data = { ...alarmEntity, ...hardwareSelectedKeys };
    alarmId
      ? await alarmService.update(alarmId, tmp_data)
      : await alarmService.create(tmp_data);
    setAlarmEntity(tmp_data);
    setModalOpen(false);
    callback();
  };
  let stepItems = [{ title: "Set Alarm" }];
  hardwares.map((hardware) =>
    stepItems.push({
      title: hardware.charAt(0).toUpperCase() + hardware.slice(1),
    }),
  );

  return (
    <Modal
      width={"800px"}
      open={modalOpen}
      onOk={() => sendData()}
      onCancel={() => {
        setModalOpen(false);
      }}
    >
      <Steps
        current={currentStep}
        onChange={onChangeStep}
        style={{ padding: "20px 10px" }}
        items={stepItems}
      />
      <div hidden={currentStep !== 0}>
        <AlarmSetting
          alarmEntity={alarmEntity}
          setAlarmEntity={setAlarmEntity}
          optionTypes={optionTypes}
        />
      </div>
      {hardwares.map((hardware, index) => {
        return (
          <div hidden={currentStep !== index + 1} key={index}>
            <TableHardware
              alarmService={alarmService}
              hardware={hardware}
              hardwareService={hardwareServices[index]}
              selectedKeys={hardwareSelectedKeys}
              setSelectedKeys={setHardwareSelectedKeys}
            />
          </div>
        );
      })}
    </Modal>
  );
}
