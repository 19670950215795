import { useState } from "react";
import { theme, message } from "antd"; // Importa message desde antd
import { DeleteOutlined } from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import { useTranslation } from "react-i18next";
import { BasicApi } from "../../../services/basicApi";

const { useToken } = theme;

const RemoveButton = ({ element, route, onRemove }) => {
  const { t } = useTranslation();
  const { token } = useToken();
  const api = new BasicApi(route);

  // Delete an element modal view
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage(); // Inicializa messageApi

  function openDeleteModal() {
    setIsDeleteModalOpen(true);
  }

  async function handleDeleteOk() {
    setIsDeleteModalOpen(false);

    if (!element.id) {
      element.mac ? (element.id = element.mac) : (element.id = undefined);
    }

    try {
      const result = await api.deleteOne(element.id); // Espera el resultado de la eliminación
      console.log(result);
      if (result) {
        messageApi.open({
          type: "success", // Cambia a success para mensajes exitosos
          content: "Deleted successfully",
        });
        onRemove(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error trying to remove",
      });
    }
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  return (
    <>
      {contextHolder}
      <DeleteOutlined
        style={{ fontSize: "large", color: token.colorError }}
        onClick={openDeleteModal}
      />
      <Modal
        title="Delete Element"
        open={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      >
        <p>{t("Are you sure you want to remove this element?")}</p>
        {element.id ? (
          <p>
            {t("ID")}: {element.id}
          </p>
        ) : null}
        {element.name ? (
          <p>
            {t("Name")}: {element.name}
          </p>
        ) : null}
      </Modal>
    </>
  );
};

export default RemoveButton;
