
import { IColumnForCRUD } from "../interfaces.service";
import NetworkServerService from "../NetworkServer/NetworkServer.service";
import OrganizationService from "../Organization/Organization.service";

export const Columns = (mac: string): IColumnForCRUD[] => {
    return [
        { dataIndex: 'name', title: 'Name', filter: 'text', type: 'text', visible: true, editable: true },
        { dataIndex: 'networkServerID', title: 'Network Server ID', filter: 'text', type: 'service', service: new NetworkServerService(),defaultValue: mac, serviceLabel: "gatewayMac", serviceValue: "id", visible: true, editable: true, },
        { dataIndex: 'organizationID', title: 'Organization ID', filter: 'text', type: 'service', service: new OrganizationService(mac), serviceLabel: "name", serviceValue: "id", visible: true, editable: true, },
        { dataIndex: 'addGWMetaData', title: 'Add GW Meta Data', filter: 'bool', type: 'boolean', visible: true, editable: true, },
        { dataIndex: 'channelMask', title: 'Channel Mask', filter: 'text', type: 'text', visible: false, editable: true, },
        { dataIndex: 'devStatusReqFreq', title: 'Dev Status Req Freq', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'dlBucketSize', title: 'DL Bucket Size', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'dlRate', title: 'DL Rate', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'dlRatePolicy', title: 'DL Rate Policy', filter: 'text', type: 'text', visible: false, editable: true, },
        { dataIndex: 'drMax', title: 'DR Max', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'drMin', title: 'DR Min', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'gwsPrivate', title: 'GWs Private', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'hrAllowed', title: 'HR Allowed', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'nwkGeoLoc', title: 'NWK Geo Loc', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'prAllowed', title: 'PR Allowed', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'raAllowed', title: 'RA Allowed', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'reportDevStatusBattery', title: 'Report Dev Status Battery', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'reportDevStatusMargin', title: 'Report Dev Status Margin', filter: 'bool', type: 'boolean', visible: false, editable: true, },
        { dataIndex: 'minGWDiversity', title: 'Min GW Diversity', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'targetPER', title: 'Target PER', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'ulBucketSize', title: 'UL Bucket Size', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'ulRate', title: 'UL Rate', filter: 'text', type: 'number', visible: false, editable: true, },
        { dataIndex: 'ulRatePolicy', title: 'UL Rate Policy', filter: 'text', type: 'text', visible: false, editable: true, },
    ];
}