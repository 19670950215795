import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { enableInputGroupAtom } from "../../components/UpdateItemForm/CommonForm.jsx";

function TextInput(props) {
  const { t } = useTranslation();

  const dataName = props.dataName;
  const title = props.title;
  const [enableInputGroup] = useAtom(enableInputGroupAtom);
  const textWidth = Object.keys(props).includes("textWidth")
    ? props.textWidth
    : 25;
  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[
        {
          required: props.required,
          max: textWidth,
        },
      ]}
    >
      <Input
        copyable="true"
        disabled={
          props.disabledTargetValue === enableInputGroup[props.disabledVarName]
        }
      />
    </Form.Item>
  );
}

export default TextInput;
