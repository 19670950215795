import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ModalUploadCSV } from "../ModalUploadCSV/ModalUploadCSV";

export function ButtonUploadCSV({ csvOptions, fileExtension = ".csv" }) {
  const [modal_CSV_Open, setModal_CSV_Open] = useState(false);

  return (
    <>
      <ModalUploadCSV
        modalOpen={modal_CSV_Open}
        setModalOpen={setModal_CSV_Open}
        csvOptions={csvOptions}
        fileExtension={fileExtension}
      />
      <Button
        type="primary"
        style={{ margin: "6px" }}
        icon={<UploadOutlined />}
        onClick={() => {
          setModal_CSV_Open(true);
        }}
      >
        Upload {fileExtension.split(".")[1].toUpperCase()}
      </Button>
    </>
  );
}
