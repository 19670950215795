// utils
import React from "react";
import { useState } from "react";
import AddOrEditEntityModal from "../../../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import DynamicTable from "../../../../components/DynamicTable/DynamicTable";
import StreetlightService from "../../../../services/Streetlight/Streetlight.service";
import { Flex, message, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";

export default function StreetlightTab({ControlCabinetId}: {  ControlCabinetId: number;}) {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [streetlightId, setStreetlightId] = useState(0);
  const [service] = useState<StreetlightService>(new StreetlightService(ControlCabinetId));
  const [refreshTable, setRefreshTable] = useState<boolean>(true);
  
  const columnsWithActions = [
        ...service.getColumns(),
        {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 100,
        align: 'center',
        render: (text:string, record:any) => (
          <Flex justify="center" ><Space align="center">
          <EditOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              setStreetlightId(record.id);
              setEditModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <DeleteOutlined
            style={{ fontSize: "1.8em", color: "red" }}
            onClick={() => {
              setStreetlightId(record.id);
              setDeleteModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
        </Space></Flex>
      ),
    },
  ];
  return (
    <>
      {/* Streetlight*/}

      <AddOrEditEntityModal
        title="Streetlight"
        type="edit"
        service={service}
        id={streetlightId}
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        paramsPerPage={10}
        callback={() => {
          setRefreshTable(!refreshTable);
        }}
      />

      <ConfirmationModal
        callback={async ()=>{ 
          if(await service.delete(streetlightId)){
            setRefreshTable(!refreshTable);
            message.success("Deleted successfully!")
          }
        }}
        message="Are you sure you want to delete this streetlight?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <DynamicTable
        title="Streetlight"
        service={service}
        columns={columnsWithActions}
        generalActions={
          { 
            add: {}, 
            configColumns: {}, 
            deleteSelected: {}, 
            csv: { 
              service: service,
              callback: () => {setRefreshTable(!refreshTable)},
              description: "'ID PLATAFORMA', 'ID device',	'TIPO', 'LOCALIZACIÓN'",
              children: () => {return (<></>)},
              options: null,
              fileExtension: ".csv" 
            }
          }
        }
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        multiSelect={true}
      />
    </>
  );
}
