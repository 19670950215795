import { useEffect, useState } from "react";
import { Select } from "antd";
import React from "react";
type Tprops = {
  onChangeHandler?: Function,
  title?: string,
  options?: any,
  topic?: string,
  disabled?: boolean,
  service?: any,
  unicSelection?: any,
  name?:string
};
//function Selector():any {
function Selector({
  onChangeHandler =()=>{},title="",options,topic="",
  disabled = false,service,unicSelection
}:Tprops):any {
  const [data, setData] = useState([]);

  const handleChange = (value:any) => {
    onChangeHandler(value, name);
  };
  useEffect(() => {
    if (options){
      setData(options)
    }
    if (service) {
      service.getAll(topic)
      .then((data:any) => {
        setData(data.data.db.result.map((item:any)=>
          ({
            label: item.name ? item.name : item.gatewayMac,
            value: item.id,
          })
        ))
      })
      .catch((error:any) => {
        console.log("error", error)
      })
    }
  }, [service,topic,options]);

  const mergedOptions = options || data.map((entity:any) => ({
        label: entity.name ? entity.name : entity.gatewayMac,
        value: entity.id,
      }));

  return (
    <Select
      disabled={disabled}
      showSearch
      style={{ width: 200 }}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option:any) =>
        ((option?.label as string) || "").toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA:any, optionB:any) =>
        ((optionA?.label as string) || "")
          .toLowerCase()
          .localeCompare((optionB?.label as string) || "".toLowerCase())
      }
      onChange={handleChange}
      defaultValue={title}
      options={data}
    />
  );
}

export default Selector;
