import { ENV } from "../../utils";
import axios from "axios";
import AuthService from "../Auth/Auth.service";

type mode = "centralized"|"embedded"
import { IServiceCRUD,IColumnForCRUD } from "../interfaces.service";
import MqttServerService from "../MqttServer/MqttServer.service";
const mqttServer = new MqttServerService()
export default class PhysicalGatewayService implements IServiceCRUD {
    token:string
    url:string
    mqttId:number|undefined
    constructor(mode:mode|undefined=undefined){
        this.token = AuthService.getToken()
        this.url = `${ENV.Infraestructure_API}/physical-gateway`
        this.url += mode? `-${mode}`:"";
        this.mqttId = undefined
    }
    setMqttId(mqttId:number){
        this.mqttId = mqttId
    }
    async create (body: any):Promise<any>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        return await axios.post(this.url, body, {headers: headers});
    }
    async getById(id: string | number | null):Promise<any>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        return await axios.get(`${this.url}/${id}`, {headers: headers})
                            .then(response => response.data)
                            .catch((error)=>{console.log('Error:', error);return {}})
       
    };
    async getAll(filters: any, currentPage: number,pageSize: number ):Promise<any>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        const offset = (currentPage-1) * pageSize; 
        let rawData = await axios.get(`${this.url}` 
            + `?filters=${JSON.stringify(filters)}`
            + `&limit=${pageSize}&offset=${offset}`
            , {headers: headers});
        return {data: rawData.data.db.result, totalCount: rawData.data.db.totalCount}
    }
    async update(id: string | number, body: any): Promise<any>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        return await axios.put(`${this.url}/${id}`, body, {headers: headers});
    }
    async getEnums():Promise<any>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        return await axios.get(`${this.url}/enum`, {headers: headers});
    }
    async delete(id: string|string[]|number|number[]):Promise<any>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        const IdsWithCommas = Array.isArray(id)?id.join(","):id
        return await axios.delete(`${this.url}/${IdsWithCommas}`, {headers: headers});
    }
    async isCentralized(topic:string):Promise<boolean>{
        const headers= {'x-access-token': this.token,'Content-Type': 'application/json'}
        let result =  await axios
                        .get(`${ENV.Infraestructure_API}/physical-gateway/isCentralized/${topic}`, {headers: headers})
                        .then((response) => response.data)
                        .then((result) => result.isCentralized)
                        .catch((err) => {console.log(err); return false})
        return result
        
    }
    getColumns():IColumnForCRUD[]{

        return[
            {title:"Name", dataIndex:"name", type:"text", visible:true, editable:true},
            {title:"Mac", dataIndex:"mac", type:"text",visible:true, editable:true},
            {title:"LastSeen", dataIndex:"lastSeen",type:"lastSeen",  defaultValue: "3h",visible:true, editable:false},
            {title:"Description", dataIndex:"description", type:"text", visible:true, editable:true},
            {title:"Latitude", dataIndex:"latitude",type:"number", visible:true, editable:true},
            {title:"Longitude", dataIndex:"longitude", type:"number", visible:true, editable:true},
            {title:"mqtt", dataIndex:"mqttId", type:"service",service:new MqttServerService(), serviceLabel:"profileName",serviceValue:"id", visible:false, editable:true},
        ]
    }
    
    async processCsv(delimiter:string,serverFileName:string,aditionalData:any=null) {
        const headers =  {
            headers: {'x-access-token': this.token,'Content-Type': 'application/json'}
        }
        const body = JSON.stringify({...aditionalData, delimiter: delimiter, file_name: serverFileName, mqttServerId: this.mqttId})
        return await axios.post(`${ENV.Infraestructure_API}/physical-gateway/process_csv`, body, headers);
    }
}
